import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';

const CarCard = ({ car, onClick, style }) => {
    return (
        <Card sx={{ maxWidth: 345, m: 1, cursor: 'pointer', ...style }} onClick={() => onClick(car)}>
            <CardMedia
                component="img"
                height="140"
                image={car.photo || '/placeholder-image.jpg'} // Зображення або плейсхолдер
                alt={`Автомобіль ${car.car_id}`}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {car.car_id}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CarCard;
