import React, { createContext, useState } from 'react';

const ActiveTabContext = createContext({
  activeTab: 'dashboard',
  setActiveTab: () => {}
});

export const ActiveTabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('dashboard');

  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </ActiveTabContext.Provider>
  );
};

export default ActiveTabContext;
