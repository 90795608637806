import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, Button, Modal, TextField } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DiagnosticDataComponent from './CarDiagnostic';
import { SnackbarProvider, useSnackbar } from 'notistack';

// Компонент для відображення окремої карточки автомобіля
const CarCard = React.memo(({ car, toggleDetails, openCardId, openDiagnostic, buttonText, setButtonText }) => {
  return (
    <Paper
      style={{
        backgroundColor: '#808080', // Темно-синій фон
        color: '#ffffff', // Білий текст
        margin: '8px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column', // Елементи один під одним
        border: '5px solid #bbdefb', // Світло-синя обрамлення
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <img src={car.image} alt="Car" style={{ width: '50px', marginRight: '10px' }} />
        <div>
          <Typography>{car.number}</Typography>
          
          <Button
            onClick={() => toggleDetails(car.id)}
            variant="contained"
            color="primary"
            size="small"
            startIcon={<InfoOutlinedIcon />}
            style={{ marginLeft: '10px' }}
          >
            Деталі
          </Button>
        </div>
      </div>
      {openCardId === car.id && (
        <div>
          <Typography>Авто: {car.make}</Typography>
          <Typography>Двигун: {car.engine}</Typography>
          <Typography>VIN код: {car.vin}</Typography>
          <hr /> {/* Розділювач */}
          <Button 
            onClick={() => {
              setButtonText('Завантаження...');
              openDiagnostic(car.number);
            }}
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: '10px' }}
          >
            {buttonText}
          </Button>

          <Typography>КП: {car.transmission}</Typography>
          <Typography>Ремонт: {car.repair}</Typography>
          <hr />
          <Typography>Прибуток: {car.profit}</Typography>
          <Typography>Сплачено: {car.paid}</Typography>
          <hr />
          <Typography>Рекомендації: {car.recommendations}</Typography>
        </div>
      )}
    </Paper>
  );
});

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${hours}:${minutes} ${day}-${month}-${year}`;
};


const RecordDataComponent = React.memo(({ record }) => {
  // Функція для перевірки, чи дата запису менша за сьогоднішню дату
  const isDatePast = (recordDate) => {
    const today = new Date(); // Сьогоднішня дата
    today.setHours(0, 0, 0, 0); // Обнулити час, щоб порівняти лише дату
    const recordDateObj = new Date(recordDate);
    return recordDateObj < today;
  };

  // Визначаємо колір обрамлення
  const borderColor = isDatePast(record.date) ? '#FF0000' : '#64DD17'; // Червоний, якщо дата запису менша за сьогодні, інакше світло-зелений

  return (
    <Paper
      style={{
        backgroundColor: '#696969', // Зелений фон
        color: '#ffffff', // Білий текст
        margin: '8px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column', // Елементи один під одним
        border: `5px solid ${borderColor}`, // Колір обрамлення в залежності від дати
      }}
    >
      
      <Typography variant="h6" style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{record.date}</Typography>
      <Typography variant="h6">{record.phone}</Typography>
      <Typography variant="h6">{record.owner}</Typography>
      <Typography variant="h6">{record.plate}</Typography>
      <Typography variant="h6">{record.auto}</Typography>
      <Typography variant="h6">{record.vin}</Typography>
      <Typography>Статус: {record.status}</Typography>
    </Paper>
  );
});








const CRM = () => {
  const [openModal, setOpenModal] = useState(false);
  const [amount, setAmount] = useState('');
  const [selectedCar, setSelectedCar] = useState(null);
  const [buttonText, setButtonText] = useState('Діагностика');
  const [loadingDots, setLoadingDots] = useState('');
  const [openCardId, setOpenCardId] = useState(null);
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [records, setRecords] = useState([]); // Стан для зберігання даних записів
  const { enqueueSnackbar } = useSnackbar(); // Хук для відображення сповіщень


  const handleNewRecords = (newRecords) => {
    const savedRecords = JSON.parse(localStorage.getItem('records')) || [];
  
    // Перевірка на наявність нових записів, яких немає в збережених
    const newRecordFound = newRecords.some(newRecord => !savedRecords.some(oldRecord => oldRecord.id === newRecord.id));
  
    if (newRecordFound) {
      // Показати сповіщення, якщо є нові записи
      showNotification('Виявлено новий запис', 'info');
    }
  
    // Збереження нових записів в стан компоненти та локальне сховище
    setRecords(newRecords);
    localStorage.setItem('records', JSON.stringify(newRecords));
  };
  

  const showNotification = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };




  useEffect(() => {
    // Функція, яка буде викликатися кожні 10 секунд
    const fetchRecords = () => {
      const apiKey = localStorage.getItem('api'); // Отримання API ключа з локального сховища
      const savedRecords = JSON.parse(localStorage.getItem('records')) || [];
      setRecords(savedRecords);
      // Виконання запиту на отримання записів
      fetch('http://api.assist.cam/get_records', {
        method: 'GET',
        headers: {
          'X-Api-Key': apiKey, // Використання API ключа в заголовках запиту
        }
      })
        .then(response => response.json()) // Парсинг відповіді у форматі JSON
        .then(data => {
          handleNewRecords(data); // Обробка отриманих записів
        })
        .catch(error => {
          console.error('Помилка отримання даних записів:', error); // Обробка можливих помилок
        });
    };
  
    // Створення інтервалу для періодичного виклику fetchRecords
    const intervalId = setInterval(fetchRecords, 10000); // 10000 мс = 10 секунд
  
    // Очищення інтервалу при розмонтовуванні компоненту
    return () => clearInterval(intervalId);
  }, []); // Пустий масив залежностей, щоб useEffect виконався лише один раз після монтування компоненту
  




  const [columns, setColumns] = useState({
   
    "Машинозаїзди" : [],
    'Діагностика': [],
    'Ремонт': [],
    'Огляд після ремонту': [],
    'Завершено': [],
   
  });
  const [selectedCarForDiagnostic, setSelectedCarForDiagnostic] = useState(null);

  const openDiagnostic = (carNumber) => {
    setSelectedCarForDiagnostic(carNumber);
    setLastRequestTime(new Date()); // Встановлюємо поточний час
  };

  const handleCloseDiagnostic = () => {
    setSelectedCarForDiagnostic(null);
  };

  useEffect(() => {
    if (buttonText.startsWith('Завантаження')) {
      const interval = setInterval(() => {
        setLoadingDots(dots => (dots.length < 3 ? dots + '.' : ''));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [buttonText]);
  
  const updateButtonText = (newText) => {
    setButtonText(newText);
    setLoadingDots(''); // Обнулення крапок
  };
  

  useEffect(() => {
    const apiKey = localStorage.getItem('api');
    fetch('https://api.assist.cam/carjobs', {
    method: 'GET', // або інший метод, якщо потрібно
    headers: {
      'X-Api-Key': apiKey, // Використовуйте 'Bearer', якщо це токен Bearer
      // Або 'X-API-KEY': apiKey, якщо API вимагає цього ключа
      // Інші заголовки за потреби...
    }
  })
      .then(response => response.json())
      .then(data => {
        // Ініціалізація колонок
        const newColumns = {
          
          "Машинозаїзди" : [],
          'Діагностика': [],
          'Ремонт': [],
          'Огляд після ремонту': [],
          'Завершено': [],
          
          
        };
  
        // Розподіл автомобілів по колонках
        data.forEach(car => {
          if (car.status === '🗑') {
            // Якщо статус ❗, то пропускаємо цей автомобіль
            return;
          }
          if (car.status === '💰') {
            // Якщо статус "💰", то пропускаємо цей автомобіль
            return;
          }
          let columnKey;
          switch (car.status) {
            
            case '❗':
              columnKey = 'Машинозаїзди';
              break;
            case '🔍':
              columnKey = 'Огляд після ремонту';
              break;
            case '💻':
              columnKey = 'Діагностика';
              break;
            case '⚙':
              columnKey = 'Ремонт';
              break;
            case '✅':
              if (car.completed_today) {
                columnKey = 'Завершено';
              } else {
                return;
              }
              break;
          }
          if (!newColumns[columnKey]) {
            newColumns[columnKey] = [];
          }
        
          newColumns[columnKey].push({
            id: car.id,
            image: car.img_src,
            number: car.info.plate,
            vin: car.info.vin,
            status: car.status,
            make: car.info.make,
            engine: car.info.engine,
            owner: car.info.owner,
            phone: car.info.phone
          });
        });
  
        setColumns(newColumns);
      });
  }, []);

  const toggleDetails = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };

  function convertColumnIdToStatus(columnId) {
    switch (columnId) {
      
      case 'Машинозаїзди':
        return '❗';
      case 'Огляд після ремонту':
        return '🔍';
      case 'Діагностика':
        return '💻';
      case 'Ремонт':
        return '⚙';
      case 'Завершено':
        return '✅';
      
      
    }
  }

  const handleSave = () => {
    sendStatusUpdate(selectedCar.id, '💰', amount);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAmount('');
  };

  const handleOpenModal = (car) => {
    setOpenModal(true);
    setSelectedCar(car);
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
  
    if (!destination) return;
  
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
  
    
    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];
    const card = start.find(car => car.id === parseInt(draggableId, 10));
;
  
    if (destination.droppableId === 'Сплачено') {
      handleOpenModal(card); // Відкрити модальне вікно
      return; // Не відправляємо запит і не оновлюємо стан, поки сума не буде введена
    }
  
    // Оновлення статусу на сервері для інших випадків перенесення
    const newStatus = convertColumnIdToStatus(destination.droppableId);
    sendStatusUpdate(draggableId, newStatus); // Відправка оновленого статусу на сервер
  
  
    // Оновлення стану фронтенду
    if (start === finish) {
      const newCarItems = Array.from(start);
      const [reorderedItem] = newCarItems.splice(source.index, 1);
      newCarItems.splice(destination.index, 0, reorderedItem);
  
      const newColumns = {
        ...columns,
        [source.droppableId]: newCarItems,
      };
  
      setColumns(newColumns);
    } else {
      const startCarItems = Array.from(start);
      const finishCarItems = Array.from(finish);
      const [movedItem] = startCarItems.splice(source.index, 1);
  
      finishCarItems.splice(destination.index, 0, movedItem);
  
      const newColumns = {
        ...columns,
        [source.droppableId]: startCarItems,
        [destination.droppableId]: finishCarItems,
      };
  
      setColumns(newColumns);
    }
  };

  function sendStatusUpdate(id, status, amount) {
    const apiKey = localStorage.getItem('api');

    fetch('https://api.assist.cam/edit_st', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey,
      },
      body: JSON.stringify({
        id: id,
        status: status,
        amount: amount,
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Статус оновлено:', data);
      alert(data.message)
      // Оновіть стан тут, якщо потрібно
    })
    .catch(error => {
      console.error('Помилка оновлення статусу:', error);
    });
  }

  return (
    <div style={{ margin: '30px' }}>
      <DragDropContext onDragEnd={onDragEnd}>
      <Grid container spacing={2} justifyContent="center" style={{width: '100%', marginTop: 20, flexWrap: 'wrap' }}>

        {/* Секція для записів */}
        <Grid item xs={12} md={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>Записи</Typography>
          <Droppable droppableId="Записи">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              <Paper elevation={3} style={{ width: '90%', minHeight: '100px', padding: '10px' }}>
                {records.map((record, index) => (
                  <Draggable key={record.id} draggableId={record.id.toString()} index={index} isDragDisabled={true}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} > {/* Додано стиль для прозорості */}
                        <RecordDataComponent record={record} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Paper>
            </div>
          )}
        </Droppable>
        </Grid>

        {/* Секція для інших колонок */}
        {Object.keys(columns).map((colName, index) => (
          <Grid item xs={12} md={2} key={colName} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', borderRight: index < Object.keys(columns).length - 1 ? '1px solid lightgray' : 'none', minHeight: '300px' }}>
            <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>{colName}</Typography>
            <Droppable droppableId={colName}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                >
                  <Paper elevation={3} style={{ width: '90%', minHeight: '100px', padding: '10px' }}>
                    {columns[colName].map((car, index) => (
                      <Draggable key={car.id} draggableId={car.id.toString()} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <CarCard car={car} toggleDetails={toggleDetails} openCardId={openCardId} openDiagnostic={openDiagnostic} buttonText={buttonText} setButtonText={setButtonText} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Paper>
                </div>
              )}
            </Droppable>
          </Grid>
        ))}

      </Grid>

      </DragDropContext>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          backgroundColor: '#1F2A40',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div style={{
          backgroundColor: '#1F2A40',
          padding: '20px',
          borderRadius: '5px',
          boxShadow: '0 3px 7px rgba(0, 0, 0, 0.3)',
          minWidth: '300px'
        }}>
          <h2 id="modal-title">Введіть суму</h2>
          <TextField
            id="amount"
            label="Сума"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Button 
            onClick={handleSave}
            variant="contained"
            style={{
              backgroundColor: 'green', // Зелений фон
              color: 'white', // Білий текст
              marginLeft: '10px'
            }}
          >
            Зберегти
          </Button>
        </div>
      </Modal>

      {selectedCarForDiagnostic && (
        <DiagnosticDataComponent
        carNumber={selectedCarForDiagnostic}
        lastRequestTime={lastRequestTime}
        onClose={handleCloseDiagnostic}
        updateButtonText={updateButtonText}
      />
      )}
    </div>
  );
};

export default function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <CRM />
    </SnackbarProvider>
  );
}
