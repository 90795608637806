import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControlLabel, Checkbox, Select, MenuItem } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import Header from "../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import {GRID_UA_UA_LOCALE_TEXT} from './loc'

const formatDate = (date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [year, month, day].join('-');
  }

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${hours}:${minutes} ${day}-${month}-${year}`;
  };


const CarReport = () => {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedCar, setSelectedCar] = useState(null);
    const [startDate, setStartDate] = useState(formatDate(new Date()));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openPdfDialog, setOpenPdfDialog] = useState(false); // Додано: Стан для відкриття/закриття діалогу PDF

    const [pdfUrl, setPdfUrl] = useState(''); // Стан для URL PDF файла



    const handleOpenPdfDialog = (pdfUrl) => {
      setPdfUrl(pdfUrl); // Оновлення URL PDF файла
      setOpenPdfDialog(true); // Відкриття діалогу PDF
    };
  
    const handleClosePdfDialog = () => {
      setOpenPdfDialog(false); // Закриття діалогу PDF
    };

    const handleStatusChange = async (id, newStatus) => {
      setRows((prevRows) =>
          prevRows.map((row) =>
              row.id === id ? { ...row, status: newStatus } : row
          )
      );

      try {
          const apiKey = localStorage.getItem('api');
          const response = await fetch(`https://api.assist.cam/car_reports/${id}`, {
              method: 'PUT',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Api-Key': apiKey,
              },
              body: JSON.stringify({ status: newStatus }),
          });

          if (!response.ok) {
              throw new Error('Error updating status');
          }
      } catch (error) {
          console.error('Error updating status: ', error);
      }
  };
   

  const columns = [
    {
      field: 'status',
      headerName: 'Статус',
      width: 100,
      renderCell: (params) => (
          <Select
              value={params.value}
              onChange={(e) => handleStatusChange(params.row.id, e.target.value)}
              sx={{ width: '100%' }}
          >
              <MenuItem value={statusEmojiMap.diagnostics}>💻 Діагностика</MenuItem>
              <MenuItem value={statusEmojiMap.repair}>⚙ Ремонт</MenuItem>
              <MenuItem value={statusEmojiMap.review}>🔍 Огляд</MenuItem>
              <MenuItem value={statusEmojiMap.completed}>✅ Завершено</MenuItem>
              <MenuItem value={statusEmojiMap.unselected}>❗ Не обраний статус</MenuItem>
              <MenuItem value={statusEmojiMap.record}>📃 Записи</MenuItem>
              <MenuItem value={statusEmojiMap.delete}>🗑 Видалені</MenuItem>
          </Select>
      ),
  },
    {
      field: 'img_src',
      headerName: 'Фото',
      width: 90,
      renderCell: (params) => (
        <img src={params.value} alt="Фото" style={{ width: '80px', height: '80px' }} />
      ),
    },
    { field: 'plate', headerName: 'Номер', width: 150 }, 
    {
      field: 'diag_pdf',
      headerName: 'Діагностика',
      width: 150,
      renderCell: (params) => (
          <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenPdfDialog(params.value)} // Виклик функції для відкриття модального вікна
              disabled={!params.value} // Відключення кнопки, якщо params.value дорівнює null або undefined
          >
              Діагностика
          </Button>
      ),
  },
    {
      field: 'kp',
      headerName: 'КП',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(params.value, '_blank')}
          disabled={!params.value}
        >
          КП
        </Button>
      ),
    }, 
    {
      field: 'summ',
      headerName: 'Прибуток',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(params.value, '_blank')}
          disabled={!params.value}
        >
          Прибуток
        </Button>
      ),
    },

    {
      field: 'real_ng',
      headerName: 'Реальна НГ',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(params.value, '_blank')}
          disabled={!params.value}
        >
          Реальна НГ
        </Button>
      ),
    },
    {
    field: 'checkInTime',
    headerName: 'Фіксація заїзду',
    width: 150,
    renderCell: (params) => (
      <span>{params.value ? formatDateTime(params.value) : ''}</span>
    ),
  },
  {
    field: 'checkOut',
    headerName: 'Фіксація виїзду',
    width: 150,
    renderCell: (params) => (
      <span>{params.value ? formatDateTime(params.value) : ''}</span>
    ),
  },
    { field: 'total_rem', headerName: 'Час на підйомнику', width: 150 },
    { field: 'repair_time', headerName: 'Загальний час у ремонті', width: 150 },

    // ... Інші колонки ...
  ];

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
  };

  const handleEndDateChange = (newDate) => {
    setEndDate(newDate);
  };

  const handleResetDates = () => {
    const today = formatDate(new Date());
    setStartDate(today);
    setEndDate(today);
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `https://api.assist.cam/car_reports?startDate=${startDate}&endDate=${endDate}`;
        const apiKey = localStorage.getItem('api');
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-Api-Key': apiKey, // Тут вказується ваш API ключ
           
          }
        });
        const data = await response.json();
        setRows(data);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [startDate, endDate]);
  

  const handleRowClick = (params) => {
    setSelectedCar(params.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const statusEmojiMap = {
    diagnostics: '💻',
    repair: '⚙',
    review: '🔍',
    completed: '✅',
    unselected: '❗',
    record: '📃',
    delete: '🗑'
  };

  const [selectedStatuses, setSelectedStatuses] = useState({
    diagnostics: true, // Діагностика
    repair: true, // Ремонт
    review: true, // Огляд
    completed: true, // Завершено
    unselected: true, // Не вибраний статус
    record: true, // Записи
    delete: true
  });

  const filteredRows = rows.filter((row) => {
    return (
      (selectedStatuses.diagnostics && row.status.includes(statusEmojiMap.diagnostics)) ||
      (selectedStatuses.repair && row.status.includes(statusEmojiMap.repair)) ||
      (selectedStatuses.review && row.status.includes(statusEmojiMap.review)) ||
      (selectedStatuses.completed && row.status.includes(statusEmojiMap.completed)) ||
      (selectedStatuses.unselected && row.status.includes(statusEmojiMap.unselected)) ||
      (selectedStatuses.record && row.status.includes(statusEmojiMap.record)) ||
      (selectedStatuses.delete && row.status.includes(statusEmojiMap.delete))
      
    );
  });


  return (
    <Box m="20px">
      <Header title="Звіти по автомобілях" subtitle="Детальна інформація про завершені роботи" />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display="flex" flexDirection="row" m="20px">
            <TextField
                
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ marginRight: '20px' }}
            />
            <TextField
                
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
            />
            <Button
                onClick={handleResetDates}
                style={{ marginLeft: '20px', alignSelf: 'center', color: 'white' }} // Адаптуйте стилі за потребою
            >
                Скинути
            </Button>
        </Box>
        <Box display="flex" flexDirection="row" m="20px">
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatuses.diagnostics}
              onChange={(e) => setSelectedStatuses({ ...selectedStatuses, diagnostics: e.target.checked })}
              sx={{
                color: 'white', // колір коли чекбокс не вибраний
                '&.Mui-checked': {
                  color: 'blue', // колір коли чекбокс вибраний
                },
              }}
            />
          }
          label="💻 Діагностика"
        />
        {/* Інші чекбокси ви можете додати аналогічним чином */}
        {/* Приклад для "Ремонт": */}
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatuses.repair}
              onChange={(e) => setSelectedStatuses({ ...selectedStatuses, repair: e.target.checked })}
              sx={{
                color: 'white', // колір коли чекбокс не вибраний
                '&.Mui-checked': {
                  color: 'blue', // колір коли чекбокс вибраний
                },
              }}
            />
          }
          label="⚙ Ремонт"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatuses.completed}
              onChange={(e) => setSelectedStatuses({ ...selectedStatuses, completed: e.target.checked })}
              sx={{
                color: 'white', // колір коли чекбокс не вибраний
                '&.Mui-checked': {
                  color: 'blue', // колір коли чекбокс вибраний
                },
              }}
            />
          }
          label="✅ Завершені"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatuses.unselected}
              onChange={(e) => setSelectedStatuses({ ...selectedStatuses, unselected: e.target.checked })}
              sx={{
                color: 'white', // колір коли чекбокс не вибраний
                '&.Mui-checked': {
                  color: 'blue', // колір коли чекбокс вибраний
                },
              }}
            />
          }
          label="❗ Не обраний статус"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatuses.review}
              onChange={(e) => setSelectedStatuses({ ...selectedStatuses, review: e.target.checked })}
              sx={{
                color: 'white', // колір коли чекбокс не вибраний
                '&.Mui-checked': {
                  color: 'blue', // колір коли чекбокс вибраний
                },
              }}
            />
          }
          label="🔍 Огляд після ремонту"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatuses.record}
              onChange={(e) => setSelectedStatuses({ ...selectedStatuses, record: e.target.checked })}
              sx={{
                color: 'white', // колір коли чекбокс не вибраний
                '&.Mui-checked': {
                  color: 'blue', // колір коли чекбокс вибраний
                },
              }}
            />
          }
          label="📃 Записи"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedStatuses.delete}
              onChange={(e) => setSelectedStatuses({ ...selectedStatuses, delete: e.target.checked })}
              sx={{
                color: 'white', // колір коли чекбокс не вибраний
                '&.Mui-checked': {
                  color: 'blue', // колір коли чекбокс вибраний
                },
              }}
            />
          }
          label="🗑 Видалені"
        />
      </Box>

        </LocalizationProvider>
      <Box m="40px 0 0 0" height="75vh" sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          // onRowClick={handleRowClick}
          components={{ Toolbar: GridToolbar }}
          localeText={GRID_UA_UA_LOCALE_TEXT}
        />
        
      </Box>

      <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Інформація про автомобіль</DialogTitle>
            <DialogContent>
                {selectedCar && (
                <Box display="flex" flexDirection="row">
                    <Box>
                    <img src={selectedCar.img_src} alt="Автомобіль" style={{ maxWidth: '80%' }} />
                    </Box>
                    <Box ml={2}>
                    <p>Номер: {selectedCar.plate}</p>
                    <p>Статус: {selectedCar.status}</p>
                    <p>Час у ремонті: {selectedCar.today_time}</p>
                    <p>Фіксація заїзду: {formatDateTime(selectedCar.checkInTime)}</p>
                    <p>Фіксація виїзду: {formatDateTime(selectedCar.checkOut)}</p>
                    <p>Час на підйомнику: {selectedCar.total_rem}</p>
                    <p>Загальний час у ремонті: {selectedCar.repair_time}</p>
                    {/* Інші деталі авто */}
                    </Box>
                </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Закрити</Button>
            </DialogActions>
            </Dialog>

            <Dialog open={openPdfDialog} onClose={handleClosePdfDialog} fullWidth={true} maxWidth="md">
        <DialogTitle>PDF Документ</DialogTitle>
        <DialogContent>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              width="100%"
              height="500px"
              style={{ border: 'none' }}
            ></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePdfDialog}>Закрити</Button>
        </DialogActions>
      </Dialog>
            
    </Box>
  );
};

export default CarReport;
