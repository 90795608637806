import React, { useContext, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ClientWebSocketContext } from './WebSocket'; // Імпортуємо контекст

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ClientModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [clientData, setClientData] = useState(null);
  const { ws } = useContext(ClientWebSocketContext);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Client Data Received:', data);
        setClientData(data); // Зберігаємо дані клієнта
        setIsVisible(true); // Показуємо модальне вікно
      };
    }
  }, [ws]);

  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      open={isVisible}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {clientData ? (
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Ім'я: {clientData.name}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Email: {clientData.email}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Телефон: {clientData.phoneNumber}
            </Typography>
            {/* Додайте інші поля за потребою */}
          </>
        ) : (
          <Typography>Завантаження даних...</Typography>
        )}
        <Button onClick={closeModal}>Закрити</Button>
      </Box>
    </Modal>
  );
};

export default ClientModal;
