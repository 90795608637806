import React, { useState, useEffect } from 'react';



function calculateColor(percentage) {
  const red = Math.round((255 * percentage) / 100);
  const green = 255 - red;
  return `rgb(${red}, ${green}, 0)`;
}

function AutoIndicator({date}) {
  const [autos, setAutos] = useState(0);
  const [posts, setPosts] = useState(0);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const apiKey = localStorage.getItem('api');
        
        
        // Опції запиту з заголовком
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey, // Припустимо, що API очікує Bearer токен
          },
        };
  
        // Додавання параметра дати до URL
        const urlAutos = `https://api.assist.cam/get_zag?date=${date}`;
        // const urlPosts = `https://api.assist.cam/get_count_posts`;

        const responseAutos = await fetch(urlAutos, requestOptions);
        const autosData = await responseAutos.json();
        setPercentage(autosData)
        // console.log()
        // const responsePosts = await fetch(urlPosts, requestOptions);
        // const postsData = await responsePosts.json();
  
        // setAutos(autosData);
        // setPosts(postsData);
      } catch (error) {
        console.error("Помилка при отриманні даних:", error);
      }
    };
  
    fetchStats();
  }, [date]); 
  

  // const percentage = calculatePercentage(autos, posts);
  const color = calculateColor(percentage);
  const width = `${percentage}%`;

  return (
    <div style={{ width: '100px', height: '30px', border: '1px solid #ccc', borderRadius: '5px', position: 'relative', backgroundColor: '#f0f0f0' }}>
      <div style={{ height: '100%', width: width, backgroundColor: color, borderRadius: '5px' }}></div>
      <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '18px', color: 'black' }}>
        {percentage}%
      </div>
    </div>
  );
}

export default AutoIndicator;
