import React, { useState, useEffect } from 'react';
import { Slider, TextField, Box, Typography } from '@mui/material';

const ClockTimePicker = () => {
    const [value, setValue] = useState([0, 24]); // Початкові значення: з 9 до 17 години
    const apiKey = localStorage.getItem('api');


    useEffect(() => {
      // Приклад запиту для отримання робочого часу
      fetch('https://api.assist.cam/get_work_time', {
          method: 'GET', // або 'POST', залежно від вашого API
          headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': apiKey
          },
      })
      .then(response => response.json())
      .then(data => {
          setValue([data.startHour, data.endHour]);
      })
      .catch(error => {
          console.error('Помилка при отриманні робочого часу:', error);
      });
  }, []); // Залежності не потрібні, оскільки це викликається лише при монтуванні компонента
  

    const handleChange = (event, newValue) => {
      setValue(newValue);
      
  
      fetch('https://api.assist.cam/edit_work_time', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': apiKey// Замініть YOUR_API_KEY на ваш API ключ
          },
          body: JSON.stringify({
              startHour: newValue[0],
              endHour: newValue[1],
          }),
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Помилка при оновленні робочого часу');
          }
          return response.json();
      })
      .then(data => {
          console.log('Робочий час успішно оновлено', data);
      })
      .catch(error => {
          console.error('Помилка:', error);
      });
  };
  

    const marks = [
        {
            value: 0,
            label: '0:00',
        },
        {
            value: 24,
            label: '24:00',
        },
    ];

    const valueText = (value) => `${value}:00`;

    return (
        <Box>
            <Typography gutterBottom>Вибір робочих годин:</Typography>
            <Slider
                getAriaLabel={() => 'Working hours range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valueText}
                marks={marks}
                min={0}
                max={24}
                step={1}
                sx={{color: 'white'}}
            />
            <Box display="flex" justifyContent="space-between" mt={2}>
                <TextField
                    label="Початок робочого дня"
                    value={`${value[0]}:00`}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    label="Кінець робочого дня"
                    value={`${value[1]}:00`}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Box>
        </Box>
    );
};

export default ClockTimePicker;
