import React, { useState, useEffect } from 'react';
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid,
     TextField, MenuItem, Autocomplete, Checkbox, FormControlLabel,  Box, ThemeProvider, createTheme, Drawer,Typography,
     Select } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SettingsIcon from '@mui/icons-material/Settings'; // Іконка для діагностики
import BuildIcon from '@mui/icons-material/Build'; // Іконка для ремонту
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; // Іконка для заміни
import axios from 'axios';
import ClientInfoDialog from './ClientDialog'; // Імпортуйте ваш компонент
import Draggable from 'react-draggable';


const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Задає колір межі
              borderWidth: '2px', // Задає ширину межі
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Колір межі при наведенні
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Колір межі при фокусі
            },
            '& .MuiInputBase-input': {
              color: 'white', // Колір тексту
            },
            '& .MuiInputLabel-outlined': {
              color: 'white', // Колір мітки
            },
            '&.Mui-focused .MuiInputLabel-outlined': {
              color: 'white', // Колір мітки при фокусі
            }
          },
        },
        MuiCheckbox: {
            styleOverrides: {
              root: {
                // Змінити колір для обраного стану
                '&.Mui-checked': {
                  color: 'green', // Зелений колір
                },
              },
            },
          },
      },
    },
  });


  


  const CreateRecordDialog = ({ open, onClose, apiKey, date }) => {
    const [selectedService, setSelectedService] = useState('');
    const [notes, setNotes] = useState('');
    const [carNumber, setCarNumber] = useState('');
    const [carInfo, setCarInfo] = useState({ makeModel: '', owner: '', phone: '', photo: '' });
    const [carInfoOptions, setCarInfoOptions] = useState([]);
    const [selectedCarInfo, setSelectedCarInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ownerInitials, setOwnerInitials] = useState('');
    const [phon, setphone] = useState('');
    const [carImage, setCarImage] = useState('');
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [selectedPost, setSelectedPost] = useState('');

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [clientInfo, setClientInfo] = useState(null);
    const [color, setColor] = useState('');

    const [visitData, setVisitData] = useState([])

    const [mechanics, setMechanics] = useState([]);
    const [selectedMechanic, setSelectedMechanic] = useState('');

  
    const handleOpenDialog = () => {
      // Тут ви можете встановити інформацію про клієнта, отриману з API
      setClientInfo({
        name: 'Іван Іванов',
        car: 'Toyota Corolla',
        year: '2020',
      });
      setIsDialogOpen(true);
    };
    

    useEffect(() => {
      const fetchMechanics = async () => {
          try {
              const response = await axios.get('https://api.assist.cam/mechanics', {
                  headers: { 'X-Api-Key': apiKey },
              });
              if (response.status === 200) {
                  setMechanics(response.data); // Припустимо, що API повертає масив об'єктів механіків
              }
          } catch (error) {
              console.error("Помилка при завантаженні механіків:", error);
          }
      };
  
      fetchMechanics();
  }, []); // Залежності не потрібні, тому використовуємо порожній масив
  



    const [servicesSelected, setServicesSelected] = useState({
      diagnostics: false,
      repair: false,
    });

    // const posts = [
    //     { id: '1', name: 'Пост 1' },
    //     { id: '2', name: 'Пост 2' },
    //     { id: '3', name: 'Пост 3' },
    //   ];
    
    //   const postsSchedule = {
    //     '1': ['10:00',  '11:00', '12:00'],
    //     '2': ['13:00', '13:30', '14:00'],
    //   };
    
    const [postsSchedule,setShedule] = useState({});

    const handleServiceChange = (event) => {
        const { name, checked } = event.target;
        setServicesSelected(prevServices => ({
          ...prevServices,
          [name]: checked,
        }));
      };
      

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      
      const today = new Date(); // Отримати поточну дату
      const formattedDate = formatDate(today); // Форматувати дату


      const [posts, setPosts] = useState([]);

      const fetchPostsFromBackend = async () => {
        try {
          const response = await fetch('https://api.assist.cam/get_post', {
              method: 'GET',
              headers: {
                'X-Api-Key': apiKey, 
              }
            }); // Замініть URL на ваш API endpoint
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
      
          const newPosts = data.map(post => ({
            id: post.id, // перетворюємо id у строку
            name: post.name,
            cars: post.cars || []
          }));
          
          console.log(newPosts); // Перевірте, що newPosts має правильну структуру
          setPosts(newPosts);
          
        } catch (error) {
          console.error("Помилка при завантаженні постів:", error);
        }
      };
      
      // Викликаємо функцію для завантаження постів при монтуванні компонента
      useEffect(() => {
        fetchPostsFromBackend();
      }, [open]); 

// Функція для обробки вибору поста
const handleSelectPost = (postId) => {
    setSelectedPost(prevSelectedPost => prevSelectedPost === postId ? '' : postId);
  };


  const handleKeyDown = (e) => {
    if (e.key === "+" && e.shiftKey) {
      // Якщо натиснута клавіша "+" і одночасно утримується клавіша Shift
      handleOpenCreateModal();
    }
  };
  useEffect(() => {
    // Додавання обробника подій клавіш при монтуванні компонента
    window.addEventListener("keydown", handleKeyDown);

    // Прибирання обробника подій клавіш при демонтажі компонента
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  

  // Припустимо, що дані з сервера виглядають як масив об'єктів { N_REG_NEW, BRAND, MODEL }
    useEffect(() => {
        const fetchCarInfo = async () => {
            if (carNumber.length >=8) {
                setLoading(true);
                
                try {
                    const response = await axios.get(`https://api.assist.cam/get_rec_num/${carNumber}`, {
                        headers: { 'X-Api-Key': apiKey },
                    });
                    const resultsArray = Array.isArray(response.data[0]) ? response.data : [response.data];
                    console.log('Відповідь сервера:', response.data);
                    setSelectedCarInfo(response.data[0]);
                    setVisitData(response.data[0].visits)
                    
                    
                    // Припустимо, що API повертає масив об'єктів
                } catch (error) {
                    console.error('Error fetching car info:', error);
                    setCarInfoOptions([]);
                } finally {
                    setLoading(false);
                    
                    
                }
            }
        };

        fetchCarInfo();
        
    }, [carNumber, apiKey]);

    const [schedule, setSchedule] = useState({});



      

    
useEffect(() => {
    if (selectedCarInfo) {
        setOwnerInitials(selectedCarInfo.owner || '');
        setphone(selectedCarInfo.phone)
        setCarInfo({
            makeModel: selectedCarInfo.makeModel || '',
            owner: selectedCarInfo.owner || '',
            phone: selectedCarInfo.phone || '',
            photo: selectedCarInfo.photo || '',
        });
        setCarImage(selectedCarInfo.photo || '');
        handleOpenDialog();
    }
}, [selectedCarInfo]);
    


const handleSubmit = async () => {
    // Підготовка даних з усіма обраними годинами та ID поста
    const appointmentData = {
      carNumber: carNumber,
      makeModel: carInfo.makeModel,
      owner: ownerInitials ,
      phone: phon,
      date: date, // Використовується передана дата
      times: selectedTimes, // Масив обраних годин
      services: servicesSelected, // Додано обрані послуги
      notes: notes,
      postId: selectedPost,
      color: color, // ID обраного поста
      mechanic : selectedMechanic
    };
  
    try {
      const response = await axios.post('https://api.assist.cam/add_rec', appointmentData, {
        headers: {
          'X-Api-Key': apiKey,
          'Content-Type': 'application/json'
        }
      });
  
      // Перевірка відповіді від сервера
      if (response.status === 200) {
        console.log('Відповідь сервера:', response.data);
        // Логіка після успішного збереження (наприклад, закриття діалогового вікна)
        handleClear()
        handleCloseDialog()

      } else {
        console.error('Помилка при відправленні даних:', response.data);
      }
    } catch (error) {
      console.error('Помилка мережі або сервера:', error);
    }
};



  const generateTimeSlots = () => {
    let slots = [];
    for (let hour = 9; hour <= 20; hour++) {
      slots.push(`${hour < 10 ? `0${hour}` : hour}:00`);

    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  const handleCloseDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsDialogOpen(false); // Спочатку закриваємо ClientInfoDialog
      onClose(); // Потім закриваємо основний діалог, викликаючи передану функцію onClose
    }
  };
  

      // Скидання станів при очищенні поля вводу
      const handleClear = () => {
        setSelectedCarInfo(null);
        // Встановлення інформації про авто та клієнта на початкові значення
        setCarInfo({ makeModel: '', owner: '', phone: '', photo: '' });
        setOwnerInitials('');
        setCarImage('');
        setNotes('')
        setColor('')
        setSelectedPost(null)
        setServicesSelected({
            diagnostics: false,
            repair: false,
          })
        // Логіка закриття діалогу, якщо він відкритий
        setIsDialogOpen(false);
    };

    useEffect(() => {
        if (carNumber === "") {
            handleClear();
        }
    }, [carNumber]);
  
 
    useEffect(() => {
        const fetchSchedule = async () => {
          if (!formattedDate) return; // Переконайтеся, що дата встановлена
    
          try {
            setLoading(true); // Починаємо завантаження
            const response = await axios.get(`https://api.assist.cam/time_schedule`, {
              headers: { 'X-Api-Key': apiKey }, // Додаємо API ключ у заголовок запиту
              params: { date: date }, // Використовуйте ключ 'date', як визначено у вашому бекенді
            });
            
            if (response.status === 200) {
                setShedule(response.data); // Записуємо отримані дані у стан
            } else {
              console.error("Неможливо отримати розклад");
            }
          } catch (error) {
            console.error("Помилка при отриманні розкладу:", error);
          } finally {
            setLoading(false); // Закінчуємо завантаження
          }
        };
      
        fetchSchedule();
    }, [date, apiKey]); // Залежності useEffect


    const handleChangeColor = (postId, color) => {
        setPosts((prevPosts) => prevPosts.map((post) => post.id === postId ? { ...post, color } : post));
      };


  return (
    <>
      <Draggable handle=".draggable-handle" cancel={'[class*="MuiDialogContent-root"]'}>

    <Dialog
    open={open}
    onClose={handleCloseDialog}
    maxWidth="sm"
    fullWidth
    sx={{ borderRadius: '10px' }}
    PaperProps={{
      style: { marginTop: '-10vh', marginLeft: '-55vw' } // Від'ємне значення marginTop для підняття вгору
    }}
    BackdropProps={{
      style: { backgroundColor: 'rgba(0, 0, 0, 0)' } // Прозорий фон
    }}
    className="draggable-handle"
    >
    <Box style={{border: '2px solid #D3D3D3', borderRadius: '4px', backgroundColor: '#16615C'}}>
      <DialogTitle >Створення запису на {date}</DialogTitle>
      <DialogContent >
      <Grid container spacing={2}>
      <Grid item xs={12}>
        {carImage && (
            <img src={carImage} alt="Фото авто" style={{ maxWidth: '30%', marginTop: '10px', border: '2px solid #D3D3D3', borderRadius: '10px', }} />
            )}
        </Grid>

        <Grid item xs={6}>
        
        <ThemeProvider theme={theme}>
            <TextField
                label="Номер авто"
                value={carNumber}
                onChange={(e) => setCarNumber(e.target.value)} // дозволяє ручне введення номера
                InputProps={{
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        </React.Fragment>
                    ),
                }}
                fullWidth // для використання всієї доступної ширини
            />
        </ThemeProvider>
            
        </Grid>

        <ThemeProvider theme={theme}>
            <Grid item xs={6}>
                <TextField
                label="Марка і модель автомобіля"
                value={carInfo.makeModel}
                onChange={(e) => setCarInfo({ ...carInfo, makeModel: e.target.value })}
                fullWidth
                variant="outlined"
                InputLabelProps={{
                    style: { color: 'white' }, // Це для мітки, але краще використати theme для єдиності стилів
                }}
                />
            </Grid>
    </ThemeProvider>
    <ThemeProvider theme={theme}>

        <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={servicesSelected.diagnostics}
              onChange={handleServiceChange}
              name="diagnostics" // Важливо: name має відповідати ключу в стані
            />
          }
          label="Діагностика"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={servicesSelected.repair}
              onChange={handleServiceChange}
              name="repair" // Важливо: name має відповідати ключу в стані
            />
          }
          label="Ремонт"
        />
      </Grid>
      </ThemeProvider>

      <Grid item xs={6}>  

      <TextField
          label="Примітка"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={2}
        />
        </Grid>
        

        <Grid item xs={6}>  
        <TextField
            label="Телефон"
            value={phon}
            onChange={(e) => setphone(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            />
        </Grid>

        <Grid item xs={6}>  
    <TextField
    label="Ініціали"
    value={ownerInitials}
    onChange={(e) => setOwnerInitials(e.target.value)}
    fullWidth
    margin="normal"
    variant="outlined"
    />

    </Grid>

    <Grid item xs={6} direction="row" justifyContent="flex-start" alignItems="center">
    {posts
        .filter(post => post.id !== 0 && post.id !== 1) // Відфільтровування постів з id 0 та 1
        .map((post) => {
        // Визначення стилю кнопки в межах функції map
        const buttonStyle = {
            backgroundColor: selectedPost === post.id ? '#4caf50' : 'grey', // Зелений для вибраних, сірий для невибраних
            color: 'white',
            margin: '8px', // Додавання відступу для кнопок
        };
        return (
            <Button
            key={post.id}
            variant="contained"
            style={buttonStyle}
            onClick={() => handleSelectPost(post.id)}
            >
            {post.name}
            </Button>
        );
        })}
        <Grid item xs={10}>
        <Select
          value={color}
          onChange={(e) => setColor(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="" disabled>Виберіть колір</MenuItem>
          <MenuItem value="#B80000">Червоний</MenuItem>
              <MenuItem value="#33FF57">Зелений</MenuItem>
              <MenuItem value="#3333FF">Синій</MenuItem>
              <MenuItem value="#FFD700">Золотий</MenuItem>
              <MenuItem value="#FFC0CB">Рожевий</MenuItem>
              <MenuItem value="#800080">Фіолетовий</MenuItem>
              <MenuItem value="#FFFF00">Жовтий</MenuItem>
              <MenuItem value="#FFA500">Оранжевий</MenuItem>
              <MenuItem value="#A52A2A">Коричневий</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={10}>
    <TextField
        select
        label="Виберіть механіка"
        value={selectedMechanic}
        onChange={(e) => setSelectedMechanic(e.target.value)}
        fullWidth
        
    >
        {mechanics.map((mechanic) => (
            <MenuItem key={mechanic.id} value={mechanic.id}>
                {mechanic.name}
            </MenuItem>
        ))}
    </TextField>
</Grid>
    </Grid>
    


    <Grid item xs={6} direction="row" justifyContent="flex-start" alignItems="center"> 
    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
        {timeSlots.map((slot) => {
            const isBusy = postsSchedule[selectedPost] ? postsSchedule[selectedPost].includes(slot) : false;
            const isSelected = selectedTimes.includes(slot);

            return (
                <Grid item xs={3} key={slot}> {/* Кожна кнопка в своєму Grid елементі з xs={3} */}
                    <Button
                        variant={isSelected ? 'contained' : 'outlined'}
                        disabled={isBusy}
                        onClick={() => {
                            if (!isSelected && !isBusy) {
                                setSelectedTimes((prev) => [...prev, slot]);
                            } else {
                                setSelectedTimes((prev) => prev.filter((time) => time !== slot));
                            }
                        }}
                        style={{
                            width: '100%', // Займає всю ширину Grid елемента
                            color: 'white',
                            backgroundColor: isBusy ? 'red' : isSelected ? '#4caf50' : "grey",
                        }}
                    >
                        {slot}
                    </Button>
                </Grid>
            );
        })}
    </Grid>

    
    </Grid>

       
        
        </Grid>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleCloseDialog} sx={{backgroundColor: 'red'}}>Закрити</Button>
        <Button onClick={handleSubmit}  variant="contained" sx={{backgroundColor: 'green'}}>Зберегти</Button>
      </DialogActions>
      </Box>
      </Dialog>
      </Draggable>


    <ClientInfoDialog
        isVisible={isDialogOpen}
        clientInfo={clientInfo}
        visitsData={visitData}
      />
    </>
  );
};

export default CreateRecordDialog;
