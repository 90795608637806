import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton, Stack, Paper, Select, MenuItem, FormControl, InputLabel, Grid, ImageList, ImageListItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import cameraPhoto from './camera-removebg-preview.png'; // Припускаємо, що ваше зображення тепер тут
import cameraGif from './cam.gif'; // Припускаємо, що ваш гіф тепер тут
import { CircularProgress } from '@mui/material';

import CarRepairIcon from '@mui/icons-material/CarRepair';
import NumbersIcon from '@mui/icons-material/Numbers';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ComputerIcon from '@mui/icons-material/Computer';
import DevicesIcon from '@mui/icons-material/Devices';
import PercentIcon from '@mui/icons-material/Percent';
import "./cam.css"


const TypingAnimation = ({ text = '', speed = 100 }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (displayedText.length < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(text.substr(0, displayedText.length + 1));
      }, speed);

      return () => clearTimeout(timeoutId);
    }
  }, [displayedText, text, speed]);

  return <span>{displayedText}</span>;
};

const TariffCalculator = () => {
  const [camerasCount, setCamerasCount] = useState(1);
  const [paymentTerm, setPaymentTerm] = useState(1); // 1 місяць за замовчуванням
  const [isLoading, setIsLoading] = useState(false);


  const [showGif, setShowGif] = useState(false);

  const toggleImage = () => {
    setShowGif(!showGif);
  };

  const basePriceForOneCamera = 1000;
  const basePriceForTwoCameras = 2000;
  const priceForAdditionalCamera = 1000;
  const discountRates = {
    1: 1,    // Без знижки
    3: 1, // 5% знижка
    6: 1,  // 10% знижка
    12: 1 // 15% знижка
  };

  const handleAddCamera = () => setCamerasCount(prev => prev + 1);
  const handleRemoveCamera = () => setCamerasCount(prev => Math.max(1, prev - 1));

  const calculateTotal = () => {
    let basePrice = camerasCount === 1 ? basePriceForOneCamera : basePriceForTwoCameras + (camerasCount - 2) * priceForAdditionalCamera;
    // Повна ціна за місяць без знижок
    const monthlyPriceWithoutDiscount = basePrice;
    // Застосовуємо знижку на основі терміну оплати
    const discountRate = discountRates[paymentTerm];
    
    let fullPrice; // Повна ціна за весь період без застосування знижки
    if (paymentTerm === 12) {
      // Для 12 місяців розраховуємо ціну за 10 місяців, оскільки 2 місяці безкоштовно
      fullPrice = monthlyPriceWithoutDiscount * 10;
    } else {
      // Для інших термінів просто множимо місячну ціну на кількість місяців
      fullPrice = monthlyPriceWithoutDiscount * paymentTerm;
    }
    
    // Застосування знижки до повної ціни
    let totalPrice = fullPrice * discountRate;
  
    return { totalPrice: Math.round(totalPrice), fullPrice: Math.round(fullPrice) };
  };

  const { totalPrice, fullPrice } = calculateTotal();

  const handleSelectTerm = (term) => {
    setPaymentTerm(term);
  };


  const handleConfirmPayment = async () => {
    const apiUrl = 'https://api.assist.cam/create_invoice'; // Змініть на URL вашого API
    const apiKey = localStorage.getItem('api');
    setIsLoading(true); // Починаємо завантаження

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey // Припускаємо, що аутентифікація через Bearer Token
        },
        body: JSON.stringify({
          camerasCount,
          paymentTerm,
          totalPrice
        })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // Припускаємо, що бекенд повертає об'єкт з полем 'paymentUrl'
      window.open(data.url, '_blank'); // Відкриваємо URL для оплати
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    } finally {
      setIsLoading(false); // Завершуємо завантаження
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        margin: 'auto',
        width: '60vw',
        height: '70vh', // Змінено на auto, щоб вмістити все вміст
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        backgroundColor: 'background.default',
      }}
    >
      <Grid container spacing={2}>
      {/* Ліва колонка */}
      <Grid item xs={6} md={3}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          color: 'primary.contrastText',
          borderRadius: '8px',
          backgroundColor: 'secondary.main',
        }}
      >

      <Box sx={{ textAlign: 'center', color: 'black' }}>
      <ComputerIcon sx={{ fontSize: 60, mt: 1, mb: 10 }} /> {/* Розмір іконки та відступи */}

        <Typography variant="h3" sx={{ mb: 3, fontWeight: 'bold' }}>
          1000 грн/місяць
        </Typography>
        <Typography variant="h8" sx={{ mb: 3, fontWeight: 'bold' }}>
          в місяць
        </Typography>
        <Typography sx={{mt: 2, mb:2}}>
        - <Box component="span" sx={{ textDecoration: 'underline' }}>1 підключена камера</Box>
        </Typography>
        <Typography>
          - Контроль машинозаїздів: фотофіксація номеру авто, час заїзду/виїзду авто.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          При підключенні цього тарифу підходить лише підключення вологозахищенної камери.
        </Typography>
        </Box>
      </Box>
    </Grid>

      {/* Права колонка */}
      {/* Paper 1 */}
      <Grid item xs={6} md={3}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          color: 'primary.contrastText',
          borderRadius: '8px',
          backgroundColor: 'secondary.main',
        }}
      >

      <Box sx={{ textAlign: 'center', color: 'black' }}>
      <DevicesIcon sx={{ fontSize: 60, mt: 2, mb: 2 }} /> {/* Розмір іконки та відступи */}

        <Typography variant="h3" sx={{ mb: 3, fontWeight: 'bold' }}>
          2000 грн
        </Typography>
        <Typography variant="h8" sx={{ mb: 3, fontWeight: 'bold' }}>
          в місяць
        </Typography>
        <Typography sx={{mt: 2, mb:2}}>
        - <Box component="span" sx={{ textDecoration: 'underline' }}>від 2 підключених камер</Box>
        </Typography>
        <Typography sx={{  fontStyle: 'italic'  }}>
        ● повний доступ до відеомодулю
        </Typography>
        <Typography sx={{  fontStyle: 'italic'  }}>
        ● розпізнавання номеру авто і автоматичне отримання VIN code та атрибутів
        </Typography>
        <Typography sx={{  fontStyle: 'italic'  }}>
        ● доступ до планувальнику постів
        </Typography>
        <Typography sx={{  fontStyle: 'italic'  }}>
        ● календар та статистика завантаження автосервісу
        </Typography>
        <Typography sx={{  fontStyle: 'italic'  }}>
        ● CRM
        </Typography>
        <Typography sx={{  fontStyle: 'italic'  }}>
        ● актуальні звіти
        </Typography>
        <Typography sx={{  fontStyle: 'italic'  }}>
        ● інтеграція з Вашою системою обліку
        </Typography>
        </Box>
      </Box>
    </Grid>

     

        {/* Інформація про камеру */}
        <Grid item xs={12} md={6} onClick={toggleImage}>
        <Box
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: showGif ? 'row' : 'column',
        alignItems: showGif ? 'flex-start' : 'center',
        justifyContent: 'center',
        height: showGif ? 'auto' : '100%',
        transition: 'all .9s ease-in-out',
        backgroundColor: 'secondary.main',
        color: 'primary.contrastText',
        borderRadius: '8px',
        cursor: 'pointer',
      }}
      onClick={toggleImage}
    >
      {!showGif ? (
        <>
          <img src={cameraPhoto} alt="Camera" style={{ width: '100%', height: 'auto', maxWidth: '600px' }} />
          <Box sx={{ textAlign: 'center', opacity: showGif ? 0 : 1, transition: 'opacity 1s' }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Вологозахищенна камера Reolink
            </Typography>
            <Typography variant="h7" sx={{ mb: 2 }}>
              (має власну підсвітку, кріплення йдуть у комплекті)
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              5600 грн
            </Typography>
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <img src={cameraGif} alt="Camera Demo" style={{ width: '50%', height: 'auto' }} />
          <Box sx={{ marginLeft: 2, opacity: showGif ? 1 : 0, transition: 'opacity 1s' }}>
          <Typography variant="h5" sx={{ mt: 5 }} className="fadeIn fadeIn1">
            <CarRepairIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> - Захоплення авто
          </Typography>
          <Typography sx={{ mt: 5 }} className="fadeIn fadeIn2">
            <NumbersIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> - Розпізнавання номеру
          </Typography>
          <Typography className="fadeIn fadeIn3" sx={{ mt: 5 }}>
            <HourglassEmptyIcon sx={{ verticalAlign: 'middle', mr: 1}} /> - Обробка авто...
          </Typography>
          <Typography className="fadeIn fadeIn4" sx={{ mt: 5 }}>
            <CheckCircleIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> - Автомобіль зареєстровано!!!
          </Typography>
        </Box>
        </Box>
      )}
    </Box>
    </Grid>

        
    

        {/* Права колонка - Калькулятор */}
        <Grid item xs={12} md={12}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          backgroundColor: 'secondary.main',
          color: 'secondary.contrastText',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h4">
          Загальна вартість: 
          {totalPrice !== fullPrice && (
            <span style={{ textDecoration: 'line-through', marginRight: '8px' }}>
              {fullPrice} грн
            </span>
          )}
          {totalPrice} грн
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <IconButton onClick={handleRemoveCamera} sx={{ color: 'inherit' }}>
            <RemoveIcon />
          </IconButton>
          <Typography sx={{ mx: 2 }}>Камери: {camerasCount}</Typography>
          <IconButton onClick={handleAddCamera} sx={{ color: 'inherit' }}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 1 }}>
          {Object.keys(discountRates).map((term) => (
            <Button
              key={term}
              variant={paymentTerm === parseInt(term) ? "contained" : "outlined"}
              onClick={() => setPaymentTerm(parseInt(term))}
            >
              {term} міс
            </Button>
          ))}
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="warning" onClick={handleConfirmPayment} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Підтвердити оплату"}
        </Button>


          <Button variant="outlined" color="error">
            Скасувати
          </Button>
        </Box>
      </Box>
    </Grid>
      </Grid>
    </Paper>
  );
};

export default TariffCalculator;
