import React, { useEffect, useState, createContext } from 'react';
import { useSnackbar } from 'notistack';

// Створення контекстів для двох WebSocket'ів
export const WebSocketContext = createContext(null);
export const ClientWebSocketContext = createContext(null);

const WebSocketComponent = ({ children }) => {
  const [mainWs, setMainWs] = useState(null);
  const [clientWs, setClientWs] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const api_key = localStorage.getItem('api'); // Отримання API ключа з localStorage

    // Створення основного WebSocket з'єднання
    const mainWebSocket = new WebSocket(`wss://api.assist.cam/ws/main?api_key=${api_key}`);
    setMainWs(mainWebSocket);

    mainWebSocket.onopen = () => console.log('Main WebSocket Connected');
    mainWebSocket.onmessage = (event) => {
      console.log('Main Receive:', event.data);
      enqueueSnackbar(event.data, { variant: 'info', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } });
    };
    mainWebSocket.onerror = (error) => console.error('Main WebSocket error:', error);
    mainWebSocket.onclose = () => console.log('Main WebSocket Disconnected');

    // Створення другого WebSocket з'єднання для клієнтських даних
    const clientWebSocket = new WebSocket(`wss://api.assist.cam/ws/client?api_key=${api_key}`);
    setClientWs(clientWebSocket);

    clientWebSocket.onopen = () => console.log('Client WebSocket Connected');
    clientWebSocket.onmessage = (event) => {
      console.log('Client Receive:', event.data);
      // Тут можна обробляти дані, отримані від сервера
    };
    clientWebSocket.onerror = (error) => console.error('Client WebSocket error:', error);
    clientWebSocket.onclose = () => console.log('Client WebSocket Disconnected');

    return () => {
      mainWebSocket.close();
      clientWebSocket.close();
    };
  }, []);

  // Функція для відправки повідомлень через основний WebSocket
  const sendMainMessage = (message) => {
    if (mainWs) {
      mainWs.send(message);
    }
  };

  // Функція для відправки повідомлень через другий WebSocket (клієнтські дані)
  const sendClientMessage = (message) => {
    if (clientWs) {
      clientWs.send(message);
    }
  };

  return (
    <WebSocketContext.Provider value={{ ws: mainWs, sendMessage: sendMainMessage }}>
      <ClientWebSocketContext.Provider value={{ ws: clientWs, sendMessage: sendClientMessage }}>
        {children}
      </ClientWebSocketContext.Provider>
    </WebSocketContext.Provider>
  );
};

export default WebSocketComponent;
