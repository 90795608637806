import React, { useState, useRef } from 'react';
import { Box, Tabs, Tab, Paper } from '@mui/material';
import AccountSettings from './Setting';
import EmployeesTable from './Employees';
import PostsComponent from './Posts';
import Services from './Services';
const Tabss = () => {
    // ... ваш існуючий код ...

    const [selectedTab, setSelectedTab] = useState(0);
    const tabRefs = useRef([]);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const tabIndicatorStyle = {
        position: 'absolute',
        bottom: 0,
        left: tabRefs.current[selectedTab]?.offsetLeft || 0,
        width: tabRefs.current[selectedTab]?.offsetWidth || 0,
        borderBottom: '2px solid white',
        transition: 'left 0.3s ease-out, width 0.3s ease-out'
    };

    return (
        <Box m="20px">
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', position: 'relative', backgroundColor : "rgba(0, 0, 0, 0)",}}>
                <Tabs 
                    value={selectedTab} 
                    onChange={handleTabChange} 
                    
                    sx={{ 
                        width: '100%', 
                        '.MuiTab-root': { color: 'white !important' }, // Всі вкладки білі
                        '.Mui-selected': { color: 'white !important' }
                    }}
                >
                    {['Автосервіс', 'Співробітники', 'Пости', "Послуги", 'Рахунки', 'Спеціалізація', 'Запчастини', 'Склад'].map((label, index) => (
                        <Tab 
                            key={label} 
                            label={label} 
                            ref={el => tabRefs.current[index] = el} 
                            
                        />
                    ))}
                </Tabs>
                <Box sx={tabIndicatorStyle} />
            </Box>
            <Paper 
                elevation={8} 
                sx={{
                    mt: 2,
                    p: 2,
                    transition: 'all 0.3s',
                    backgroundColor : "rgba(0, 0, 0, 0)",
                    color: 'text.primary',
                    borderRadius: 2
                }}
            >
            {selectedTab === 0 && <AccountSettings/>}
            {selectedTab === 1 && <EmployeesTable/>}
            {selectedTab === 2 && <PostsComponent/>}
            {selectedTab === 3 && <Services/>}
            {selectedTab === 4 && <Box>Контент для Спеціалізація</Box>}
            {selectedTab === 5 && <Box>Контент для Запчастини</Box>}
            {selectedTab === 6 && <Box>Контент для Запчастини</Box>}

            {/* ваш існуючий код... */}
            </Paper>
        </Box>
    );
};

export default Tabss;
