import React, { useState, useEffect } from 'react';
import { Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CarCatalog from './CarCatalog';
import PartSuppliers from './PartsShop';
const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });
  
  const ModalContent = styled('div')({
    backgroundColor: '#1F2A40',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    width: '70%', // Ширина модального вікна
    maxHeight: '80vh',
    overflowY: 'auto',
    borderRadius: '10px',
    border: '2px solid #D3D3D3'
  });
  
  const LeftContent = styled('div')({
    display: 'flex',
    flexDirection: 'column', // Компоненти йдуть один за одним вертикально
    width: '50%', // 100% ширини модального вікна
    padding: '10px',
    overflowY: 'auto', // Дозволяє вертикальну прокрутку
    maxHeight: '100%',
    border: '2px solid #D3D3D3',
    borderRadius: '10px', // Максимальна висота для включення прокрутки
  });
  
  const CarInfoAndImageContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row', // Компоненти йдуть один за одним горизонтально
    alignItems: 'flex-start', // Вирівнювання елементів за початком
    marginBottom: '10px',
     // Відступ знизу
  });
  
  const CarInfo = styled('div')({
    marginRight: '20px', // Відступ справа
  });
  
  const Divider = styled('div')({
    height: '3px', // Висота розділювача
    backgroundColor: '#d3d3d3', // Колір розділювача
    width: '100%', // Ширина розділювача
    margin: '10px 0', // Відступ зверху і знизу для розділювача
  });
  const RightContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '75vh', // Припустимо, що висота встановлена в 500px
    padding: '10px',
    marginLeft: '5px',
    overflowY: 'auto',
    borderRadius: '10px',
    border: '2px solid #D3D3D3' // Дозволяє вертикальну прокрутку для всього контейнера RightContent
  });
  
  const HalfSection = styled('div')({
    flex: 1, // Кожен дочірній елемент займе рівну частину простору (50% висоти)
    padding: '10px',
    overflowY: 'auto',
    borderRadius: '10px',
    border: '2px solid #D3D3D3' // Дозволяє вертикальну прокрутку в межах HalfSection
  });

  const DiagnosticInfo = styled('div')({
    display: 'flex', // Встановлюємо flex-контейнер
    flexDirection: 'row', // Елементи розташовуються горизонтально
    width: '100%', // Задати ширину
    padding: '10px',
    
    borderRadius: '10px',
  });

  const DiagnosticSquare = styled('div')({
    width: '50%', // Задати ширину 50% від ширини DiagnosticInfo
    padding: '10px',
    border: '2px solid #D3D3D3', // Можете змінити на потрібний стиль рамки
    borderRadius: '10px',
    // Додайте потрібні стилі
  });

  const CarAndPartsInfo = styled('div')({
    width: '50%', // Задати ширину 50% від ширини модального вікна
    padding: '10px',
    marginRight: '5px'
  });

function DiagnosticDataComponent({carNumber, onClose, updateButtonText, lastRequestTime}) {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [buttonText, setButtonText] = useState('Діагностика');
  const [selectedPart, setSelectedPart] = useState(null); // Додаємо стан для обраної запчастини

  const handlePartClick = (part) => {
    // Тут можна додати логіку завантаження даних для обраної запчастини
    setSelectedPart(part);
  };

  useEffect(() => {

    setLoading(true);
    fetch(`https://api.assist.cam/get_diagnostic_data/${carNumber}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Запит завершився з помилкою');
        }
      })
      .then(data => {
        setData(data.data);
        setLoading(false);
        updateButtonText('Діагностика');
      })
      .catch(error => {
        console.error('Помилка:', error);
        setLoading(false);
        
        alert('Дані не знайдено');
        updateButtonText('Діагностика');
      });
  }, [carNumber, lastRequestTime]);// Залежність від carNumber забезпечує повторний запит при зміні цього параметра
  
  const handleClose = () => {
    setModalOpen(false);
    onClose(); // Виклик переданої функції закриття
  };
  

  const renderPartsTree = (parts, categoryId) => {
    return parts.map((part, index) => (
      <TreeItem key={`${categoryId}-${index}`} nodeId={`${categoryId}-${index}`} label={part} />
    ));
  };

  const renderTree = (categories, handlePartClick) => {
    return Object.keys(categories).map((category, index) => (
        <TreeItem key={`category-${index}`} nodeId={`category-${index}`} label={category}>
            {categories[category].map((part, partIndex) => (
                <TreeItem
                    key={`part-${index}-${partIndex}`}
                    nodeId={`part-${index}-${partIndex}`}
                    label={part}
                    onClick={() => handlePartClick(part)} // Додавання обробника на клік
                />
            ))}
        </TreeItem>
    ));
};

  if (!data) {
    // Обробка випадку, коли дані відсутні
    return
  }
  // Перетворення даних для TreeView
  const treeData = Object.entries(data.parts).map(([category, parts], index) => ({
    id: `category-${index}`,
    name: category,
    children: parts.map(part => ({ id: `part-${category}-${part}`, name: part }))
  }));

  return (
    <StyledModal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContent>
      <LeftContent>
        {/* Контейнер для інформації про авто та зображення */}
        <CarInfoAndImageContainer>
        {data.car.image && (
            <img src={data.car.image} alt="Car" style={{ width: '50%', height: 'auto', marginRight: '15px' }} />
          )}
          <CarInfo>
            <p>Дата:  {data.date}</p>
            <p>Номер: {data.car.number}</p>
            <p>Марка: {data.car.make}</p>
            <p>Модель: {data.car.model}</p>
            <p>VIN: {data.car.vin}</p>
            <p>Колір: {data.car.color}</p>
            <p>Пробіг: {data.car.mile}</p>
          </CarInfo>
          
        
          
        </CarInfoAndImageContainer>
        <Divider/>
        {/* Блок діагностики */}
        <DiagnosticInfo>
        <DiagnosticSquare>
        
          <p>Результат діагностики:</p>     
            <TreeView
              aria-label="diagnostic tree"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {renderTree(data.parts, handlePartClick)}

            </TreeView>
          </DiagnosticSquare>
          <DiagnosticSquare>
          {selectedPart && (
            <div>
              <h3>{selectedPart}</h3>
              {/* Показуємо фотографію, якщо вона є */}
              {selectedPart.image && <img src={selectedPart.image} alt={selectedPart} />}
              {/* Тут можна додати іншу інформацію про запчастину */}
            </div>
          )}
        </DiagnosticSquare>
          </DiagnosticInfo>
        
        
      </LeftContent>
      
        <RightContent>
            <HalfSection>
                <CarCatalog/>
            </HalfSection>
            <Divider />
            <HalfSection>
                <PartSuppliers/>
            </HalfSection>
        </RightContent>
        
      </ModalContent>
    </StyledModal>
  );
}

export default DiagnosticDataComponent;