import React, { useState, useEffect } from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function BlacklistTable() {
  const [cars, setCars] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);

  const handleOpen = (car) => {
    setSelectedCar(car);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }; 

  useEffect(() => {
    // Функція для завантаження даних про автомобілі з сервера
    const fetchCars = async () => {
      try {
        // Запит на ваш API для отримання даних
        const response = await fetch('https://auto.assist.cam:5041/blacklist');
        const data = await response.json();
        setCars(data);
      } catch (error) {
        console.error('Помилка при завантаженні даних:', error);
      }
    };

    fetchCars();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Фото</TableCell>
              <TableCell>Номерний знак</TableCell>
              <TableCell>Статус</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cars.map((car) => (
              <TableRow 
                key={car.id} 
                onClick={() => handleOpen(car)}
                style={{ 
                  backgroundColor: car.status === '🗑' ? '#d3d3d3' : car.status === '⚫' ? '#ffb3b3' : 'inherit',
                  cursor: 'pointer'
                }}
              >
                <TableCell>
                  <img src={car.photo} alt="Автомобіль" style={{ width: '100px', height: 'auto' }} />
                </TableCell>
                <TableCell style={{ color: 'black' }}>{car.plate}</TableCell>

                <TableCell style={{ color: 'black' }}>{car.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedCar && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Інформація про автомобіль</DialogTitle>
          <DialogContent>
            <img src={selectedCar.photo} alt="Автомобіль" style={{ width: '30%', height: 'auto' }} />
            <p>Номерний знак: {selectedCar.plate}</p>
            <p>Статус: {selectedCar.status}</p>
            {/* Додайте тут іншу інформацію про авто, якщо потрібно */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Закрити</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default BlacklistTable;
