import React, { useState, useEffect } from 'react';
import ApexChart from 'react-apexcharts';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper,Typography, IconButton,useTheme } from "@mui/material";
import CarCard from './CARDS'; // Імпортуйте CarCard
import CloseIcon from '@mui/icons-material/Close'; // Імпорт іконки закриття
import AutoIndicator from './AutoIndicator'; // Імпортуйте AutoIndicator

import './card.css'


const GeographyChartWithButtons = ({ selectedDate }) => {
  
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeHour, setActiveHour] = useState(null); // Для зберігання активної години

  const currentDate = new Date().toISOString().split('T')[0]; // Поточна дата в форматі YYYY-MM-DD
  const [startHour, setStartHour] = useState(); // Початкова година
  const [endHour, setEndHour] = useState(); // Закінчальна година
  const theme = useTheme();
  const mod = theme.palette.mode;
  // Функція для генерації масиву годин від початкової до закінчальної
  const generateHourRange = () => {
    const hours = [];
    for (let i = startHour; i <= endHour; i++) {
      hours.push(`${i}:00`);
    }
    return hours;
  };
  
  const [dat, setDat] = useState([]);
  // Поточні дані годин
  const currentHourRange = generateHourRange();

  const [carInfo, setCarInfo] = useState({ img_src: '', info: '' });
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      theme: {
        mode: mod,
      },
      
      
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: 'normal',
        background: 'transparent', // робимо фон графіка прозорим
        color: '#041d44',
        
        toolbar: {
          show: false
        },
        // events: {
        //   dataPointSelection: (event, chartContext, config) => {
        //     const hourIndex = config.dataPointIndex + startHour; // Обрахунок індексу години з урахуванням початкової години
        //     setActiveHour(hourIndex);
           
        //   }
          
        // },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        }
      },
      
      tooltip: {
        enabled: true,
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          return generateTooltipContent(data, dataPointIndex);
        }
      },
      xaxis: {
        categories: generateHourRange(),
      },
      
      yaxis: {
        max: 10
      }
    }
  });





  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiKey = localStorage.getItem('api');
        const response = await fetch(`https://api.assist.cam/get_service?date=${selectedDate}`, {
          method: 'GET',
          headers: {
            'X-Api-Key': apiKey,
          },
        });
        const newData = await response.json();
  
        // Припустимо, що в newData є поля для робочих годин: workHours: { start: 8, end: 20 }
        
        setStartHour(newData.working_hours.start);
        setEndHour(newData.working_hours.end);
        console.log(newData.working_hours.start);
        console.log(newData);
       
        setDat(newData)
        
        // Тепер масив буде створюватися динамічно на основі робочих годин
        const hoursCount = newData.working_hours.end - newData.working_hours.start + 1;
        console.log("Коллл тайм",hoursCount)
        const carCountsToday = new Array(hoursCount).fill(0);
        const carCountsOtherDays = new Array(hoursCount).fill(0);
  
        newData.cars_info.forEach(car => {
          car.visits_info.forEach(visit => {
            const visitDate = new Date(car.date_s).toISOString().split('T')[0];
            const entryHour = new Date(visit.entry_time).getHours();
            const exitHour = new Date(visit.exit_time).getHours();
  
            for (let hour = entryHour; hour <= exitHour; hour++) {
              if (hour >= startHour && hour <= endHour) {
                const index = hour - startHour;
                if (visitDate === selectedDate) {
                  carCountsToday[index]++;
                } else {
                  carCountsOtherDays[index]++;
                }
              }
            } // Тут була відсутня закриваюча дужка
          });
        });
  
        setChartData(prevChartData => ({
          ...prevChartData,
          series: [
            {
              name: 'Заїзд сьогодні',
              data: carCountsToday,
              color: '#519772'
            },
            {
              name: 'Заїзд в інші дні',
              data: carCountsOtherDays,
              color: '#5E6F74'
            },
          ],
          options: {
            ...prevChartData.options,
            xaxis: {
              categories: generateHourRange(),
            },
          },
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [selectedDate, startHour, endHour]); // Додано залежності від startHour і endHour
  

   // залежність від selectedDate забезпечує оновлення даних при зміні вибраної дати
  

   const handleBarClick = (dataPointIndex) => {
    console.log('dssdsds', dataPointIndex)
    const hourIndex = dataPointIndex + startHour; // Виправлено обчислення активної години
    setActiveHour(hourIndex);
  };
  
  // Видаліть `handleCloseCarList`, якщо вона більше не потрібна, або переконайтеся, що вона працює правильно.
  

  const handleCloseCarList = () => {
    setActiveHour(null);
  };

  const handleCarInfoClick = async (carId) => {
    // Очищення стану перед встановленням нових даних
    setCarInfo({ img_src: '', info: '' });
  
    try {
      const apiKey = localStorage.getItem('api');
        const config = {
          method: 'GET',
          headers: {
            'X-Api-Key': apiKey, // Тут вказується ваш API ключ
           
          }
        };
    
        const response = await fetch(`https://api.assist.cam/get_car_inf/${carId}`, config);
    
  
      if (response.ok) {
        const data = await response.json();
    
        // Встановлення нових даних
        setCarInfo({ id: data.id, img_src: data.img_src, info: data.info });
        setOpen(true);
      } else {
        console.error("Помилка при отриманні даних автомобіля");
      }
    } catch (error) {
      console.error("Помилка запиту", error);
    }
  };
  
  // Використання цієї функції для відображення інформації при кліку на номер авто у тултіпі
  window.handleCarNumberClick = (carId) => {
    const car = data.find(c => c.id === carId);
    console.log(car)
    if (car) {
      setCarInfo({ img_src: car.img_src, info: car.info });
      setOpen(true);
    }
  };
  

const generateTooltipContent = (hour) => {
  const indexHour = hour + startHour;
  const carsAtHour = dat.cars_info?.filter(car =>
    car.visits_info.some(visit => {
      const visitEntryHour = new Date(visit.entry_time).getHours();
      const visitExitHour = new Date(visit.exit_time).getHours();
      return indexHour >= visitEntryHour && indexHour <= visitExitHour;
    })
  ) || [];

  console.log('Cars at hour:', hour, carsAtHour);
  
    return carsAtHour.map(car => `
      <div class="car-card" onclick="window.handleCarNumberClick('${car.car_id}')" style="cursor: pointer; margin-bottom: 10px; display: flex; align-items: center;">
        <img src="${car.photo || '/placeholder-image.jpg'}" alt="Car Image" style="width: 100px; height: 60px; margin-right: 10px;"/>
        <div class="car-info">
          <div class="car-id">ID: ${car.car_id}</div>
        </div>
      </div>
    `).join("");
  };
  
  
  
  


  


  useEffect(() => {
    setChartData(prevChartData => ({
      ...prevChartData,
      options: {
        ...prevChartData.options,
        tooltip: {
          enabled: true,
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class="tooltip">${generateTooltipContent( dataPointIndex)}</div>`;
          }
        },
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              handleBarClick(config.dataPointIndex);
            }
          }
        }
      }
    }));
  }, [dat]);

  return (
    <Box sx={{ flexGrow: 1, height: '100%', width: '100%',  }}>
      
      <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px', // Відстань між елементами
    }}>
      <h3 style={{ textAlign: 'center' }}>Завантаження автосервісу</h3>
      <AutoIndicator date={selectedDate}/>
    </div>
        <ApexChart options={chartData.options} series={chartData.series} type="bar" height="70%" width='100%'/>
      
      {activeHour !== null && (
      <Paper elevation={3} sx={{ position: 'relative', padding: 1, display: 'flex', flexWrap: 'wrap'}}>
        <IconButton
          onClick={() => setActiveHour(null)} // Очищення activeHour для закриття листа
          sx={{ position: 'absolute', right: 8, top: 8, color: 'grey' }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        {dat.cars_info.filter(car =>
          car.visits_info.some(visit => {
            
            const visitEntryHour = new Date(visit.entry_time).getHours();
            const visitExitHour = new Date(visit.exit_time).getHours();
            // console.log("DDDDDD", activeHour, visitEntryHour, visitExitHour)
            return activeHour >= visitEntryHour && activeHour <= visitExitHour;

          })
          ).map(car => {
            const dateS = new Date(car.date_s); // Перетворення з datetime до Date
            const dateSString = `${dateS.getFullYear()}-${(dateS.getMonth() + 1).toString().padStart(2, '0')}-${dateS.getDate().toString().padStart(2, '0')}`;

            return (
              <CarCard
                key={car.car_id}
                car={car}
                onClick={() => handleCarInfoClick(car.car_id)}
                style={{ backgroundColor: dateSString === selectedDate ? 'green' : 'black' }} // Застосування стилів залежно від дати
              />
            );
          })}
        </div>
      </Paper>
    )}


    </Box>
  );
};



export default GeographyChartWithButtons;
