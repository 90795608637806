import React, { useState } from 'react';
import { Paper, Button, Typography, Box, Tab, Tabs, AppBar, Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Імпорт іконки

const CarDetails = ({ carDetails, onBack }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper style={{ margin: '10px', padding: '20px', backgroundColor: '#006400' }}>
        <IconButton onClick={onBack} style={{ marginBottom: '20px', color: 'black', border: '2px solid 	#228B22', }} aria-label="назад">
        <ArrowBackIcon />
      </IconButton>    
      {/* Вкладки для додаткової інформації */}
      <AppBar position="static" color="default" style={{ marginBottom: '20px' }}>
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        indicatorColor="primary" 
        textColor="primary" 
        variant="fullWidth"
        sx={{
            color: 'white',
        '.Mui-selected': { // Стилі для активної вкладки
            color: 'black',
            backgroundColor: '#006400',
        },
        '.MuiTab-root': { // Стилі для всіх вкладок
            color: 'white',
            
        }
        }}
    >
            <Tab label="Інформація про авто" />
          <Tab label="Діагностика" />
          <Tab label="КП" />
          <Tab label="Запчастини" />
          <Tab label="Примітки" />
        </Tabs>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {/* Відображення фото автомобіля */}
          <img src={carDetails.photo} alt="Автомобіль" style={{ width: '100%', height: 'auto', borderRadius: '10px',
           border: '5px solid 	#228B22',
         }} />
        </Grid>
        <Grid item xs={12} sm={8}>
          {/* Інформація про автомобіль */}
          <Typography variant="h6">Деталі автомобіля:</Typography>
          <Typography>Авто: {carDetails.auto}</Typography>
          <Typography>Номер: {carDetails.plate}</Typography>
          <Typography>Рік: {carDetails.year}</Typography>
          <Typography>VIN: {carDetails.vin}</Typography>
        </Grid>
      </Grid>

      {/* Контент вкладок */}
      {tabValue === 0 && <Typography style={{ padding: '20px' }}></Typography>}
      {tabValue === 1 && <Typography style={{ padding: '20px' }}>Інформація про КП...</Typography>}
      {tabValue === 2 && <Typography style={{ padding: '20px' }}>Інформація про запчастини...</Typography>}
      {tabValue === 3 && <Typography style={{ padding: '20px' }}>Примітки...</Typography>}
      {tabValue === 4 && <Typography style={{ padding: '20px' }}>Примітки...</Typography>}
    </Paper>
  );
};

export default CarDetails;
