import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, TextField} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';






const Services = () => {
  const [services, setServices] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editedService, setEditedService] = useState({});

// Snackbar state
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');

const handleSnackbarClose = () => {
  setSnackbarOpen(false);
};


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch('http://api.assist.cam/reason', {
          method: 'GET',
          headers: {
            'X-Api-Key': localStorage.getItem('api'),
          },
        });
        if (response.ok) {
          const data = await response.json();
          setServices(data);
        } else {
          throw new Error('Помилка при отриманні списку послуг');
        }
      } catch (error) {
        console.error('Помилка при отриманні списку послуг:', error);
      }
    };

    fetchServices();
  }, []);

  const deleteService = async (id) => {
    try {
      const response = await fetch(`https://api.assist.cam/services/${id}`, {
        method: 'DELETE',
        headers: {
          'X-Api-Key': localStorage.getItem('api'),
        },
      });
      if (response.ok) {
        setServices(services.filter(service => service.id !== id));
        setSnackbarMessage('Послугу видалено!');
      setSnackbarOpen(true);
      } else {
        throw new Error('Помилка при видаленні послуги');
      }
    } catch (error) {
      console.error('Помилка при видаленні послуги:', error);
    }
  };

  const handleEdit = (service) => {
    setEditingId(service.id);
    setEditedService({ ...service });
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`https:/api.assist.cam/services/${editedService.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': localStorage.getItem('api'),
        },
        body: JSON.stringify(editedService),
      });
      if (response.ok) {
        setServices(services.map(service => (service.id === editedService.id ? editedService : service)));
        setEditingId(null);
        setEditedService({});
        setSnackbarMessage('Послугу оновлено!');
      setSnackbarOpen(true);
      } else {
        throw new Error('Помилка при оновленні послуги');
      }
    } catch (error) {
      console.error('Помилка при оновленні послуги:', error);
    }
  };

  const handleChange = (e, key) => {
    setEditedService({ ...editedService, [key]: e.target.value });
  };

  return (
    <div>
      <h2>Послуги</h2>
      <TableContainer component={Paper} style={{ position: 'relative' }}>
        <Table>
        <TableHead style={{ position: 'relative', top: 0, backgroundColor: 'green', zIndex: 1 }}>
            <TableRow>
              <TableCell>Назва</TableCell>
              <TableCell>Ціна - Від</TableCell>
              <TableCell>Ціна - До</TableCell>
              <TableCell>Мін. час</TableCell>
              <TableCell>Макс. час</TableCell>
              <TableCell>Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map(service => (
              <TableRow key={service.id}>
                <TableCell>{editingId === service.id ? <TextField multiline fullWidth value={editedService.name} onChange={(e) => handleChange(e, 'name')} /> : service.name}</TableCell>
                <TableCell>{editingId === service.id ? <TextField value={editedService.min_p} onChange={(e) => handleChange(e, 'min_p')} /> : service.min_p}</TableCell>
                <TableCell>{editingId === service.id ? <TextField value={editedService.max_p} onChange={(e) => handleChange(e, 'max_p')} /> : service.max_p}</TableCell>
                <TableCell>{editingId === service.id ? <TextField value={editedService.min_t} onChange={(e) => handleChange(e, 'min_t')} /> : service.min_t}</TableCell>
                <TableCell>{editingId === service.id ? <TextField value={editedService.max_t} onChange={(e) => handleChange(e, 'max_t')} /> : service.max_t}</TableCell>
                <TableCell>
                  {editingId === service.id ? (
                    <IconButton onClick={handleSave}>
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(service)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => deleteService(service.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
    open={snackbarOpen}
    autoHideDuration={8000}
    onClose={handleSnackbarClose}
    message={snackbarMessage}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    sx={{
        maxWidth: '500px',
        backgroundColor: '#000080',  // Correct hex color usage 
        borderRadius: '10px',
        '& .MuiSnackbarContent-root': { // Targeting a more specific class to override defaults
            backgroundColor: '#000080', 
            borderRadius: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'white',

        }
    }}
/>


    </div>
  );
};

export default Services;
