import React, { useState } from 'react';
import { Box, TextField, Button, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ChatComponent = () => {
  const [messages, setMessages] = useState([]); // Список повідомлень
  const [newMessage, setNewMessage] = useState(''); // Текст нового повідомлення

  // Додавання нового повідомлення у список
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setMessages([...messages, { id: Date.now(), text: newMessage }]);
      setNewMessage('');
    }
  };

  // Введення тексту нового повідомлення
  const handleChangeMessage = (e) => {
    setNewMessage(e.target.value);
  };

  // Закриття чату (для прикладу, можна замінити на вашу логіку закриття)
  const handleCloseChat = () => {
    // Закриття чату
  };

  return (
    <Box sx={{ maxWidth: 360, bgcolor: 'background.paper' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Typography variant="h6">В розробці...</Typography>
        <IconButton onClick={handleCloseChat}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List sx={{ maxHeight: 300, overflow: 'auto' }}>
        {messages.map((message) => (
          <ListItem key={message.id}>
            <ListItemText primary={message.text} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Ваше повідомлення..."
          value={newMessage}
          onChange={handleChangeMessage}
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
        />
        <Button variant="contained" onClick={handleSendMessage} sx={{ ml: 2 }}>
          Відправити
        </Button>
      </Box>
    </Box>
  );
};

export default ChatComponent;
