import React from 'react';
import { Typography, Box } from '@mui/material';
import { useSpring, animated } from 'react-spring';

// const visitsData = [
//     { date: '2022-01-10', service: 'Заміна масла', comment: 'Все пройшло добре' },
//     { date: '2022-03-15', service: 'Ремонт гальмівної системи', comment: 'Замінено колодки' },
//     { date: '2022-06-20', service: 'Діагностика двигуна', comment: 'Потрібен ремонт' },
//     { date: '2022-06-20', service: 'Діагностика двигуна', comment: 'Потрібен ремонт' },
//     { date: '2022-06-20', service: 'Діагностика двигуна', comment: 'Потрібен ремонт' },
    
//     // Додайте більше візитів за потреби
//   ];
  

const ClientInfoDialog = ({ isVisible, clientInfo, visitsData }) => {
  // Анімація для кожного візиту
  const generateAnimationForVisit = (index) => useSpring({
    to: { opacity: 1, transform: 'translateY(0)' },
    from: { opacity: 0, transform: 'translateY(-5vh)' },
    delay: 800 * index,
  });

  if (!isVisible) return null;

  return (
    <React.Fragment>
      <Box
        sx={{
          position: 'fixed',
          top: '10vh', // Або інша потрібна позиція
          left: '38%', // Або інша потрібна позиція
          width: '20%',
          maxHeight: '100vh',
          overflowY: 'auto',
          background: 'rgba(0, 0, 0, 0.9)', // Білий або будь-який інший колір фону
          boxShadow: '0px 0px 10px rgba(0,0,0,0.9)', // Тінь для блоку
          padding: '10px',
          zIndex: 1000,
          border: '2px solid #D3D3D3', borderRadius: '10px', // Забезпечує відображення поверх інших елементів
        }}
      >
        <Typography variant="h6">Інформація про клієнта</Typography>
        <Typography variant="body1">Ім'я: {clientInfo?.name}</Typography>
        <Typography variant="body1">Автомобіль: {clientInfo?.car}</Typography>
        <Typography variant="body1">Рік: {clientInfo?.year}</Typography>
        {visitsData.map((visit, index) => {
          const animationProps = generateAnimationForVisit(index);
          return (
            <animated.div key={index} style={animationProps}>
              <Box my={2} p={2} boxShadow={3}>
                <Typography variant="body2">Візит {index + 1}</Typography>
                <Typography variant="body2">Дата: {visit.date}</Typography>
                <Typography variant="body2">Статус: {visit.status}</Typography>
                <Typography variant="body2">Дата закінчення: {visit.complete}</Typography>
              </Box>
            </animated.div>
          );
        })}
      </Box>
    </React.Fragment>
  );
};

export default ClientInfoDialog;
