import React, { useContext,useRef, useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import ActiveTabContext from '../../ActiveTabContext'; // Імпортуйте ActiveTabContext
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CarRepairIcon from '@mui/icons-material/CarRepair';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

import mainIcon from './main.png';
import crm from "./crm.png"
import otch from "./otch.png"
import set from "./sett.png"

const Item = ({tabId, title, icon }) => {
  const { activeTab, setActiveTab } = useContext(ActiveTabContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={activeTab === tabId}
      style={{ color: colors.grey[100]}}
      onClick={() => setActiveTab(tabId)}
      icon={icon}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);




  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      

      "& .pro-sidebar-inner": {
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      "& .pro-icon-wrapper": {
        backgroundColor: "transparent !important",
      },
      "& .pro-inner-item": {
        padding: "5px 35px 5px 20px !important",
      },
      "& .pro-inner-item:hover": {
        color: "#868dfb !important",
      },
      "& .pro-menu-item.active": {
        color: "#6870fa !important",
      },
    }}>
    <ProSidebar collapsed={isCollapsed} style={{ height: '100%', overflowY: 'auto' }}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3" color={colors.grey[100]}>
                  Assist CONTROL
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../../assets/logo192.png`}
                  style={{ cursor: "pointer"}}
                />
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}
              sx={{ borderBottom: '2px solid #617c85', borderRight: '2px solid #617c85' }}>
            {/* Використання Item для кожної вкладки */}
            <Item tabId="videokontrol" title={<span style={{ fontSize: '18px' }}>Відеоконтроль</span>} icon={<img src={mainIcon} alt="My Icon" style={{ width: 35, height: 35 }} />} />
            
            <Item tabId="crm" title={<span style={{ fontSize: '18px' }}>CRM</span>} icon={<img src={crm} alt="My Icon" style={{ width: 35, height: 35 }} />} />
            
            <Item tabId="carReport" title={<span style={{ fontSize: '18px' }}>Звіт по авто</span>} icon={<img src={otch} alt="My Icon" style={{ width: 35, height: 35 }} />} />
            
            <Item tabId="settings" title={<span style={{ fontSize: '18px' }}>Налаштування</span>} icon={<img src={set} alt="My Icon" style={{ width: 35, height: 35 }} />} />
          </Box>
          
        </Menu>
        
      
        
        </ProSidebar>  
        
    </Box>
    
    
  );
};

export default Sidebar;
