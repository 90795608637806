import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Paper, List, ListItem, Typography, Box, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatDialog = ({ isOpen, onClose, apiKey }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatId, setChatId] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSendMessage();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Запобігаємо переходу на новий рядок у TextField
      handleSendMessage();
    }
  };

  const fetchMessages = async () => {
    try {
        let currentChatId = chatId;

        // Якщо chatId відсутній, спробуємо знайти чат
        if (!currentChatId) {
            const findChatResponse = await axios.get(`http://10.20.40.2:7050/find_chat`, {
                headers: {
                    'X-Api-Key': apiKey
                }
            });
            currentChatId = findChatResponse.data.chatId;
            setChatId(currentChatId); // Зберігаємо chatId
        }

        // Тепер отримуємо повідомлення для знайденого або існуючого chatId
        const response = await axios.get(`http://10.20.40.2:7050/get_messages/${currentChatId}`, {
            headers: {
                'X-Api-Key': apiKey
            }
        });
        const updatedMessages = response.data.messages.map(msg => ({
            ...msg,
            isSender: msg.sender === apiKey
        }));
        setMessages(updatedMessages);
    } catch (error) {
        console.error('Error fetching messages:', error);
    }
};



  const adminApiKey = "NwM8vR3YAyE";

  useEffect(() => {
    if (isOpen) {
      fetchMessages();
      const intervalId = setInterval(fetchMessages, 1000); // Оновлення кожну секунду
  
      return () => clearInterval(intervalId); // Очистка інтервалу
    }
  }, [isOpen, apiKey, chatId]); // Залежності useEffect
  

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    const receiver = chatId ? apiKey : adminApiKey;
    const chatIdValue = chatId || "new_chat";
    console.log("Sending message:", newMessage, "Receiver:", receiver, "Chat ID:", chatIdValue);

    try {
        const url = `http://10.20.40.2:7050/send_message?message=${encodeURIComponent(newMessage)}&receiver=${encodeURIComponent(receiver)}&chat_id=${encodeURIComponent(chatIdValue)}`;
        const response = await axios.post(url, {}, {
            headers: {
                'X-Api-Key': apiKey
            }
        });

        console.log("Response:", response.data);
        if (response.data.isNewChat) {
          console.log(response.data)
            setChatId(response.data.chatId);
            fetchMessages();
        }
        setNewMessage('');
        // fetchMessages();
    } catch (error) {
        console.error('Error sending message:', error);
    }
};




  
  return (
    <Paper elevation={3} style={{ padding: '10px', width: '400px', maxHeight: '500px', display: 'flex', flexDirection: 'column', backgroundColor: '#1565c0' }}>
      <Box style={{ flexGrow: 1, overflow: 'auto', backgroundColor: '#0d47a1', padding: '10px', borderRadius: '5px' }}>
        <List>
          {messages.map((msg, index) => (
            <ListItem key={index} style={{ justifyContent: msg.isSender ? 'flex-end' : 'flex-start' }}>
              <Typography 
                variant="body2" 
                style={{ 
                  maxWidth: '80%', 
                  backgroundColor: msg.isSender ? '#64b5f6' : '#bbdefb', 
                  borderRadius: '10px', 
                  padding: '5px 10px',
                  color: 'white'
                }}
              >
                {msg.text}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box component="form" onSubmit={handleSubmit} style={{backgroundColor: '#008B8B', display: 'flex', alignItems: 'center', marginTop: 'auto', borderRadius: '5px', padding: '5px' }}>
        <TextField
        onKeyPress={handleKeyPress}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          
          fullWidth
          margin="dense"
          variant="outlined"
          placeholder="Напишіть повідомлення..."
          size="small"
        />
        <IconButton onClick={handleSendMessage} color="primary" style={{ marginLeft: '10px' }}>
          <SendIcon />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default ChatDialog;
