import React, { useState } from 'react';
import { Button, Select, MenuItem, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";

const PartSuppliers = () => {
  const [selectedPart, setSelectedPart] = useState('');
  const [suppliers, setSuppliers] = useState([]);

  const parts = [
    { id: 'part1', name: 'Запчастина 1' },
    { id: 'part2', name: 'Запчастина 2' },
    // Додайте більше запчастин за потреби
  ];

  const handlePartChange = (event) => {
    setSelectedPart(event.target.value);
    // Змоделюйте відповідь з API або іншого джерела
    setSuppliers([
      { id: 'supplier1', name: 'Постачальник 1' },
      { id: 'supplier2', name: 'Постачальник 2' },
      // Додайте більше постачальників за потреби
    ]);
  };

  const addToCart = (supplierId) => {
    console.log(`Додано в кошик: ${supplierId}`);
    // Тут можна додати логіку для додавання в кошик
  };

  return (
    <div>
      <Select
        value={selectedPart}
        onChange={handlePartChange}
        displayEmpty
        fullWidth
      >
        <MenuItem value="" disabled>Виберіть запчастину</MenuItem>
        {parts.map((part) => (
          <MenuItem key={part.id} value={part.id}>{part.name}</MenuItem>
        ))}
      </Select>

      {suppliers.length > 0 && (
        <List>
          {suppliers.map((supplier) => (
            <ListItem key={supplier.id}>
              <ListItemText primary={supplier.name} />
              <ListItemSecondaryAction>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addToCart(supplier.id)}
                >
                  Додати в кошик
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default PartSuppliers;
