import React, { useState, useEffect } from 'react';

import { Box, Table, TableBody, Dialog, DialogActions, DialogContent, DialogTitle, 
  Button, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Typography } from '@mui/material';
import Draggable from 'react-draggable';

import "../index.css"



function CustomTable() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [carInfo, setCarInfo] = useState({ id: '', img_src: '', info: '' });

  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectAutoNumbers, setRejectAutoNumbers] = useState([]);
  const [selectedAutoNumber, setSelectedAutoNumber] = useState('');
  const [isNewCarDetected, setIsNewCarDetected] = useState(false);
  const [knownPlates, setKnownPlates] = useState(() => {
    const savedPlates = localStorage.getItem('knownPlates');
    return savedPlates ? new Set(JSON.parse(savedPlates)) : new Set();
  });
  
  const rejectReasons = ["Збій фото", 'Не вірний номер авто'];
  // Додайте правильний шлях до звукового файлу

  const fetchData = async () => {
    try {
      const apiKey = localStorage.getItem('api');
  
      const response = await fetch('https://api.assist.cam/data/', {
        headers: {
          'X-Api-Key': apiKey,
        },
      });
  
      if (!response.ok) throw new Error('Response not OK');
  
      const newData = await response.json();
      setRows(newData);
  
      const newKnownPlates = new Set(newData.map(car => car.plate));
      const isNewCarDetected = [...newKnownPlates].some(plate => !knownPlates.has(plate));
  
      setKnownPlates(newKnownPlates);
      setIsNewCarDetected(isNewCarDetected);
  
      if (isNewCarDetected) {
        localStorage.setItem('knownPlates', JSON.stringify(Array.from(newKnownPlates)));
      }
    } catch (error) {
      console.error('Помилка при завантаженні даних:', error);
    }
  };

  const clearRejectForm = () => {
    setRejectReason('');
    setSelectedAutoNumber('');
    setRejectAutoNumbers([]);
  };
  
  

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (isNewCarDetected) {
  //     alert("Виявлено новий автомобіль!");
  //     setIsNewCarDetected(false);
  //   }
  // }, [isNewCarDetected]);

  useEffect(() => {
    const interval = setInterval(fetchData, 15000);
    return () => clearInterval(interval);
  }, [knownPlates]);


  const fetchAutoNumbers = async () => {
    const apiKey = localStorage.getItem('api');

    const response = await fetch('https://api.assist.cam/auto_number', {
        method: 'GET',  // або 'POST', залежно від того, як ваш сервер очікує отримати запит
        headers: {
            'X-Api-Key': api_key,  // Використовуйте 'Bearer' або інший префікс, якщо це потрібно
            // Додайте інші заголовки, якщо потрібно
        }
    });
    if (response.ok) {
        const data = await response.json();
        // Додаємо опцію "Немає вірного номеру" в кінець списку
        setRejectAutoNumbers([...data, 'Немає вірного номеру']);
    }
};

// Оновлена функція для відкриття модального вікна відхилення
const openRejectModal = () => {
  setOpenRejectDialog(true);
  clearRejectForm(); // Очистити поля форми перед відкриттям
  if (rejectReason === 'Не вірний номер авто') {
    fetchAutoNumbers().catch(console.error);
  }
};

// Оновлена функція для закриття модального вікна відхилення
const handleCloseRejectDialog = () => {
  setOpenRejectDialog(false);
  clearRejectForm(); // Очистити поля форми після закриття
};


    const handleCarNumberClick = async (carId) => {
      // Очищення стану перед встановленням нових даних
      setCarInfo({ img_src: '', info: '' });
    
      try {
        const apiKey = localStorage.getItem('api');
        const config = {
          method: 'GET',
          headers: {
            'X-Api-Key': apiKey, // Тут вказується ваш API ключ
           
          }
        };
    
        const response = await fetch(`https://api.assist.cam/get_car_inf/${carId}`, config);
    
        const data = await response.json();
    
        // Встановлення нових даних
        setCarInfo({ id: carId, img_src: data.img_src, info: data.info });
        setOpen(true);
      } catch (error) {
        console.error('Помилка при запиті:', error);
      }
    };
    
      // Функція для закриття модального вікна
      const handleClose = () => {
        setOpen(false);
      };

      async function sendStatusUpdate(carId, status) {
        const apiKey = localStorage.getItem('api');
        try {
          
            const response = await fetch('https://api.assist.cam/edit_st', {
                method: 'POST',
                
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': apiKey,
                },
                body: JSON.stringify({
                    id: carId,
                    status: status
                }),
            });
    
            if (!response.ok) {
                throw new Error('HTTP error');
            }
    
            const data = await response.json();
            console.log('Статус оновлено:', data);
            alert(data.message);
            return data;
        } catch (error) {
            console.error('Помилка оновлення статусу:', error);
            throw error;
        }
    }
    

    const handleStatusChange = (newStatus) => {
      if (newStatus === '🗑') {
          openRejectModal();
      } else {
          // Використання carInfo.id замість carInfo.info.plate
          if (carInfo && carInfo.id) {
              sendStatusUpdate(carInfo.id, newStatus)
              .then(() => fetchData())
              .catch(error => console.error('Помилка при оновленні таблиці:', error));
              setOpen(false);
          }
      }
  };

  const handleReject = () => {
    if (rejectReason && (carInfo.info.plate || selectedAutoNumber)) {
      const apiKey = localStorage.getItem('api'); // замініть на ваш реальний API ключ
        fetch('https://api.assist.cam/reject', {
            method: 'POST',
            headers: {
              'X-Api-Key': apiKey, // Додаємо API ключ у заголовок, якщо потрібно
            },
            body: JSON.stringify({
                rejectedNumber: carInfo.info.plate, // Номер, який відхиляється
                correctNumber: selectedAutoNumber, // Вибраний правильний номер або "Немає вірного номеру"
                reason: rejectReason
            }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Відхилення оброблено:', data);
            alert(data.message);
            setOpenRejectDialog(false);
            fetchData(); // Оновити дані таблиці
        })
        .catch(error => {
            console.error('Помилка при відхиленні:', error);
            alert('Помилка при відхиленні');
        });
    } else {
        alert('Будь ласка, введіть всі дані.');
    }
};

const handleRejectReasonChange = (event) => {
  const selectedReason = event.target.value;
  setRejectReason(selectedReason);

  if (selectedReason === 'Не вірний номер авто') {
    // Очистити вибраний номер авто та завантажити список доступних номерів авто
    setSelectedAutoNumber('');
    fetchAutoNumbers().catch(console.error);
  } else {
    // Встановіть вибраний номер авто як єдину доступну опцію
    setRejectAutoNumbers([carInfo.info.plate]);
    setSelectedAutoNumber(carInfo.info.plate);
  }
};

const [filter, setFilter] = useState('');

const [shadow, setShadow] = useState('none');
// Стилі для контейнера кнопок
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  position: 'relative',
  padding: '20px',
  backgroundColor: "rgba(0, 0, 0, 0)", // Прозорий фон

};

// Базові стилі для кнопок
const buttonStyle = {
  color: 'white',
  margin: '5px',
  border: '2px solid #D3D3D3',
  borderRadius: '10px',
  cursor: 'pointer',
  position: 'relative',
  transition: 'background-color 0.3s',
 

};




const onMouseMove = (e) => {
  const rect = e.currentTarget.getBoundingClientRect();
  // Збільшення коефіцієнтів для створення більшої та виразної тіні
  const x = (e.clientX - rect.left - rect.width / 2) * 0.5;
  const y = (e.clientY - rect.top - rect.height / 2) * 0.5;
  // Збільшення значення розміру тіні та її розсіяність для більшої та насиченої тіні
  setFilter(`drop-shadow(${x}px ${y}px 10px rgba(0, 0, 0, 0.7))`);
};

const onMouseLeave = () => {
  setFilter('');
};

  return (
    <Box borderRadius="20px" className="hide-scrollbar"  sx={{ overflow: 'auto', width: '100%', height: '95%', 
    backgroundColor: "rgba(255, 255, 255, 0.1)" 
  }}> {/* Можна налаштувати maxHeight */}
<TableContainer 
  component={Paper} 
  sx={{ 
    backgroundColor: "rgba(179, 179, 179, 0.5)", // Збільшення прозорості для затемнення
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.9)", // Додавання тіні
    borderRadius: "5px", // Опціонально, для закруглення країв
     
  }}
>  
        <Table  sx={{ backgroundColor: "rgba(235, 245, 237, 0.1)" }}>
        <TableHead>
    <TableRow>
      <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>Статус</TableCell>
      <TableCell sx={{ color: '#041d44', fontSize: 'small' , fontWeight: 'bold'}}>Фото</TableCell>
      <TableCell sx={{ color: '#041d44', fontSize: 'small' , fontWeight: 'bold'}}>Номер авто</TableCell>
      <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>Час у ремонті</TableCell>
      <TableCell sx={{ color: '#041d44', fontSize: 'small' , fontWeight: 'bold'}}>Марка/Модель</TableCell>
      <TableCell sx={{ color: '#041d44', fontSize: 'small' , fontWeight: 'bold'}}>ВІН</TableCell>
      <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>Об. двигуна</TableCell>
      <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>1С</TableCell>
    </TableRow>
  </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              let backgroundColor = 'none'; // за замовчуванням, без кольору
              let statusIcon = ''; // за замовчуванням, без іконки

              // Визначаємо колір фону залежно від значення поля records
              if (row.records === 1) {
                backgroundColor = '#2F4F4F'; // Зелений для запису
                statusIcon = '📃'; // Іконка запису
              } else if (row.records === 2) {
                backgroundColor = '#191970'; // Жовтий для запису з телефону
                statusIcon = '📞'; // Іконка телефону
              }

              return (
                <TableRow
                  key={row.id}
                  hover
                  onClick={() => handleCarNumberClick(row.id)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: backgroundColor, // встановлення фонового кольору
                  }}
                >
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>
                    {statusIcon} {row.status} {/* Відображення іконки та статусу */}
                  </TableCell>
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>
                    <img src={row.photo} alt="Фото" style={{ width: '50px', height: '50px' }} />
                  </TableCell>
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>{row.plate}</TableCell>
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>{row.checkInTime}</TableCell>
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>{row.makeModel}</TableCell>
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>{row.vin}</TableCell>
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>{row.engine}</TableCell>
                  <TableCell sx={{ color: '#041d44', fontSize: 'small', fontWeight: 'bold' }}>{row.customerOrder}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>

        </Table>
      </TableContainer>

      <Draggable handle=".draggable-handle" cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: 'transparent', // Зробіть фон Paper прозорим
              boxShadow: 'none',
            },
          }}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0)' } }} // Затемнення фону навколо діалога
          className="draggable-handle" >
        <Box sx={{background: 'linear-gradient(to top right, #041d44 0%, #041d44 30%, #519772 100%)',}}>
        

        <DialogTitle >Інформація про автомобіль</DialogTitle>
        <DialogContent sx={{}}>
        <Box
          display="flex"
          flexDirection="row"
          style={{
            // Градієнт з синього в зелений
            height: '30vh',
            border: '2px solid #D3D3D3',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
              
              
            },
          }}
        >
                <Box
                display="flex"
                justifyContent="top"
                alignItems="center"
                minWidth="150px"
                flexDirection='column'
                
                 // You can adjust this value as needed
                >
                {carInfo.img_src && (
                    <img
                    src={carInfo.img_src}
                    alt="Автомобіль"
                    style={{ maxWidth: '90%', maxHeight: '100%',
                    border: '2px solid #D3D3D3',
                    borderRadius:"10px" }}
                    />
                )}
                {carInfo.info && (
                  <Typography variant="h6" component="div" sx={{ marginBottom: '10px' }}>
                    {carInfo.info.bm}
                  </Typography>
                )}
                
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="top"
                  marginLeft="20px"
                  alignItems="left"
                >
                  {carInfo.info && (
                   <>
                   <Typography variant="subtitle1" sx={{ marginBottom: '5px' }}>Номер: {carInfo.info.plate}</Typography>
                   <Typography variant="body2" sx={{ marginBottom: '5px' }}>VIN: {carInfo.info.vin}</Typography>
                   <Typography variant="body2" sx={{ marginBottom: '5px' }}>Час у ремонті: {carInfo.info.time}</Typography>
                   <Typography variant="body2" sx={{ marginBottom: '5px' }}>Період: {carInfo.info.per}</Typography>
                   <Typography variant="body2" sx={{ marginBottom: '5px' }}>Статус: {carInfo.info.status}</Typography>
                   <Typography variant="body2" sx={{ marginBottom: '5px' }}>Документ 1С: {carInfo.info.zp}</Typography>
                   <Typography variant="body2" sx={{ marginBottom: '5px' }}>{carInfo.info.owner} {carInfo.info.phone}</Typography>
                 </>
                  )}
                </Box>
            </Box>
            </DialogContent>
            <DialogActions style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap',  }}>
            <div
              style={{ ...containerStyle }}
              onMouseMove={onMouseMove}
              onMouseLeave={onMouseLeave}
            >
              <Button 
                  onClick={() => handleStatusChange('💻')}
                  style={buttonStyle}
              >
                 💻 Діагностика
              </Button>
              <Button 
                  onClick={() => handleStatusChange('⚙')}
                  style={buttonStyle}
              >
                 ⚙ Ремонт
              </Button>
              <Button 
                  onClick={() => handleStatusChange('🔍')}
                  style={buttonStyle}
              >
                 🔍 Огляд після ремонту
              </Button>
              <Button 
                  onClick={() => handleStatusChange('✅')}
                  style={buttonStyle}
              >
                 ✅ Завершити
              </Button>
              
            </div>
              
              
              <Button 
                  onClick={() => handleStatusChange('🗑')}
                  style={{ backgroundColor: 'black', color: 'white', margin: '5px', border: '2px solid #D3D3D3',
                  borderRadius:"10px" }}
              >
                 🗑 Відхилити
              </Button>
              <Button 
                  onClick={handleClose} 
                  style={{ backgroundColor: 'red', color: 'white', margin: '5px', border: '2px solid #D3D3D3',
                  borderRadius:"10px" }}
              >
                 ❌ Закрити
              </Button>
          </DialogActions>
          </Box>
        </Dialog>
      </Draggable>
      
      <Dialog 
  open={openRejectDialog} 
  onClose={handleCloseRejectDialog} 
  style={{ width: '90%', maxWidth: 'none' }}
>
            <DialogTitle>Відхилення Автомобіля</DialogTitle>
            <DialogContent>
            <Select
              fullWidth
              value={rejectReason}
              onChange={handleRejectReasonChange}
              displayEmpty
            >
              {rejectReasons.map(reason => (
                <MenuItem key={reason} value={reason}>{reason}</MenuItem>
              ))}
            </Select>

                <Select
                  fullWidth
                  value={selectedAutoNumber}
                  onChange={(e) => setSelectedAutoNumber(e.target.value)}
                  displayEmpty
              >
                  {rejectAutoNumbers.map(number => (
                      <MenuItem key={number} value={number}>{number}</MenuItem>
                  ))}
              </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReject} color="primary">
                    Підтвердити
                </Button>
                <Button onClick={() => setOpenRejectDialog(false)} color="secondary">
                    Відмінити
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
  );
}

export default CustomTable;
