import React, { useState, useEffect, useMemo  } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Tooltip, img } from '@mui/material';

import {
  Dialog, DialogTitle, DialogContent, DialogActions, Box, Grid, Paper,
  Typography, IconButton, Table, TableBody, TableCell, TableHead, TableRow,
  FormControl, InputLabel, Select, MenuItem, Button, Chip, TextField, useTheme
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/uk';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CustomerDialog from './CarCard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ImageIcon from '@mui/icons-material/Image';
import HideImageIcon from '@mui/icons-material/HideImage';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import CRM from './CRM'

import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/Call';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CreateRecordDialog from './CreateRecords';
import CommercialOfferModal from './CP';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { 
  Person, AirportShuttle, Hardware, Circle,
  Description, Visibility, Phone, Check, AccessTime, DirectionsCar,
  Computer, Build, VpnKey, Payment, Done, Cancel, AddCircle
} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TaskIcon from '@mui/icons-material/Task';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { green } from '@mui/material/colors';
// Визначаємо порядок статусів
import DiagnosticModal from './ViewDiag';

const statusIcons = {
  '✔': TaskIcon,
  '‼': NoCrashIcon,
  '💻': Computer,
  '⚙': Build,
  '💰': Payment,
  '✅': CheckCircleIcon,
  '❌': Cancel
};

const statusDescriptions = {
  '✔': 'Узгоджено',
  '‼': 'Авто прибуло',
  '💻': 'Діагностика',
  '⚙': 'Авто в ремонті',
  '💰': 'Сплачено',
  '✅': 'Завершено',
  '❌': 'Відмова',
  'language': 'Запис з сайту',
  'call': 'Дзвінок',
  '0': 'Машино заїзд',
  'createKP': 'Створити КП'
};






  

const formatDate = (dateString) => {
  if (!dateString) {
    return ''; // Return empty string if no date provided
  }
  
  const date = new Date(dateString);
  const formatted = date.toLocaleString('uk-UA', {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

  return formatted;
};



const RecordDataComponent = React.memo(({ record, handleCarNumberClick, carColor }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const handleIconClick = (iconKey, recordId, event) => {
    
    event.stopPropagation();
    // Ви можете використовувати switch або if-else для визначення дії, заснованої на iconKey
    switch (iconKey) {
      case 'createKP':
        // Відкриття модального вікна для КП
        break;
      case 'call':
        console.log(`Показати інформацію для iconKey=${iconKey} та recordId=${recordId}`);
        // Виконати дію для CallIcon
        break;
  
        case '💻':
          setSelectedRecordId(recordId);
          setIsModalOpen(true);
          console.log(`Показати інформацію для iconKey=${iconKey} та recordId=${recordId}`);
          
          break;
      // Додати випадки для інших іконок...
      default:
        console.log(`Невідома іконка: ${iconKey}`);
    }
  };
  


  const formattedDate = formatDate(record.date);

  const [visibleDetails, setVisibleDetails] = useState({
    image: false,
    clientInfo: false,
  });

  const theme = useTheme(); // Використання хука useTheme для отримання поточної теми
const mode = theme.palette.mode;
const color_t = theme.palette.text.primary



  const onClick = () => handleCarNumberClick(record.client_id);

  const [isKPModalOpen, setIsKPModalOpen] = useState(false);

  const handleKPModalOpen = (event) => {
    event.stopPropagation();
    setIsKPModalOpen(true);
  };
  
  
  const handleKPModalClose = () => {
    setIsKPModalOpen(false);
  };
  

  const statusOrder = ['✔', '‼', '💻', '⚙', '💰', '✅', '❌']; // Визначений порядок статусів

  const createIconButton = (IconComponent, iconKey) => (
    <Tooltip title={statusDescriptions[iconKey] || 'Невідомий статус'} key={iconKey} placement='top'>
    <IconButton
      style={{ color: "#2EFF00", marginRight: 0 }}
      onClick={(event) => handleIconClick(iconKey, record.id, event)}
    >
      <IconComponent />
    </IconButton>
  </Tooltip>
);

  const iconsToRender = useMemo(() => {
    let icons = [];

    // Спочатку додайте іконку відповідно до типу запису
    switch(record.type) {
      case 0:
        icons.push(createIconButton(CarRepairIcon, '0'));
        break;
      case 1:
      case 2:
        icons.push(createIconButton(CallIcon, 'call'));
        break;
      default:
        icons.push(createIconButton(LanguageIcon, 'language'));
    }

    // Знаходимо індекс поточного статусу в порядку статусів
    const currentIndex = statusOrder.indexOf(record.status);

    // Додавання іконок статусів відповідно до визначеного порядку до поточного статусу (включно)
    statusOrder.slice(0, currentIndex + 1).forEach(status => {
      const IconComponent = statusIcons[status];
      if (IconComponent) {
        icons.push(createIconButton(IconComponent, status));
      }
    });

    // Якщо статус '💻', додайте додаткову іконку
    if (record.status === '💻') {
      icons.push(createIconButton(AddCircle, 'createKP', handleKPModalOpen));
    }

    return icons;
  }, [record.type, record.status, handleKPModalOpen]);


  return (
    <>
    <Paper
    elevation={5}
      sx={{
        background: mode === "dark"
  ? 'linear-gradient(to top right, #0a3d62 0%, #0a3d62 50%, #74b9ff 100%)'
  : 'linear-gradient(to top right, #b8e994 0%, #b8e994 20%, #0984e3 100%)',

        margin: '4px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Забезпечує розподіл контенту
        gap: '2px',
        border: '3px solid #B1B1B1',
        color: 'black',
      }}
      onClick={onClick}
    >
      {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {renderIcon()}
        <Circle sx={{ color: carColor, fontSize: '25px' }} />
        <Typography variant="h6" sx={{ marginLeft: 1, flexGrow: 1 }}>
          {record.plate}
        </Typography>
      </Box> */}

      <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold' }}>
      <CalendarMonthIcon sx={{ color: color_t }} />
        {formattedDate && (
        <Typography variant="body1" color='#2EFF00' sx={{ display: 'inline', fontWeight: 'bold' }}>
          {formattedDate}
        </Typography>
      )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 'bold' }}>
      
        <Person sx={{ color: color_t }} />
        <Typography variant="subtitle" color={color_t} sx={{ display: 'inline', fontWeight: 'bold' }}>
          {record.owner}
        </Typography>
        <Phone sx={{ color: color_t }} />
        <Typography variant="subtitle" color={color_t} sx={{ display: 'inline', fontWeight: 'bold' }}>
          {record.phone} 
        </Typography>
        
      </Box>
        <Typography variant="subtitle" color={color_t} sx={{ fontWeight: 'bold' }}>
          <AirportShuttle sx={{ verticalAlign: 'middle', mr: 1, color: color_t }} />{record.plate}ㅤ{record.auto}
        </Typography>
        <Typography variant="body1" color={color_t}  sx={{ fontWeight: 'bold' }}>
          <Hardware sx={{ verticalAlign: 'middle', mr: 1, color: color_t }} />{record.services}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 1 }}>
        {/* Іконки зліва */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {iconsToRender}
        </Box>

        {/* Ціна справа */}
        <Tooltip
  title="Вартість послуги"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '12px', // Задайте бажаний розмір шрифту
              },
            },
          }}
        >
        <Typography variant="subtitle1" color={color_t} sx={{ fontWeight: 'bold' }}>
            {record.cost}
        </Typography>
        </Tooltip>
    </Box>
    </Paper>
    <CommercialOfferModal open={isKPModalOpen} handleClose={handleKPModalClose} />

    <DiagnosticModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          recordId={selectedRecordId}
        />

    </>
  );
});






const Scheduler = () => {
  // State initialization
  moment.locale('uk');
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedDay, setSelectedDay] = useState(moment().format('YYYY-MM-DD'));
  const [carColors, setCarColors] = useState({});
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [carRepairs, setCarRepairs] = useState({});
  const [selectedStart, setSelectedStart] = useState('09:00');
  const [selectedEnd, setSelectedEnd] = useState('10:00');
  const [selectedColor, setSelectedColor] = useState(''); // Default color selection
  const [defaultCarColor, setDefaultCarColor] = useState('grey'); // Default car color
  const [openModal, setOpenModal] = useState(false);
  const formattedSelectedDay = moment(selectedDay).format('YYYY-MM-DD');
  const formattedDate = moment(selectedDay).format('DD.MM.YY');
  const [records, setRecords] = useState([]); // Стан для зберігання даних записів
  const [openCardId, setOpenCardId] = useState(null);
  const toggleDetails = (id) => {
    setOpenCardId(openCardId === id ? null : id);
  };
  const [buttonText, setButtonText] = useState('Діагностика');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [carInfo, setCarInfo] = useState({ id: '', img_src: '', info: '' });
  const [activeCar, setActiveCar] = useState(null);
  const [activeCarId, setActiveCarId] = useState(null); // Дода

  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [modalFormData, setModalFormData] = useState({
    service: '',
    note: '',
    carId: null
  });

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const apiKey = localStorage.getItem('api');

  const handleOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => setOpenCreateModal(false);
  const [isDenialModalOpen, setIsDenialModalOpen] = useState(false);
  const [denialReason, setDenialReason] = useState('');
  const [carModel, setCarModel] = useState(''); // Для зберігання моделі авто у випадку відмови через не відповідність вимогам
  const [carday, setCarday] = useState({});


  const handleOpenDenialModal = (itemId) => {
    console.log('Відкриваємо модальне вікно для', itemId);
    setIsDenialModalOpen(true);
  };
  

  const [selectedCarForDiagnostic, setSelectedCarForDiagnostic] = useState(null);

  const openDiagnostic = (carNumber) => {
    setSelectedCarForDiagnostic(carNumber);
    setLastRequestTime(new Date()); // Встановлюємо поточний час
  };

  const handleCloseDiagnostic = () => {
    setSelectedCarForDiagnostic(null);
  };


  const getCarColorByIdAndDate = (carId, selectedDay) => {

  for (const [key, cars] of Object.entries(tableData)) {
      const car = cars.find(c => c.name === carId && c.date_r === selectedDay);
      if (car) {
          
          return car.color; // Повертаємо колір знайденого автомобіля
      }
  }
  return null; // Якщо автомобіль не знайдено у tableData або дата не відповідає
};
  
const [columns, setColumns] = useState({
  "Нерозібрані": [],
  "Заплановані": [],
  "В роботі": [],
  "Завершені": [],
  // "Завершено": [],
  // "Видача": [],
  // "Сплачено": [],
  // 'Відмова' : []
});


useEffect(() => {
  const fetchAndProcessRecords = () => {
    const apiKey = localStorage.getItem('api');

    fetch('https://api.assist.cam/get_records', {
      method: 'GET',
      headers: {
        'X-Api-Key': apiKey,
      }
    })
    .then(response => response.json())
    .then(data => {
      const newColumns = {
        "Нерозібрані": [],
        "Заплановані": [],
        "В роботі": [],
        "Завершені": [],
        // "Діагностика": [],
        // "Авто в роботі": [],
        // "Завершено": [],
        // "Сплачено": [],
        // "Відмова": [],
      };

      data.forEach(car => {
        let columnKey = ""; // Визначаємо змінну для ключа колонки
      
        if (['📃', '👁', '📞'].includes(car.status)) {
          columnKey = 'Нерозібрані';
        } else if (['✔', '🕘'].includes(car.status)) {
          columnKey = 'Заплановані';
        } else if (['‼','💻', '⚙', '🔑'].includes(car.status)) {
          columnKey = 'В роботі';
        } else if (['✅', '💰', '❌'].includes(car.status)) {
          columnKey = 'Завершені';
        }
      
        // Тут ваш код для обробки кожного автомобіля в залежності в
      

        // Перевірка чи ключ колонки був встановлений
        if (columnKey && newColumns[columnKey] !== undefined) {
          newColumns[columnKey].push(car);
        }
      });

      setColumns(newColumns);
      setRecords(data);
      console.log(data);
    })
    .catch(error => {
      console.error('Помилка отримання даних записів:', error);
    });
  };

  fetchAndProcessRecords();
  const intervalId = setInterval(fetchAndProcessRecords, 10000); // 10000 мс = 10 секунд

  return () => clearInterval(intervalId);
}, []);





  const handleCarNumberClick = async (carId) => {
    // Очищення стану перед встановленням нових даних
    // setCarInfo({ img_src: '', info: '' });
    console.log(carId)
    setActiveCarId(carId); // Зберігаємо carId в стані

  
    try {
      const apiKey = localStorage.getItem('api');
      const config = {
        method: 'GET',
        headers: {
          'X-Api-Key': apiKey, // Тут вказується ваш API ключ
         
        }
      };
  
      const response = await fetch(`https://api.assist.cam/get_client_card/${carId}`, config);
  
      const data = await response.json();
  
      // Встановлення нових даних
      setCarInfo({ id: carId, img_src: data.img_src, info: data.info });
      setIsModalOpen(true);
    } catch (error) {
      console.error('Помилка при запиті:', error);
    }
  };
  
  
  
  const handleOpenModal = (car) => {
    setActiveCar(car);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setActiveCar(null);
  };


  // const postIds = ['0', '1', '2', '3', '4', '5', '6']; // Post identifiers
  const hours = []; // Hours array
  for (let hour = 9; hour <= 18; hour++) {
    hours.push(`${hour.toString().padStart(2, '0')}:00`);
  }
  

  const [posts, setPosts] = useState([]);

  const fetchPostsFromBackend = async () => {
    try {
      const response = await fetch('https://api.assist.cam/get_posts', {
          method: 'GET',
          headers: {
            'X-Api-Key': apiKey, 
          }
        }); // Замініть URL на ваш API endpoint
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
  
      const newPosts = data.map(post => ({
        id: post.id, // перетворюємо id у строку
        name: post.name,
        cars: post.cars || []
      }));
      
      console.log(newPosts); // Перевірте, що newPosts має правильну структуру
      setPosts(newPosts);
      
    } catch (error) {
      console.error("Помилка при завантаженні постів:", error);
    }
  };
  
  // Викликаємо функцію для завантаження постів при монтуванні компонента
  useEffect(() => {
    fetchPostsFromBackend();
  }, []); // Пустий масив залежностей означає, що ефект буде викликатися лише один раз при першому рендерингу
  



  const [carData, setCarData] = useState({});


  useEffect(() => {
    generateCalendarDays();
  }, [currentMonth]);

  const generateCalendarDays = () => {
    const days = [];
    const startDay = currentMonth.clone().startOf('month').startOf('week');
    const endDay = currentMonth.clone().endOf('month').endOf('week');

    for (let m = moment(startDay); m.isBefore(endDay); m.add(1, 'days')) {
      days.push(m.clone());
    }

    setDaysInMonth(days);
  };

  const handleDayClick = (day) => {
    setSelectedDay(day.format('YYYY-MM-DD'));
  };

  const goToPreviousMonth = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
  };

  const goToNextMonth = () => {
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
  };


  const handleOpenDialog = (carId, postId) => {
    const car = carData[selectedDay][postId].find(c => c.id === carId);

    // Зберігаємо весь об'єкт автомобіля, а не лише ID
    setSelectedCar(car || null); 
    setSelectedPost(postId);
    setSelectedColor(carColors[carId] || defaultCarColor); // Set car color
    setOpenModal(true);
};




  const handleCloseDialog = () => {
    setOpenModal(false);
  };





  // Помістіть ваш API ключ тут
  const BASE_URL = 'https://api.assist.cam'; // Замініть на URL вашого API
  
  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/carData`, {
          headers: {
            'X-Api-Key': apiKey,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        
        // Трансформація даних у структуру carData
        const carDataStructure = data.reduce((acc, { date, post_id, car }) => {
          if (!acc[date]) acc[date] = {};
          if (!acc[date][post_id]) acc[date][post_id] = [];
          acc[date][post_id].push(car);
          return acc;
        }, {});
        
        console.log("Дані постів", {carDataStructure});
        setCarData(carDataStructure); // Оновлення стану carData
        // ...
      } catch (error) {
        console.error("Помилка при завантаженні даних про автомобілі: ", error);
      }
    };

    const fetchCalend = async () => {
      try {
        const response = await fetch(`https://api.assist.cam/car_plan`, {
          headers: {
            'X-Api-Key': apiKey,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("Дані постів", {data});
        // Перетворюємо дані
        const processedData = data.reduce((acc, item) => {
          // Потрібно перетворити дату зі строки в об'єкт Date
          const date = new Date(item.date);
          const dateKey = date.toISOString().split('T')[0]; // Перетворюємо дату у формат 'YYYY-MM-DD'
          if (!acc[dateKey]) {
            acc[dateKey] = [];
          }
          acc[dateKey].push(item.plate);
          return acc;
        }, {});
    
        // Встановлення стану carDay з переробленими даними
        setCarday(processedData);
        // ...
      } catch (error) {
        console.error("Помилка при завантаженні даних про автомобілі: ", error);
      }
    };
    
  
    const fetchTableData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get_plan`, {
          headers: {
            'X-Api-Key': apiKey, // Тут передаємо API ключ у заголовках
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data)
        setTableData(data);
      } catch (error) {
        console.error("Помилка при завантаженні даних для таблиці: ", error);
      }
    };
  
    const fetchData = () => {
      fetchCarData();
      fetchTableData();
      fetchCalend();
      generateCalendarDays();
    };
    
    // Викликаємо fetchData відразу при монтажі компонента
    fetchData();
  
    // Налаштовуємо інтервал для автооновлення даних
    const intervalId = setInterval(fetchData, 10000); // 10000 мс = 10 секунд
  
    // Очищуємо інтервал при демонтажі компонента
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedDay, currentMonth]);  // Залежність від зміни поточного місяця
  

  

  

  function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }
  
  function rgbToHex(r, g, b) {
    return "#" + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }).join('');
  }
  
  function interpolateColor(color1, color2, factor) {
    const [r1, g1, b1] = hexToRgb(color1);
    const [r2, g2, b2] = hexToRgb(color2);
    
    const r = Math.round(r1 + (r2 - r1) * factor);
    const g = Math.round(g1 + (g2 - g1) * factor);
    const b = Math.round(b1 + (b2 - b1) * factor);
  
    return rgbToHex(r, g, b);
  }
  
  const getColorForDay = (day) => {
    const dayData = carday[day.format('YYYY-MM-DD')];
    let carCount = 0;
  
    if (dayData) {
      Object.values(dayData).forEach(arr => {
        carCount += arr.length;
      });
    }
  
    if (carCount === 0) {
      return '#617c85'; // Сірий колір
    }
  
    const startColor = '#FFBA08';
    const endColor = '#DC2F02';
    const maxCarCount = 10;
    const factor = Math.min(carCount, maxCarCount) / maxCarCount;
  
    return interpolateColor(startColor, endColor, factor);
  };

const handleSubmit = async () => {
  // Тут можна додати валідацію даних
  setIsServiceModalOpen(false);
  const apiKey = localStorage.getItem('api');
  try {
    const response = await fetch('https://api.assist.cam/update_status', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey
      },
      body: JSON.stringify({
        carId: modalFormData.carId,
        service: modalFormData.service,
        note: modalFormData.note
      })
    });
    fetchAndProcessRecords();
    if (!response.ok) {
      throw new Error('Помилка при збереженні даних');
    }

    // Тут можна додати логіку для оновлення UI після успішного збереження
  } catch (error) {
    console.error('Помилка при відправці даних:', error);
  }
};






const renderTopColumns = () => {
  return (
    <Grid container spacing={2} justifyContent="center" style={{width: '100%', marginTop: 20, flexWrap: 'wrap' }}>

        {/* Секція для інших колонок */}
        {Object.keys(columns).map((colName, index) => (
          <Grid item xs={10} md={3} key={colName} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '300px'}}>
          <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '8px' }}>{colName}</Typography>
          <Droppable droppableId={colName}>
              {(provided) => (
                  <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                      <Paper elevation={5} style={{ width: '98%', minHeight: '150px', padding: '2px',
                       backgroundColor: "rgba(0, 0, 0, 0)" }}>
                          {columns[colName].map((item, index) => (
                              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                  {(provided) => (
                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          
                                          
                                          <RecordDataComponent record={item} handleCarNumberClick={handleCarNumberClick}
                                            carColor={getCarColorByIdAndDate(item.plate, selectedDay)} key={item.id} />

                                  
                                         
                                      </div>
                                  )}
                              </Draggable>
                          ))}
                          {provided.placeholder}
                      </Paper>
                  </div>
              )}
          </Droppable>
      </Grid>
        ))}

      </Grid>
  );
};
  

  
  
  
const renderCalendar = () => {
  return daysInMonth.map((day) => {
    const dayKey = day.format('YYYY-MM-DD');
    const isCurrentMonth = day.month() === currentMonth.month();
    const isToday = dayKey === moment().format('YYYY-MM-DD'); // перевіряємо, чи це сьогоднішній день
    const dayColor = getColorForDay(day);

    return (
      <Grid item xs={1.7} key={dayKey} onClick={() => handleDayClick(day)}>
        <Paper
          elevation={3}
          sx={{
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: isCurrentMonth ? dayColor : 'inherit',
            opacity: isCurrentMonth ? 1 : 0.3,
            cursor: 'pointer', // Додаємо курсор покажчика для кращої взаємодії
            border: isToday ? '5px solid #0000FF' : 'none', // додаємо рамку, якщо це сьогоднішній день
          }}
        >
          <Typography variant="body2">{isCurrentMonth ? day.format('D') : ''}</Typography>
        </Paper>
      </Grid>
    );
  });
};








// Функція для відправлення даних на бекенд
const sendDataToBackend = async (data) => {
  try {
    const apiKey = localStorage.getItem('api'); // Замініть на свій API ключ
    console.log('Update DATA', data)
      const response = await fetch('https://api.assist.cam/update_posts', {
          method: 'POST',
          headers: {
              'X-Api-Key': apiKey, // Додайте ваш API ключ у заголовок
              'Content-Type': 'application/json', // Вказуйте тип контенту
          },
          body: JSON.stringify(data),
      });

      if (response.ok) {
          const responseData = await response.json();
          console.log('Дані успішно збережені:', responseData);
      } else {
          console.error('Помилка при збереженні даних:', response.status);
      }
  } catch (error) {
      console.error('Помилка при збереженні даних:', error);
  }
};



  
  const [tableData, setTableData] = useState({});
  



// const onDragEnd = (result) => {
//   const { source, destination, draggableId } = result;

//   if (!destination) {
//     return; // Якщо елемент відпущений за межами droppable області
//   }

//   // Перетворення draggableId назад на число, якщо ваші ID є числами
//   const draggableIdNumber = parseInt(draggableId, 10);

//   // Знаходження відповідного запису в records
//   const draggedItem = records.find(record => record.id === draggableIdNumber);

//   if (!draggedItem) {
//     console.error("Не вдалося знайти запис з ID:", draggableId);
//     return;
//   }

  

//   const isMovingToTable = destination.droppableId.includes('-');
  
//   if (isMovingToTable) {
//     handleMoveToTable(draggedItem, destination);
//   } else if (source.droppableId !== destination.droppableId) {
//     // Припустимо, у вас є логіка для переміщення між стовпцями, якщо це потрібно
//     handleMoveBetweenColumns(draggedItem, source, destination);
//   }
// };





const onDragEnd = (result) => {
  const { source, destination, draggableId } = result;
  console.log('Відбувається переміщення', source, destination, draggableId)
  if (!destination) {
    console.log("Переміщення за межі droppable області");
    return; // Якщо елемент відпущений за межами droppable області, нічого не робимо.
  }
  // Якщо переміщення відбувається між різними постами
  
  const sourceParts = source.droppableId.split('-');
  const destinationParts = destination.droppableId.split('-');
  
  // Переконуємося, що формат ID відповідає очікуваному
  if (sourceParts.length >= 2 && destinationParts.length >= 2) {
    const sourcePostId = sourceParts[0];
    const destinationPostId = destinationParts[0];
    const plate = draggableId.split('-')[2];
    console.log(plate)
    console.log(sourcePostId, destinationPostId)
    // Логіка для визначення, чи переміщення відбувається між різними постами
    if (sourcePostId !== destinationPostId || sourcePostId === destinationPostId) {
      console.log(`Переміщення авто ${draggableId} з поста ${sourcePostId} до поста ${destinationPostId}`);


      const draggedItem = records.find(record => record.plate === plate);
      if (!draggedItem) {
        console.error("Автомобіль для переміщення не знайдено:", plate);
        return;
      }

      // Оновіть дані або стан тут для відображення переміщення авто між постами
      handlePostChange(draggedItem, destination);
       // Припускаючи, що ви використовуєте setOpenModal для управління станом модального вікна
      return;
    }
  } 


  // Перевірка на переміщення у таблицю
  if (source.droppableId !== destination.droppableId) {
    if (destination.droppableId.includes('-')) {
      const draggedItem = records.find(record => record.id.toString() === draggableId);
      if (draggedItem) {
        
        handleMoveToTable(draggedItem, destination);
        return;
      }
    }
  }

  // Перевірка на переміщення між стовпцями (залишається без змін)
  if (source.droppableId !== destination.droppableId) {
    console.log("PPPPPPPPPP",draggableId)
    const draggedItem = records.find(record => record.id.toString() === draggableId);
    if (draggedItem) {
      handleMoveBetweenColumns(draggedItem, source, destination);
    }
  }
};

function handlePostChange(draggedItem, destination) {
  console.log('Переміщений елемент:', draggedItem);
  console.log("Місце призначення:", destination);

  const [destinationPost, destinationHour] = destination.droppableId.split('-');
  console.log("Пост призначення:", destinationPost, "Година призначення:", destinationHour);

  // Підготовка оновлених даних для таблиці
  let updatedTableData = { ...tableData };
  const destinationKey = `${destinationPost}-${destinationHour}`;
  
  if (!updatedTableData[destinationKey]) {
    updatedTableData[destinationKey] = [];
  }

  // Додавання переміщеного елемента до відповідного часового слоту в таблиці
  updatedTableData[destinationKey].push({
    id: draggedItem.id,
    name: draggedItem.name, // Можливо, вам знадобиться використати інше поле для імені
    color: carColors[draggedItem.id] || defaultCarColor // За замовчуванням або вибраний колір
    // Додайте тут будь-які додаткові поля, які вам потрібні
  });

  // Оновлення стану таблиці
  setTableData(updatedTableData);

  // Збереження вибраного автомобіля та поста для подальшого використання в модальному вікні
  setSelectedCar(draggedItem);
  setSelectedPost(destinationPost);
  // Також можна зберегти destinationHour, якщо він буде використовуватися

  // Відкриття модального вікна
  setOpenModal(true);
};







const handleMoveToTable = (draggedItem, destination) => {
  console.log('Переміщений елемент:', draggedItem);
  console.log("Місце призначення:", destination);

  const [destinationPost, destinationHour] = destination.droppableId.split('-');
  console.log("Пост призначення:", destinationPost, "Година призначення:", destinationHour);

  // Підготовка оновлених даних для таблиці
  let updatedTableData = { ...tableData };
  const destinationKey = `${destinationPost}-${destinationHour}`;
  
  if (!updatedTableData[destinationKey]) {
    updatedTableData[destinationKey] = [];
  }

  // Додавання переміщеного елемента до відповідного часового слоту в таблиці
  updatedTableData[destinationKey].push({
    id: draggedItem.id,
    name: draggedItem.name, // Можливо, вам знадобиться використати інше поле для імені
    color: carColors[draggedItem.id] || defaultCarColor // За замовчуванням або вибраний колір
    // Додайте тут будь-які додаткові поля, які вам потрібні
  });

  // Оновлення стану таблиці
  setTableData(updatedTableData);

  // Збереження вибраного автомобіля та поста для подальшого використання в модальному вікні
  setSelectedCar(draggedItem);
  setSelectedPost(destinationPost);
  // Також можна зберегти destinationHour, якщо він буде використовуватися

  // Відкриття модального вікна
  setOpenModal(true);
};









function sendStatusUpdate(id, status) {
  const apiKey = localStorage.getItem('api');
  console.log('DANI DLA', id, status);
  fetch('https://api.assist.cam/edit_status', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': apiKey,
    },
    body: JSON.stringify({
      id: id,
      status: status,

    }),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Статус оновлено:', data);
    alert(data.message);
    // Оновіть стан тут, якщо потрібно
  })
  .catch(error => {
    console.error('Помилка оновлення статусу:', error);
  });
}

const changeStatus = (id, newStatus) => {
  // Використання sendStatusUpdate для відправлення оновлення статусу на сервер
  sendStatusUpdate(id.id, newStatus);
  console.log(`Запит на зміну статусу для ${id} на ${newStatus} відправлено`);
};



const handleMoveBetweenColumns = (draggedItem, source, destination) => {

  // Використовуйте switch-case для обробки різних сценаріїв переміщення
  switch (destination.droppableId) {
    case "Заплановані":
      if (source.droppableId === "Нерозібрані") {
        setModalFormData({ service: '', note: '', carId: draggedItem.id });
        console.log('Відкриваємо модальне вікно для', draggedItem.id);
        setIsServiceModalOpen(true);
      }
      break;
    case "Затвердженно":
      // Логіка для "Затвердженно"
      changeStatus(draggedItem, "Затвердженно");
      break;
    case "Авто на місці":
      // Логіка для "Авто на місці"
      changeStatus(draggedItem, "Авто на місці");
      break;
    case "Завершені":
      // Логіка для "Завершено"
      changeStatus(draggedItem, "✅");
      break;
    case "Видача":
      // Логіка для "Видача"
      changeStatus(draggedItem, "Видача");
      break;
    case "Сплачено":
      // Логіка для "Сплачено"
      changeStatus(draggedItem, "Сплачено");
      break;
    case "Відмова":
      // Логіка для "Відмова"
      console.log('Відкриваємо модальне вікно з причинами відмови для', draggedItem.id);
      handleOpenDenialModal(draggedItem);
      break;
      case "В роботі":

        // Якщо елемент переміщується з "Завершені" у "В роботі"
        if (source.droppableId === "Завершені") {

          // Виклик функції зміни статусу
          changeStatus(draggedItem, "⚙");
        }
        // Якщо елемент переміщується з "Нерозібрані" у "В роботі"
        else if (source.droppableId === "Нерозібрані") {
          // Тут можна додати логіку специфічну для цього сценарію
          // Наприклад, встановити статус в "В роботі" або виконати інші необхідні дії
          changeStatus(draggedItem, "⚙");
        }
        break;
      break;
    default:
      console.log('Переміщення не вимагає спеціальної обробки');
  }
};



const renderTableHeader = () => {
  return (
    <Box display="flex">
      <Box width="100px" textAlign="center" border="1px solid black">Пости/години</Box>
      {hours.map(hour => (
        <Box key={hour} flex="1" textAlign="center" border="1px solid black">{hour}</Box>
      ))}
    </Box>
  );
};

const renderTableRows = () => {


  // Перетворення selectedDay у формат, сумісний з датами в tableData
  const selectedDate = moment(selectedDay).format('YYYY-MM-DD');

  return posts.map((post) => {
    if (post.id === 0 || post.id === 1) {
      return null; // Пропускаємо невідповідні пости
    }

    return (
      <Box key={post.id} display="flex" justifyContent="space-between" style={{ marginBottom: '10px' }}>
        <Box width="100px" textAlign="center" p={1} border="1px solid black" bgcolor="1D367A">
          {post.name || 'Невідомий пост'}
        </Box>
        
        {hours.map((hour) => {
          const droppableId = `${post.id}-${hour}`;
          const hourKey = `${post.id}-${hour}`;
          
          const carsForHour = tableData[hourKey]
            ? tableData[hourKey].filter(car => moment(car.date_r).format('YYYY-MM-DD') === selectedDate)
            : [];

          return (
            <Droppable key={droppableId} droppableId={droppableId} direction="vertical">
              {(provided, snapshot) => (
                <Box
                flex="1"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    width: '100px',
                    height: 'auto',
                    border: '1px solid black',
                    backgroundColor: snapshot.isDraggingOver ? 'blue' : '#617c85',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    
                  }}
                >
                  {carsForHour.map((car, index) => {
                    const draggableId = `${car.id}-${car.name}`; // Унікальний ID для Draggable
                    return (
                      <Draggable key={draggableId} draggableId={draggableId} index={index}>
                        {(provided) => (
                          <Tooltip
                            title={<img src={car.image || '/path/to/default/image.jpg'} alt={car.name} style={{ width: '300px', height: 'auto' }} />}
                            placement="top"
                            arrow
                          >
                            <div
                              ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <Box
                              style={{
                                backgroundColor: car.color || '#6E6E6E', // Використання заданого кольору або дефолтного
                                width: '62px',
                                height: 'auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                
                                // Додано невеликий відступ
                                color: 'black',
                                fontSize: '1rem',
                              }}>{car.name}
                              </Box>
                            
                              
                            </div>
                          </Tooltip>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          );
        })}
      </Box>
    );
  });
};




const renderModal = () => {
  return (
    <Dialog open={openModal} onClose={handleCloseDialog} fullWidth>
      <DialogTitle>Оберіть параметр для авто:  {selectedCar ? selectedCar.name : ''}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel>Початок роботи</InputLabel>
              <Select value={selectedStart} onChange={(e) => setSelectedStart(e.target.value)}>
                {generateTimeOptions().map(time => (
                  <MenuItem key={time} value={time}>{time}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Кінець роботи</InputLabel>
              <Select value={selectedEnd} onChange={(e) => setSelectedEnd(e.target.value)}>
                {generateTimeOptions().map(time => (
                  <MenuItem key={time} value={time}>{time}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Колір</InputLabel>
              <Select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)}>
              <MenuItem value="#B80000">Червоний</MenuItem>
              <MenuItem value="#33FF57">Зелений</MenuItem>
              <MenuItem value="#3333FF">Синій</MenuItem>
              <MenuItem value="#FFD700">Золотий</MenuItem>
              <MenuItem value="#FFC0CB">Рожевий</MenuItem>
              <MenuItem value="#800080">Фіолетовий</MenuItem>
              <MenuItem value="#FFFF00">Жовтий</MenuItem>
              <MenuItem value="#FFA500">Оранжевий</MenuItem>
              <MenuItem value="#A52A2A">Коричневий</MenuItem>


                {/* Add more color options as needed */}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleTimeSlotChange}>Confirm</Button>
          </DialogActions>
        </Dialog>
  );
};
  

const handleTimeSlotChange = () => {
  const updatedTableData = { ...tableData };
  const timeSlotKey = `${selectedPost}-${selectedStart}`;
  
  if (!updatedTableData[timeSlotKey]) {
    updatedTableData[timeSlotKey] = [];
  }

  let carDataToUpdate;
  const existingCarIndex = updatedTableData[timeSlotKey].findIndex(car => car.id === selectedCar.id);

  if (existingCarIndex !== -1) {
    // Якщо авто вже є в цьому слоті, оновлюємо його дані
    updatedTableData[timeSlotKey][existingCarIndex] = {
      ...updatedTableData[timeSlotKey][existingCarIndex],
      color: selectedColor || updatedTableData[timeSlotKey][existingCarIndex].color,
      name: selectedCar.plate,
      timeSlotStart: selectedStart,
      timeSlotEnd: selectedEnd,
      date: selectedDay  // Додавання selectedDay
    };
    carDataToUpdate = updatedTableData[timeSlotKey][existingCarIndex];
  } else {
    // Якщо авто ще немає в цьому слоті, додаємо його
    const newCarData = {
      id: selectedCar.id,
      post: selectedPost,
      name: selectedCar.plate,
      image: selectedCar.image,
      color: selectedColor || carColors[selectedCar.id] || defaultCarColor,
      timeSlotStart: selectedStart,
      timeSlotEnd: selectedEnd,
      date: selectedDay  // Додавання selectedDay
    };
    updatedTableData[timeSlotKey].push(newCarData);
    carDataToUpdate = newCarData;
  }

  // Оновлення стану tableData
  setTableData(updatedTableData);

  console.log("Дані Back", { [timeSlotKey]: [carDataToUpdate] });
  // Відправляємо лише змінені дані на бекенд
  sendDataToBackend({ [timeSlotKey]: [carDataToUpdate] });

  // Закриваємо модальне вікно
  setOpenModal(false);
};









  









  
const generateTimeOptions = () => {
  const options = [];
  for (let hour = 9; hour <= 18; hour++) {
      const time = `${hour.toString().padStart(2, '0')}:00`;
      options.push(time);
  }
  return options;
};



  const renderSidebar = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <IconButton onClick={goToPreviousMonth}>
          <ArrowCircleLeftIcon />
        </IconButton>
        <Typography variant="h5">{currentMonth.format('MMMM YYYY')}</Typography>
        <IconButton onClick={goToNextMonth}>
          <ArrowCircleRightIcon />
        </IconButton>
      </Box>
      <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} sm={8} md={11}> {/* Адаптивність: Ви можете налаштувати розміри під різні екрани */}
        <Box>
          <Grid container spacing={1}>
            {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'].map((day, index) => (
              <Grid item xs key={index}>
                <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>{day}</Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={1}>
            {renderCalendar()}
          </Grid>
        </Box>
      </Grid>
    </Grid>
        </Box>
        
        
        

  
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', overflow: 'auto' }}>
        {/* {renderCarAppointments()} */}
        
        </Box>
    
      </Box>
    );
  };

  const renderTable = () => {
    return (
      <Box sx={{ width: '95%', overflowX: 'auto' }}>
        <Box sx={{
          display: 'inline-block', // Зробити елемент інлайн-блоком, щоб він займав лише необхідний простір
          border: '2px solid #A9A9A9', // Рамка
          padding: '8px', // Відступи всередині бокса
          margin: '8px', // Відступи навколо бокса
          borderRadius: '4px', // Закруглені кути
          // Інші стилі можна додати за потребою
        }}>
          <Box 
        sx={{ 
            display: 'flex', 
            alignItems: 'center', // Вирівнювання елементів по вертикальній осі
            justifyContent: 'space-between', // Розподіл простору між елементами
        }}
    >
        <Typography variant="h3">
            {formattedDate}
        </Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: 'green', padding: '5px', margin: '5px' }}
          startIcon={<AddBoxIcon />}
          onClick={handleOpenCreateModal}
        >
          Створити запис
        </Button>
    </Box>
        </Box>
        <Box sx={{border: '2px solid #D3D3D3', padding: '6px', borderRadius: '10px'}}>
        {renderTableHeader()}
        
        {renderTableRows()}
        </Box>
        {renderModal()} 
      </Box>
    );
  };


  return (
    <Box m="20px" sx={{backgroundColor: "rgba(0, 0, 0, 0)", borderRadius: '10px'}}>
    <Box
            display="grid"
            gridTemplateColumns="repeat(12, minmax(auto, 1fr))"
            gridAutoRows="minmax(10vh, 10vh)"
            gap="10px"

          >
            
      <DragDropContext onDragEnd={onDragEnd}>
        
        <Box
        gridColumn="span 12"
        gridRow="span 5"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '2px solid #D3D3D3',
          borderRadius:"10px",
          overflow: 'auto',
           // Prevent contents from spilling outside
        }}
      >
        {renderTopColumns()}
        
        </Box>

        <Box
        gridColumn="span 3"
        gridRow="span 4"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '2px solid #D3D3D3',
          borderRadius:"10px",
          overflow: 'hidden', // Prevent contents from spilling outside
        }}
      >
        {renderSidebar()}
        </Box>

        <Box
        gridColumn="span 9"
        gridRow="span 4"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '2px solid #D3D3D3',
          borderRadius:"10px",
          overflow: 'hidden', // Prevent contents from spilling outside
        }}
      >
        {renderTable()} {/* Рендер таблиці */}
        </Box>
      </DragDropContext>


      <Dialog open={isServiceModalOpen} onClose={() => setIsServiceModalOpen(false)}>
  <DialogTitle>Вибір послуги</DialogTitle>
  <DialogContent>
    <FormControl fullWidth>
      <InputLabel>Послуга</InputLabel>
      <Select
        value={modalFormData.service}
        onChange={(e) => setModalFormData(prev => ({...prev, service: e.target.value}))}
      >
        {/* Тут додайте MenuItem для кожної послуги */}
        <MenuItem value="Діагностика">Діагностика</MenuItem>
        <MenuItem value="Ремонт">Ремонт</MenuItem>
      </Select>
    </FormControl>
    <TextField
      margin="dense"
      label="Примітка"
      fullWidth
      variant="outlined"
      value={modalFormData.note}
      onChange={(e) => setModalFormData(prev => ({...prev, note: e.target.value}))}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsServiceModalOpen(false)}>Відміна</Button>
    <Button onClick={handleSubmit}>Зберегти</Button>
  </DialogActions>
</Dialog>

<Dialog open={isDenialModalOpen} onClose={() => setIsDenialModalOpen(false)}>
  <DialogTitle>Виберіть причину відмови</DialogTitle>
  <DialogContent>
    <FormControl fullWidth>
      <InputLabel id="denial-reason-label">Причина відмови</InputLabel>
      <Select
        labelId="denial-reason-label"
        id="denial-reason"
        value={denialReason}
        label="Причина відмови"
        onChange={(e) => setDenialReason(e.target.value)}
      >
        <MenuItem value="notMatch">Авто не відповідає вимогам</MenuItem>
        {/* Додайте інші причини відмови */}
      </Select>
    </FormControl>
    {denialReason === 'notMatch' && (
      <TextField
        autoFocus
        margin="dense"
        id="carModel"
        label="Вкажіть марку та модель авто"
        type="text"
        fullWidth
        variant="outlined"
        value={carModel}
        onChange={(e) => setCarModel(e.target.value)}
      />
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsDenialModalOpen(false)}>Скасувати</Button>
    <Button onClick={() => {
      // Тут додайте логіку обробки вибору причини відмови
      console.log('Обрана причина відмови:', denialReason, 'Марка і модель авто:', carModel);
      setIsDenialModalOpen(false);
    }}>Підтвердити</Button>
  </DialogActions>
</Dialog>



      <CustomerDialog open={isModalOpen} handleClose={handleCloseModal} carId={activeCarId}/>
      <CreateRecordDialog
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        apiKey={apiKey}
        date={selectedDay} // Передача apiKey в CreateRecordDialog, якщо потрібно
      />
    
    </Box>
    </Box>
  );
  };

export default Scheduler;
