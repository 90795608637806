import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Chip, Button } from '@mui/material';
import ca from 'date-fns/locale/ca';

const DiagnosticDialog = ({ open, onClose, carData }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [activeChipIndex, setActiveChipIndex] = useState(null);
const [allReasons, setAllReasons] = useState([])
const[totalcost, setTotalCost] = useState([])
const[totaltime, setTotalTime] = useState([])

  const setActiveChip = (index) => {
    setActiveChipIndex(index);
  };



  useEffect(() => {
    // Функція для отримання списку причин з сервера
    const fetchReasons = async () => {
        const apiKey = localStorage.getItem('api');
      try {
        const response = await fetch('https://api.assist.cam/reason', {
          method: 'GET',
          headers: {
            'X-Api-Key': apiKey,
          },
        });
        if (response.ok) {
          const data = await response.json(); // Парсимо відповідь як JSON
          setAllReasons(data); // Оновлюємо стейт зі списком причин
        } else {
          throw new Error('Помилка при отриманні списку причин');
        }
      } catch (error) {
        console.error('Помилка при отриманні списку причин:', error);
      }
    };

    fetchReasons(); // Викликаємо функцію отримання причин під час завантаження компоненту
  }, []);


  
 
  
  const [suggestions, setSuggestions] = useState([]);
  const suggestionIndexRef = useRef(-1);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value.trim() === '') {
      setSuggestions([]);
    } else {
      const filteredReasons = allReasons.filter(reason =>
        reason.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredReasons.filter(reason => !selectedReasons.includes(reason)));
    }
    suggestionIndexRef.current = -1; // Скидаємо індекс підказки при кожному введенні
  };

  const handleSuggestionClick = (suggestion) => {
    const { min_p, max_p, min_t, max_t } = suggestion;
    const updatedSelectedReasons = [...selectedReasons, { name: suggestion.name, min_p, max_p, min_t, max_t }];
    setSelectedReasons(updatedSelectedReasons);
    setInputValue('');
    calculateTotalCostAndTime(updatedSelectedReasons);
  };
  
  const calculateTotalCostAndTime = (selectedReasons) => {
    const totalCost = selectedReasons.reduce((acc, curr) => {
      return acc + (curr.max_p ? (curr.max_p + curr.min_p) / 2 : curr.min_p);
    }, 0);
    
    const totalTime = selectedReasons.reduce((acc, curr) => {
      return acc + (curr.max_t ? (curr.max_t + curr.min_t) / 2 : curr.min_t);
    }, 0);
  
    setTotalCost(totalCost);
    setTotalTime(totalTime);
  };

  const handleDeleteReason = (reasonToDelete) => {
    // Видаляємо причину зі списку вибраних
    const updatedSelectedReasons = selectedReasons.filter((reason) => reason !== reasonToDelete);
    setSelectedReasons(updatedSelectedReasons);
    
    // Перераховуємо суму та час після видалення причини
    calculateTotalCostAndTime(updatedSelectedReasons);
  };

  const handleSubmit = () => {
    onSubmit(selectedReasons);
    setSelectedReasons([]);
    setInputValue('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      suggestionIndexRef.current = Math.min(suggestionIndexRef.current + 1, suggestions.length - 1);
      setActiveChipIndex(suggestionIndexRef.current);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      suggestionIndexRef.current = Math.max(suggestionIndexRef.current - 1, -1);
      setActiveChipIndex(suggestionIndexRef.current);
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      setInputValue((prevValue) => {
        const input = document.getElementById('reason-input');
        const cursorPosition = input.selectionStart;
        if (cursorPosition === prevValue.length) {
          return prevValue;
        } else {
          return prevValue.substring(0, cursorPosition) + prevValue.substring(cursorPosition + 1);
        }
      });
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      setInputValue((prevValue) => {
        const input = document.getElementById('reason-input');
        const cursorPosition = input.selectionStart;
        if (cursorPosition === 0) {
          return prevValue;
        } else {
          return prevValue.substring(0, cursorPosition - 1) + prevValue.substring(cursorPosition);
        }
      });
    } else if (event.key === 'Enter' && suggestionIndexRef.current !== -1) {
      handleSuggestionClick(suggestions[suggestionIndexRef.current], suggestionIndexRef.current);
    }
  };

  const handleChipNavigation = (event, index) => {
    if (event.key === 'ArrowRight') {
      const nextIndex = index < selectedReasons.length - 1 ? index + 1 : 0;
      const chip = document.getElementById(`chip-${nextIndex}`);
      chip.focus();
    } else if (event.key === 'ArrowLeft') {
      const prevIndex = index > 0 ? index - 1 : selectedReasons.length - 1;
      const chip = document.getElementById(`chip-${prevIndex}`);
      chip.focus();
    }
  };
  const handleAdditionalFieldChange = (event) => {
    setAdditionalFieldValue(event.target.value);
  };

  const hours = Math.floor(totaltime / 60);
const minutes = totaltime % 60;


  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ border: '2px solid black', borderRadius: '10px', padding: '5px' }}>
            Причини звернення клієнта для авто:   
            </div>
            <div>
            <span>Сума: {totalcost} грн</span>
            <span style={{ marginLeft: '10px' }}>Час: {hours} год {minutes} хв</span>
            </div>
        </div>
        </DialogTitle>

      <DialogContent style={{ display: 'flex', alignItems: 'flex-start', gap: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flexGrow: 1 }}>
          <div style={{ border: '2px solid black', borderRadius: '10px', padding: '5px',marginTop: '5px', minHeight: '150px', minWidth: '200px', width: '350px' }}>
            {selectedReasons.map((reason, index) => (
              <Chip
                key={index}
                id={`chip-${index}`}
                label={reason.name}
                onDelete={() => handleDeleteReason(reason)}
                style={{ margin: '5px', color: 'white', backgroundColor: '#333' }}
                onKeyDown={(event) => handleChipNavigation(event, index)}
                tabIndex="0"
              />
            ))}
          </div>
          <TextField
            fullWidth
            margin="normal"
            label="Нова причина"
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            id="reason-input" // Додавання ідентифікатора для поля вводу
          />
          {suggestions.length > 0 && (
            <>
              {suggestions.map((suggestion, index) => (
                <Chip
                  key={index}
                  label={suggestion.name}
                  clickable
                  onClick={() => handleSuggestionClick(suggestion, index)}
                  style={{ margin: '5px', backgroundColor: activeChipIndex === index ? '#008000' : '#4169E1' }}
                />
              ))}
            </>
          )}
        </div>
        <TextField
  multiline
  fullWidth
  margin="normal"
  variant="filled"
  rows={10}
  style={{ flexGrow: 1, margin: '2px',border: '2px solid black', borderRadius: '10px', }}
  inputProps={{
    style: {
      
      padding: '2px', // Приклад кастомного відступу
      fontSize: '14px', // Приклад кастомного розміру шрифту
      disableUnderline: true,
    },
    
  }}
/>


      </DialogContent>
      <div style={{ margin: '10px', textAlign: 'right' }}>
        
        <Button variant="outlined" color="secondary" onClick={onClose} style={{ marginRight: '10px' }}>Скасувати</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Зберегти</Button>
      </div>
    </Dialog>
  );
};

export default DiagnosticDialog;
