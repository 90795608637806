import React, { useState, useEffect, useContext } from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { tokens } from "../theme";
import { PieChart, Pie, Cell } from 'recharts';
import {
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Avatar,
  Card,
  CardContent,
  useTheme,
  Modal,
  Paper,
  Tabs, Tab,
  Select, MenuItem, FormControl, InputLabel, 
  Icon
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ClockTimePicker from './WorkTime';
import { Link, Checkbox, FormControlLabel } from '@mui/material';

import PaymentDialog from './Payment';

import TariffCalculator from './Pay';

import PeopleIcon from '@mui/icons-material/People';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import PaymentIcon from '@mui/icons-material/Payment';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CustomTable from './DataInfo';
import CompleteTab from './Complete';
import GarageIcon from '@mui/icons-material/Garage';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import axios from 'axios';

const AccountSettings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [modalOpen, setModalOpen] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    const [status_ag, setStatus_ag] = useState(0);
    const [clientDate, setClientData] = useState({});

    const [selectedTab, setSelectedTab] = useState(0);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState('');

    const [serviceName, setServiceName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [email, setEmail] = useState('');
    const apiKey = localStorage.getItem('api');

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      address: '',
      serviceType: '',
      email: '',
      status: '',
      col_people: '',
      date_e: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    

  
  
    const handleChange = (name) => (event) => {
      setClientData(prevState => ({
        ...prevState,
        [name]: event.target.value
      }));
    };
    
  
    const handleSubmit = async () => {
      setIsSubmitting(true);
      const apiKey = localStorage.getItem('api');
      try {
        // Складіть URL вашого API та дані, які потрібно відправити
        const apiUrl = 'https://api.assist.cam/updateinfo';
        const dataToSend = { ...clientDate };
        
        // Відправлення запиту на оновлення даних
        const response = await axios.post(apiUrl, dataToSend, 
          { headers: { 
            'X-Api-Key': apiKey 
          }
          });
    
        // Перевірка відповіді від сервера
        if (response.status === 200) {
          console.log('Дані успішно оновлені');
          // Опційно: оновіть локальний стан, якщо потрібно відобразити змінені дані
        } else {
          // Обробка можливих помилок відповіді
          console.error('Помилка оновлення даних:', response.status);
        }
      } catch (error) {
        // Обробка помилок HTTP запиту
        console.error('Помилка відправлення даних:', error);
      }
    
      setIsSubmitting(false);
      toggleEditingMode(); // Вийти з режиму редагування
    };

    const toggleEditingMode = () => {
      setIsEditing(!isEditing);
      
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Обробник для зміни стану чекбоксу
    const handleAgreeChange = (event) => {
      setIsAgreed(event.target.checked);
      // Тут можна відправити дані на бекенд, якщо потрібно
    };

    const [modalsOpen, setModalsOpen] = useState(false);
    const [documentUrl, setDocumentUrl] = useState('');

  const openModalWithDocument = (url) => {
    setDocumentUrl(url);
    setModalsOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const closeModals = () => {
    setModalsOpen(false);
    setDocumentUrl('');
  };

  

      
    const closeModal = () => {
      setModalOpen(false);

    };

    


  const chartData = [
    { name: 'Група A', value: 400 },
    { name: 'Група B', value: 300 },
    { name: 'Група C', value: 300 },
  ];







  async function fetchData() {
    try {
      const apiKey = localStorage.getItem('api');
      // Припустимо, що ваш API ендпоінт для отримання даних виглядає так
      const url = 'https://api.assist.cam/get_company';
      const config = {
        headers: {
          'X-Api-Key': apiKey, // Тут вказується ваш API ключ
          // Можна додати інші заголовки, якщо це необхідно
        }
      };
      const response = await axios.get(url, config);
  
      if (response.status === 200) {
        // Дані успішно отримані
        console.log(response.data);
  
        // Тут можна оновити стан вашого компоненту за допомогою отриманих даних
        // Наприклад:
        setClientData(response.data[0]);
        // setMockEmployees(response.data.mockEmployees);
  
      } else {
        // Обробка відповіді, якщо статус не 200
        console.error('Error fetching data:', response.status);
      }
    } catch (error) {
      // Обробка помилки запиту
      console.error('Error making request:', error);
    }
  }

  useEffect(() => {
    fetchData(); // Виклик функції при першому рендерингу компонента
  }, []);






  
 

  return (
    <Box m='20px'  sx={{overflow: 'hidden'}}>
        
    {/* GRID & CHARTS */}
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, minmax(auto, 1fr))"
      gridAutoRows="minmax(10vh, 10vh)"
      gap="10px"

    >
          
        {/* Блок основної інформації */}
        <Box
            gridColumn="span 4"
            gridRow="span 6"
            backgroundColor = "rgba(0, 0, 0, 0)"
            borderRadius="10px"
            border= '2px solid #D3D3D3'
            display="flex"
            flexDirection="column" // Змінили на колонку, щоб вирівняти елементи вертикально
            padding="10px"
            marginTop='5px'
          >
            
          
            <Typography variant="h4" gutterBottom style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              Основна інформація про Автосервіс
              <IconButton onClick={isEditing ? handleSubmit : toggleEditingMode}>
                {isEditing ? <SaveIcon /> : <EditIcon />}
              </IconButton>

          </Typography>


            <Box display="flex" alignItems="center" >
              
              
              <Grid container spacing={2}>
            <Grid item xs={6}>

            {
                isEditing ? (
                  // Відображення форми для редагування
                  <div>
                    <Box display="flex" alignItems="center" gap={2}>
                    <Icon>
                        <GarageIcon fontSize="title" />
                      </Icon>
                    <FormControl>
                    <TextField
                      label="Назва сервісу"
                      value={clientDate.name}
                      onChange={handleChange('name')}
                    />
                    </FormControl>
                    </Box> 
                    
                  </div>
                ) : (
                  // Відображення інформації (не в режимі редагування)
                  <div>
                    <Box display="flex" alignItems="center" gap={2}>
                    <Icon>
                        <GarageIcon fontSize="title" />
                      </Icon>
                      <Typography variant="h3">
                        {clientDate.name}
                      </Typography>
                      
                    </Box> 
                  </div>
                )
              }



                
            </Grid>
            <Grid item xs={6}>
            {
                isEditing ? (
                  // Відображення форми для редагування
                  <div>
                    <Box display="flex" alignItems="center" gap={2}>
                    <Icon>
                        <LocationOnIcon fontSize="title" />
                      </Icon>
                    <TextField
                      label="Фактична адреса"
                      value={clientDate.address}
                      onChange={handleChange('address')}
                    />
                    </Box>
                  </div>
                ) : (
                  // Відображення інформації (не в режимі редагування)
                  <div>
                    <Box display="flex" alignItems="center" gap={2}>
                    <Icon>
                        <LocationOnIcon fontSize="title" />
                      </Icon>
                   
                    <Typography variant="outlined">
                      {clientDate.address}
                      </Typography>
                </Box>
                  </div>
                )
              }
            
            </Grid>
            </Grid>
            </Box>
            <Divider sx={{ my: 2, bgcolor: 'primary.light' }} />

              
                    
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Тип сервісу</InputLabel>
                        <Select
                            value={serviceType}
                            onChange={e => setServiceType(e.target.value)}
                        >
                            <MenuItem value="Автосервіс">Автосервіс</MenuItem>
                            <MenuItem value="Мойка">Мойка</MenuItem>
                            <MenuItem value="Шиномонтаж">Шиномонтаж</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    
                    
               
            
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
            <Typography variant="h7">
                <LocationOnIcon sx={{ verticalAlign: 'bottom'}} />
                Адреса сервісу: {clientDate.address}

              </Typography>
            <Typography variant="h4">
            <PaymentIcon sx={{ verticalAlign: 'bottom', marginTop: '4px', mr: 1 }} />
                Статус підписки: {clientDate.status}
            </Typography>

            <Typography variant="h5">
            <PeopleIcon sx={{ verticalAlign: 'bottom', marginTop: '4px', mr: 1 }} />
                Загальна кількість співробітників {clientDate.col_people}
            </Typography>
              
              
            <Box
              sx={{
                display: 'flex', // Елементи розміщуються горизонтально
                alignItems: 'center', // Вирівнювання елементів за центром по вертикалі
                justifyContent: 'space-between', // Максимальний простір між групами елементів
                mb: 1, // Відступ знизу
              }}
            >
              {/* Ліва частина з текстом "Дійсний до:" */}
              <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
                <EventIcon sx={{ verticalAlign: 'bottom', mr: 1 }} />
                Дійсний до: {clientDate.date_e}
              </Typography>

              {/* Права частина з посиланнями */}
              <Box>
                <Link
                  href="#"
                  sx={{ color: 'white' }}
                  onClick={() => openModalWithDocument('../../assets/Політика конфіденційності.pdf')}
                  underline="hover"
                >
                  Політика конфіденційності
                </Link>
                {' | '}
                <Link
                  href="#"
                  sx={{ color: 'white', ml: 2 }} // Відступ зліва для другого посилання
                  onClick={() => openModalWithDocument('../../assets/Оферта.pdf')}
                  underline="hover"
                >
                  Публічна оферта
                </Link>
              </Box>
            </Box>

            </Box>
           
            
          
          </Box>



          <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor = "rgba(0, 0, 0, 0)"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          border= '2px solid #D3D3D3'
          justifyContent="space-between"
          
          margin="10px"
          padding="20px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
          marginTop='5px'
        >
          <ClockTimePicker/>
          
          
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor = "rgba(0, 0, 0, 0)"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          border= '2px solid #D3D3D3'
          justifyContent="space-between"
          margin="10px"
          padding="20px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
          marginTop='5px'
        >
          
        </Box>


        <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor = "rgba(0, 0, 0, 0)"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            // Змініть розмір висоти
            margin="10px" 
            border= '2px solid #D3D3D3'// Змініть розмір відступу
        >
           <IconButton style={{ fontSize: '150px', color: '#fff', marginBottom: '20px' }}>
                    <VideocamIcon />
                </IconButton>
                <Typography variant="h4" style={{ color: '#fff', marginBottom: '20px' }}>
                    Кількість камер: {clientDate.col_cam}</Typography>
                
        </Box>

        <Box
        gridColumn="span 4"
        gridRow="span 2"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
          margin: "10px",
          flexDirection: "column",
          textAlign: "center",
          border: '2px solid #D3D3D3',
        }}
      >
        <Typography variant="h3" sx={{ color: '#fff', mb: 2 }}>
          Тарифний план: {clientDate.plan}
        </Typography>

        <Divider sx={{ height: '8px', backgroundColor: '#4682B4', width: '100%' }} />

        <Typography variant="h6" sx={{ color: '#fff', mb: 2 }}>
          Дійсний до: {clientDate.subscription}
        </Typography>

        <Button
          variant="contained"
          sx={{ backgroundColor: "#FFA500", p: '5px 15px', color: '#fff', textTransform: 'none' }}
          startIcon={<ArrowCircleUpIcon />}
          onClick={() => setPaymentDialogOpen(true)}
        >
          Обрати тариф
        </Button>
      </Box>

        <Box
          gridColumn="span 2"
          gridRow="span 2"
          backgroundColor = "rgba(0, 0, 0, 0)"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          border= '2px solid #D3D3D3'
          justifyContent="space-between"
          margin="10px"
          padding="20px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
          marginTop='5px'
        >
          <Typography variant="h5">
            Пости
            </Typography>
        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 2"
          backgroundColor = "rgba(0, 0, 0, 0)"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          border= '2px solid #D3D3D3'
          justifyContent="space-between"
          margin="10px"
          padding="20px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
          marginTop='5px'
        >
          <Typography variant="h5">
            Вартість  норм години
            </Typography>
        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 2"
          backgroundColor = "rgba(0, 0, 0, 0)"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          border= '2px solid #D3D3D3'
          justifyContent="space-between"
          margin="10px"
          padding="20px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
          marginTop='5px'
        >
          <Typography variant="h5">
            Робочі години
            </Typography>
        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 2"
          backgroundColor = "rgba(0, 0, 0, 0)"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          border= '2px solid #D3D3D3'
          justifyContent="space-between"
          margin="10px"
          padding="20px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
          marginTop='5px'
        >
          <Typography variant="h5">
            Робочі дні
            </Typography>
        </Box>

      </Box>
     
      <Modal
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        aria-labelledby="tariff-calculator-title"
        aria-describedby="tariff-calculator-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            
            boxShadow: 24,
            p: 4,
          }}
        >
          <TariffCalculator />
        </Box>
      </Modal>

      {/* <PaymentDialog open={paymentDialogOpen} onClose={() => setPaymentDialogOpen(false)} apiUrl="http://10.20.40.2:7050/create_invoice" apiKey={apiKey} /> */}

      </Box>

        
      
    

    );
};
export default AccountSettings;
