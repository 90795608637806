import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Box, IconButton, Typography, TextField, Dialog,
  DialogActions, DialogContent, DialogTitle, Grid, Avatar
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const EmployeeForm = ({ open, handleClose, handleSubmit, initialEmployee }) => {
  const [employee, setEmployee] = useState(initialEmployee);

  useEffect(() => {
    setEmployee(initialEmployee);
  }, [initialEmployee]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEmployee(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{employee.id ? 'Редагувати' : 'Додати'} співробітника</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Ім'я та прізвище"
          type="text"
          fullWidth
          variant="outlined"
          value={employee.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="phone"
          label="Номер телефону"
          type="text"
          fullWidth
          variant="outlined"
          value={employee.phone}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="email"
          label="Електронна пошта"
          type="email"
          fullWidth
          variant="outlined"
          value={employee.email}
          onChange={handleChange}
        />
       <TextField
        margin="dense"
        name="login" // Замість "log"
        label="Логін"
        type="text"
        fullWidth
        variant="outlined"
        value={employee.login}
        onChange={handleChange}
        />
        <TextField
        margin="dense"
        name="password" // Замість "passwd"
        label="Пароль"
        type="password"
        fullWidth
        variant="outlined"
        value={employee.password}
        onChange={handleChange}
        />

        {/* Додати інші поля за необхідності */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Скасувати</Button>
        <Button onClick={() => handleSubmit(employee)}>Зберегти</Button>
      </DialogActions>
    </Dialog>
  );
}

const EmployeesTable = () => {
  const [employees, setEmployees] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState({});

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const apiKey = localStorage.getItem('api'); // Отримуємо API ключ з локального сховища
      const response = await axios.get('https://api.assist.cam/get_personal', {
        headers: {
          'X-Api-Key': apiKey // Встановлюємо API ключ у заголовки запиту
        }
      });
      setEmployees(response.data);
    } catch (error) {
      console.error('Помилка при завантаженні списку співробітників:', error);
    }
  };
  
  const handleAddClick = () => {
    setEditingEmployee({});
    setEditingEmployee({ name: '', phone: '', email: '', log: '', pass: '' }); // Ініціалізація з пустими значеннями

    setOpenForm(true);
  };

  const handleEditClick = (employee) => {
    setEditingEmployee(employee);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setEditingEmployee({ name: '', phone: '', email: '', log: '', pass: '' }); // Очищення або реініціалізація

    setOpenForm(false);
  };

  const apiBaseUrl = 'https://api.assist.cam'; // Базова URL вашого API
  const apiKey = localStorage.getItem('api'); // Припускаю, що ваш API ключ зберігається у localStorage
  
  const handleSubmitForm = async (employee) => {
    const config = {
      headers: {
        'X-Api-Key': apiKey
      }
    };
  
    if (employee.id) {
      // Редагування існуючого співробітника
      try {
        await axios.put(`${apiBaseUrl}/update_person/${employee.id}`, employee, config);
      } catch (error) {
        console.error('Помилка при оновленні даних співробітника:', error);
      }
    } else {
      // Додавання нового співробітника
      try {
        await axios.post(`${apiBaseUrl}/add_person`, employee, config);
      } catch (error) {
        console.error('Помилка при створенні нового співробітника:', error);
      }
    }
    fetchEmployees();
    setOpenForm(false);
  };
  
  const handleDeleteClick = async (id) => {
    const config = {
      headers: {
        'X-Api-Key': apiKey
      }
    };
  
    try {
      await axios.delete(`${apiBaseUrl}/delete_person/${id}`, config);
      fetchEmployees();
    } catch (error) {
      console.error('Помилка при видаленні співробітника:', error);
    }
  };
  

  return (
    <Box sx={{ m: 2 }}>
      <Button
        variant="contained"
        startIcon={<AddBoxIcon />}
        onClick={handleAddClick}
      >
        Додати співробітника
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ім'я та прізвище</TableCell>
              <TableCell>Номер телефону</TableCell>
              <TableCell align="right">Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell>{employee.name}</TableCell>
                <TableCell>{employee.phone}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEditClick(employee)}><EditIcon /></IconButton>
                  <IconButton onClick={() => handleDeleteClick(employee.id)}><DeleteIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EmployeeForm
        open={openForm}
        handleClose={handleCloseForm}
        handleSubmit={handleSubmitForm}
        initialEmployee={editingEmployee}
      />
    </Box>
  );
};

export default EmployeesTable;
