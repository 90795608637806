import React, { useState, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button, Typography, useTheme } from '@mui/material';

function CommercialOfferModal({ open, handleClose }) {
  const [offerItems, setOfferItems] = useState([]);
  const [newItem, setNewItem] = useState({ name: '', price: '', code: '' });
  const nameInputRef = useRef(null);

  const theme = useTheme();
  const gradientBackground = theme.palette.mode === 'dark'
    ? 'linear-gradient(to top right, #0a3d62 0%, #0a3d62 70%, #74b9ff 100%)'
    : 'linear-gradient(to top right, #567c34 0%, #567c34 20%, #023e8a 100%)'
    ;

  const handleAddItem = (event) => {
    if (event.key === 'Enter' && newItem.name.trim() && newItem.price.trim() && newItem.code.trim()) {
      setOfferItems(prevItems => [...prevItems, newItem]);
      setNewItem({ name: '', price: '', code: '' });
      event.preventDefault();
      setTimeout(() => nameInputRef.current.focus(), 0);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewItem({ ...newItem, [name]: value });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth PaperProps={{ style: { background: gradientBackground, boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)', color: 'white' }}}>
      <DialogTitle>Комерційна пропозиція</DialogTitle>
     
      <DialogContent dividers>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ flex: '1' }}>
            <p>Марка: </p>
            <p>Модель: </p>
            <p>VIN: </p>
          </div>
        </div>
        <Typography gutterBottom variant="h6" component="div" style={{ color: 'white' }}>
          
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ color: 'white' }}>Назва</TableCell>
              <TableCell style={{ color: 'white' }}>Ціна</TableCell>
              <TableCell style={{ color: 'white' }}>Код</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offerItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell style={{ color: 'white', fontSize: 'small' }}>{item.name}</TableCell>
                <TableCell style={{ color: 'white',fontSize: 'small' }}>{item.price}</TableCell>
                <TableCell style={{ color: 'white', fontSize: 'small' }}>{item.code}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField
                  ref={nameInputRef}
                  name="name"
                  value={newItem.name}
                  onChange={handleChange}
                  onKeyPress={handleAddItem}
                  fullWidth
                  placeholder="Назва"
                  InputProps={{ style: { color: 'white' } }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="price"
                  value={newItem.price}
                  onChange={handleChange}
                  onKeyPress={handleAddItem}
                  fullWidth
                  placeholder="Ціна"
                  InputProps={{ style: { color: 'white' } }}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="code"
                  value={newItem.code}
                  onChange={handleChange}
                  onKeyPress={handleAddItem}
                  fullWidth
                  placeholder="Код"
                  InputProps={{ style: { color: 'white' } }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: 'white' }}>Закрити</Button>
        {/* Можна додати кнопку "Зберегти" якщо потрібно */}
      </DialogActions>
    </Dialog>
  );
}

export default CommercialOfferModal;
