import React, { useContext, useState, useEffect } from "react";
import { Box, IconButton, useTheme,
   Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody,Popover,
    TableCell, TableHead, TableRow, TextField, MenuItem, Menu, Typography, Autocomplete } from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxIcon from '@mui/icons-material/AddBox';
import BuildIcon from '@mui/icons-material/Build'; // Іконка для ремонту
import SettingsIcon from '@mui/icons-material/Settings'; // Іконка для діагностики
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; // Іконка для заміни
import LogoutIcon from '@mui/icons-material/Logout'; // Іконка для виходу
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LoginForm from "../auth";
import axios from 'axios';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@mui/icons-material/Mail';
import ChatDialog from "../../components/Chat";
import Header from "../../components/Header";
import CreateRecordDialog from "../../components/CreateRecords";
function clearCookies() {
  const cookies = document.cookie.split(";");

  for (let cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}


const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const companyName = localStorage.getItem('companyName');
  const [openSearchResults, setOpenSearchResults] = useState(false);

  const [selectedCar, setSelectedCar] = useState(null); // Додатковий стан для вибраного авто
  const [openCarModal, setOpenCarModal] = useState(false); // Стан для відкриття другого модального вікна
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const handleOpenCreateModal = () => setOpenCreateModal(true);
  const handleCloseCreateModal = () => setOpenCreateModal(false);
  const [date, setDate] = useState('');
const [time, setTime] = useState('');
const [selectedService, setSelectedService] = useState('');
const [notes, setNotes] = useState('');
const [userInfo, setUserInfo] = useState({ name: '', company_name: '', companyId: '',  balance: '', date_e: ''});
const formattedDate = new Date(userInfo.date_e).toLocaleDateString('uk-UA');
const today = new Date();
const expiryDate = new Date(userInfo.date_e);
const daysUntilExpiry = Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24));
const apiKey = localStorage.getItem('api');

const [isChatOpen, setIsChatOpen] = useState(false); // Стан для відкриття чату

const [newMessagesCount, setNewMessagesCount] = useState(0);

  const pollNewMessages = async () => {
    try {
      const apiKey = localStorage.getItem('api');
      const url = 'https://api.assist.cam/new_messages_count';

      const config = {
        headers: {
          'X-Api-Key': apiKey
        },
      };

      const response = await axios.get(url, config);

      if (response.status === 200) {
        const responseData = response.data;
        setNewMessagesCount(responseData.count);
      } else {
        console.error('Error fetching new messages count');
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };





let dateColor = '';
if (daysUntilExpiry < 3) {
  dateColor = 'red'; // Якщо залишилось менше 3 днів до закінчення підписки
} else {
  dateColor = '#519772'; // Використовуйте бажаний колір за замовчуванням
}
const [anchorEl, setAnchorEl] = useState(null);
const [chatAnchorEl, setChatAnchorEl] = useState(null);


const handleChatIconClick = (event) => {
  setChatAnchorEl(event.currentTarget);
};

const handleCloseChat = () => {
  setChatAnchorEl(null);
};

const isChatPopoverOpen = Boolean(chatAnchorEl);

const chatPopoverId = isChatPopoverOpen ? 'chat-popover' : undefined;

// Інформація про користувача (тут припускається, що ця інформація зберігається в localStorage або отримується з контексту)

const fetchUserData = async () => {
  try {
    const apiKey = localStorage.getItem('api');
    console.log(apiKey)
    const url = 'https://api.assist.cam/user_info';

    const config = {
      headers: {
        'X-Api-Key': apiKey
      },
       // Це можна залишити, якщо вам це потрібно для іншої мети
    };

    const response = await axios.get(url, config);

    if (response.status === 200) {
      const responseData = response.data[0];
      console.log(responseData)
      localStorage.setItem('companyName', responseData.company_name);
      setUserInfo({
        name: responseData.name,
        company_name: responseData.company_name,
        companyId: responseData.companyId,
        balance: responseData.balance,
        date_e: responseData.date_e
        
        
      });
    } else {
      console.error('Error fetching user data');
    }
  } catch (error) {
    console.error('Network error:', error);
  }
};

useEffect(() => {
  fetchUserData();
}, []);




  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    fetchUserData();
  }; 

const handleProfileMenuClose = () => {
  setAnchorEl(null);
};

const handleLogout = () => {
  localStorage.removeItem('api'); // Видалення токена з localStorage
  clearCookies(); 
  localStorage.removeItem('isAuthenticated');
  window.location.reload(); // Простий спосіб перезавантажити сторінку
};
// Функція для оновлення стану послуги
const handleServiceChange = (event) => {
  setSelectedService(event.target.value);
};

// Функція для оновлення стану приміток
const handleNotesChange = (event) => {
  setNotes(event.target.value);
};



  const handleKeyDown = (e) => {
    if (e.key === "+" && e.shiftKey) {
      // Якщо натиснута клавіша "+" і одночасно утримується клавіша Shift
      handleOpenCreateModal();
    }
  };
  useEffect(() => {
    // Додавання обробника подій клавіш при монтуванні компонента
    window.addEventListener("keydown", handleKeyDown);

    // Прибирання обробника подій клавіш при демонтажі компонента
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [postCount, setPostCount] = useState(3); // Припустимо, що це ваш стан для кількості постів
  const buttonColor = postCount >= 5 ? 'red' : 'green';

  const handleCarClick = (car) => {
    if (car) { // Перевіряємо, чи обрано автомобіль
      setSelectedCar(car);
      setOpenCarModal(true); // Відкриваємо модальне вікно тільки якщо обрано автомобіль
    } else {
      setSelectedCar(null);
      setOpenCarModal(false); // Закриваємо модальне вікно, якщо автомобіль не обрано
    }
  };

  const handleCloseCarModal = () => {
    setOpenCarModal(false);
  };
  
  const determineEndpoint = (term) => {
    // Тут ви аналізуєте введений текст і визначаєте потрібний ендпоінт
    // Наприклад:
    if (term.match(/^[АВЕІКМНОРСТХABEIKMHOPCTX]{2}[0-9]{4}[АВЕІКМНОРСТХABEIKMHOPCTX]{2}$/)) {
      return '/search-by-plate';
    } else if (term.match(/[\u{2699}\u{2705}\u{2757}\u{1F5D1}\u{1F4B0}\u{1F4BB}\u{1F50D}]/u)) {
      return '/search-by-date';
    } else if (term.match(/^\d{1,4}$/)) {
      return '/search-by-document';
    }
    return '/general-search'; // Загальний запит, якщо не знайдено жодного патерну
  };

  const columnNames = ["id", "status", "date_s", "date_e", "plate", "year", "model", "vin", /* інші назви стовпців */];

  const handleSearch = async () => {
    const endpoint = determineEndpoint(searchTerm);
    const apiKey = localStorage.getItem("api");
  
    try {
      const response = await fetch(
        `https://api.assist.cam/general-search?query=${encodeURIComponent(searchTerm)}`,
        {
          method: "GET",
          headers: {
            "X-Api-Key": apiKey,
          },
        }
      );
  
      const data = await response.json();
      console.log("Response data:", data); // Додано для дебагінгу
  
      if (Array.isArray(data) && data.length > 0) {
        console.log("Setting search results:", data);
        setSearchResults(data);
        setOpenSearchResults(true);
      } else {
        console.log("No results or not an array:", data);
        setSearchResults([]);
        setOpenSearchResults(false);
      }
    } catch (error) {
      console.error("Error in the request:", error);
      setSearchResults([]);
      setOpenSearchResults(false);
    }
  };
  

  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const [carNumber, setCarNumber] = useState('');

  


  

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (searchTerm.length >= 3) {
        handleSearch();
      }
    }, 500); // Adjust the delay as needed

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);
  

  return (
    <Box display="flex" justifyContent="space-between" p={0.5}>
      <Box display="flex" alignItems="center" justifyContent="space-between" backgroundColor="primary" borderRadius="3px" width="50%" p={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={companyName} />
      </Box>

      </Box>

      {/* ICONS */}
      <Box display="flex" alignItems="center" gap={3}>
      <Autocomplete
      sx={{ width: 'auto', flexGrow: 1, minWidth: '190px' }} // Задати мінімальну ширину і дозволити рости
      options={searchResults}
        getOptionLabel={(option) => option.plate || ""}
        onChange={(event, newValue) => {
          handleCarClick(newValue);
          setSearchTerm(''); // Очистка поля пошуку після вибору авто
        }}
        inputValue={searchTerm}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        filterOptions={(options, state) => options.filter((option) => option.plate.toLowerCase().includes(state.inputValue.toLowerCase()))}
        renderOption={(props, option) => {
          console.log("Rendering option:", option.plate);
          return (
            <li {...props} key={option.id}>
              {option.plate}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Пошук авто"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyPress} // Ви вже маєте обробник для Enter
          />
        )}
      />
        
        <IconButton onClick={colorMode.toggleColorMode} style={{ margin: '0 4px' }}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>

        <IconButton onClick={handleChatIconClick} style={{ margin: '0 4px' }}>
        <Badge badgeContent={newMessagesCount} color="error">
          <MailIcon />
        </Badge>
      </IconButton>
      <Popover
        id={chatPopoverId}
        open={isChatPopoverOpen}
        anchorEl={chatAnchorEl}
        onClose={handleCloseChat}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          
          horizontal: 'left',
        }}
        style={{ width: '200px', marginTop: '10px', marginRight: '5px' }} // Ви можете налаштувати розмір вікна чату тут
      >
        <ChatDialog
          isOpen={isChatPopoverOpen}
          onClose={handleCloseChat}
          apiKey={apiKey}  // Передача apiKey в ChatDialog
        />
      </Popover>

        <IconButton onClick={handleProfileMenuOpen} style={{ margin: '0 4px' }}>
          <PersonOutlinedIcon />
        </IconButton>

        {/* <Button
          variant="contained"
          style={{ backgroundColor: 'blue', margin: '0 8px' }}
          startIcon={<SettingsIcon />}
        >
          Налаштування
        </Button> */}
        
        <Button
        
          variant="contained"
          style={{
            backgroundColor: dateColor,
            color: 'black',
            fontWeight: 'bold',
            borderRadius: '18px',
            margin: '0 4px',
            boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1), 0px 4px 4px -1px rgba(0,0,0,0.06)', // Додаємо box-shadow для ефекту елевації
            border: '1px solid #417559'

          }}
        >
          Дійсна до 🕘 {formattedDate}
        </Button>
      </Box>

      

      {/* Друге модальне вікно для відображення інформації про авто */}
      <Dialog open={openCarModal} onClose={handleCloseCarModal}>
        <DialogTitle>Інформація про авто</DialogTitle>
        <DialogContent>
          {selectedCar && (
            <div>
              <img src={selectedCar.photo} alt="Автомобіль" style={{ maxWidth: '100%' }} />
              <p>ID: {selectedCar.id}</p>
              <p>Статус: {selectedCar.status}</p>
              <p>Номер авто: {selectedCar.plate}</p>
              <p>Час у ремонті: {selectedCar.checkInTime}</p>
              <p>Період: {selectedCar.per}</p>
              <p>Марка/Модель: {selectedCar.makeModel}</p>
              <p>VIN: {selectedCar.vin}</p>
              {/* інші деталі авто */}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCarModal}>Закрити</Button>
        </DialogActions>
      </Dialog>
      
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleProfileMenuClose}
    >
      <MenuItem disabled>
        Ім'я: {userInfo.name}
      </MenuItem>
      <MenuItem disabled>
        Назва компанії: {userInfo.company_name}
      </MenuItem>
      <MenuItem disabled>
        ID компанії: {userInfo.companyId}
      </MenuItem>
      <MenuItem disabled>
        Баланс: {userInfo.balance}
      </MenuItem>
      
      
      <MenuItem onClick={handleProfileMenuClose}>
        <SettingsOutlinedIcon />
        Налаштування
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <LogoutIcon />
        Вихід
      </MenuItem>
    </Menu>

    <CreateRecordDialog
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        apiKey={apiKey} // Передача apiKey в CreateRecordDialog, якщо потрібно
      />
    </Box>
  );
};

export default Topbar; 
