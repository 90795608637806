import React, { useState, useEffect, createContext } from 'react';
import { Box, CssBaseline, ThemeProvider, createTheme, Slide, IconButton } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import ActiveTabContext from './ActiveTabContext';
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import Sidebar from "./scenes/global/Sidebar";
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Pie from "./scenes/Pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";
import LoginForm from './scenes/auth';
import CompleteTab from './components/Complete';
import CarReport from './components/CarReport';
import Scheduler from './components/Plan';
import BlacklistTable from './components/BlackList';
import Snowflake from './components/Snowflake';
import WebSocketComponent from './components/WebSocket';

import { SnackbarProvider } from 'notistack'; // Імпортуйте SnackbarProvider
import CRM from './components/CRM'
// import AccountSettings from './components/Setting';
import Tabss from './components/Tabs'
import Calls from './components/Calls';

import ThreeCall from './components/Pl_call';
import ChatIcon from '@mui/icons-material/Chat'; // Імпорт іконки чату
import ChatComponent from './components/ChatComponent'; // Припустимо, ви маєте компонент чату
import ClientModal from './components/ClientModal'; // Імпортуйте ваш компонент


// const theme = createTheme({
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: `
//         body {
//           background: linear-gradient(to top right, #041d44 0%, #041d44 70%, #519772 100%);

//         }
//       `,
//     },
//   },
// });
function App() {
  const [theme, colorMode] = useMode();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [activeTab, setActiveTab] = useState('dashboard');
 
  const [isChatOpen, setIsChatOpen] = useState(false);

  // Функція для перемикання стану чату
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };
  

  
  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'videokontrol': return <Dashboard />;
      case 'crm': return <Scheduler/>;
      case 'carReport': return <CarReport />;
      case 'Планувальник постів': return <CRM/>
      case 'Дзвінки': return <ThreeCall/>
      case 'BlackList': return <BlacklistTable/>
      case 'settings': return <Tabss/>
      
      // Додайте інші випадки
      default: return <Dashboard />;
    }
  };

  if (!isAuthenticated) {
    return <LoginForm onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <>
    
    <SnackbarProvider maxSnack={3}>
      <WebSocketComponent>
        
        <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              
              <CssBaseline />
              
                <div className="app">
                  <Sidebar />
                  <Box flexGrow={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Topbar onLogout={handleLogout} />
                    {renderActiveTab()}
                    <ClientModal /> 
                  </Box>
                </div>
              
            </ThemeProvider>
          </ColorModeContext.Provider>
        </ActiveTabContext.Provider>
        
      </WebSocketComponent>
      </SnackbarProvider>
      <IconButton 
        onClick={toggleChat}
        sx={{
          fontSize: '200px',
          position: 'fixed',
          bottom: 20,
          right: 20,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <ChatIcon sx={{fontSize: '50px',}} />
      </IconButton>

      {/* Вікно чату, яке з'являється плавно */}
      <Slide direction="up" in={isChatOpen} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'fixed',
            bottom: 70,
            right: 20,
            width: 350, // Або інша ширина, яка вам підходить
            maxHeight: 'calc(100vh - 100px)', // Максимальна висота
            overflow: 'auto', // Прокрутка, якщо контент не вміщується
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <ChatComponent /> {/* Ваш компонент чату */}
        </Box>
      </Slide>
      </>
  );
}

export default App;
