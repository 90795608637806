export const GRID_UA_UA_LOCALE_TEXT = {
    // Root
    noRowsLabel: 'Немає рядків',
    noResultsOverlayLabel: 'Результатів не знайдено.',
  
    // Density selector toolbar button text
    toolbarDensity: 'Щільність',
    toolbarDensityLabel: 'Щільність',
    toolbarDensityCompact: 'Компактно',
    toolbarDensityStandard: 'Стандартно',
    toolbarDensityComfortable: 'Зручно',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Стовпці',
    toolbarColumnsLabel: 'Оберіть стовпці',
  
    // Filters toolbar button text
    toolbarFilters: 'Фільтри',
    toolbarFiltersLabel: 'Показати фільтри',
    toolbarFiltersTooltipHide: 'Сховати фільтри',
    toolbarFiltersTooltipShow: 'Показати фільтри',
    toolbarFiltersTooltipActive: (count) => 
      count !== 1 ? `${count} активних фільтрів` : `${count} активний фільтр`,
  
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Шукати…',
    toolbarQuickFilterLabel: 'Пошук',
    toolbarQuickFilterDeleteIconLabel: 'Очистити',
  
    // Export selector toolbar button text
    toolbarExport: 'Експорт',
    toolbarExportLabel: 'Експорт',
    toolbarExportCSV: 'Завантажити як CSV',
    toolbarExportPrint: 'Друк',
    toolbarExportExcel: 'Завантажити як Excel',
  
    // Columns panel text
    columnsPanelTextFieldLabel: 'Знайти стовпець',
    columnsPanelTextFieldPlaceholder: 'Назва стовпця',
    columnsPanelDragIconLabel: 'Перемістити стовпець',
    columnsPanelShowAllButton: 'Показати всі',
    columnsPanelHideAllButton: 'Сховати всі',
  
    // Filter panel text
    filterPanelAddFilter: 'Додати фільтр',
    filterPanelRemoveAll: 'Видалити всі',
    filterPanelDeleteIconLabel: 'Видалити',
    filterPanelLogicOperator: 'Логічний оператор',
    filterPanelOperator: 'Оператор',
    filterPanelOperatorAnd: 'І',
    filterPanelOperatorOr: 'Або',
    filterPanelColumns: 'Стовпці',
    filterPanelInputLabel: 'Значення',
    filterPanelInputPlaceholder: 'Значення фільтра',
  
    // Filter operators text
    filterOperatorContains: 'містить',
    filterOperatorEquals: 'дорівнює',
    filterOperatorStartsWith: 'починається з',
    filterOperatorEndsWith: 'закінчується на',
    filterOperatorIs: 'є',
    filterOperatorNot: 'не є',
    filterOperatorAfter: 'після',
    filterOperatorOnOrAfter: 'на або після',
    filterOperatorBefore: 'до',
    filterOperatorOnOrBefore: 'на або до',
    filterOperatorIsEmpty: 'порожнє',
    filterOperatorIsNotEmpty: 'не порожнє',
    filterOperatorIsAnyOf: 'є будь-яким з',
    'filterOperator=': '=',
    'filterOperator!=': '!=',
    'filterOperator>': '>',
    'filterOperator>=': '>=',
    'filterOperator<': '<',
    'filterOperator<=': '<=',
  
    // Header filter operators text
    headerFilterOperatorContains: 'Містить',
    headerFilterOperatorEquals: 'Дорівнює',
    headerFilterOperatorStartsWith: 'Починається з',
    headerFilterOperatorEndsWith: 'Закінчується на',
    headerFilterOperatorIs: 'Є',
    headerFilterOperatorNot: 'Не є',
    headerFilterOperatorAfter: 'Після',
    headerFilterOperatorOnOrAfter: 'На або після',
    headerFilterOperatorBefore: 'До',
    headerFilterOperatorOnOrBefore: 'На або до',
    headerFilterOperatorIsEmpty: 'Порожнє',
    headerFilterOperatorIsNotEmpty: 'Не порожнє',
    headerFilterOperatorIsAnyOf: 'Є будь-яким з',
    'headerFilterOperator=': 'Дорівнює',
    'headerFilterOperator!=': 'Не дорівнює',
    'headerFilterOperator>': 'Більше ніж',
    'headerFilterOperator>=': 'Більше ніж або дорівнює',
    'headerFilterOperator<': 'Менше ніж',
    'headerFilterOperator<=': 'Менше ніж або дорівнює',
  
    // Filter values text
    filterValueAny: 'будь-яке',
    filterValueTrue: 'так',
    filterValueFalse: 'ні',
  
    // Column menu text
    columnMenuLabel: 'Меню',
    columnMenuShowColumns: 'Показати стовпці',
    columnMenuManageColumns: 'Керувати стовпцями',
    columnMenuFilter: 'Фільтр',
    columnMenuHideColumn: 'Сховати стовпець',
    columnMenuUnsort: 'Скасувати сортування',
    columnMenuSortAsc: 'Сортувати за зростанням',
    columnMenuSortDesc: 'Сортувати за спаданням',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} активних фільтрів` : `${count} активний фільтр`,
    columnHeaderFiltersLabel: 'Показати фільтри',
    columnHeaderSortIconLabel: 'Сортувати',
  
    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} рядків вибрано`
        : `${count.toLocaleString()} рядок вибрано`,
  
    // Total row amount footer text
    footerTotalRows: 'Всього рядків:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} з ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Вибір за допомогою прапорця',
    checkboxSelectionSelectAllRows: 'Вибрати всі рядки',
    checkboxSelectionUnselectAllRows: 'Скасувати вибір усіх рядків',
    checkboxSelectionSelectRow: 'Вибрати рядок',
    checkboxSelectionUnselectRow: 'Скасувати вибір рядка',
  
    // Boolean cell text
    booleanCellTrueLabel: 'так',
    booleanCellFalseLabel: 'ні',
  
    // Actions cell more text
    actionsCellMore: 'більше',
  
    // Column pinning text
    pinToLeft: 'Прикріпити зліва',
    pinToRight: 'Прикріпити справа',
    unpin: 'Відкріпити',
  
    // Tree Data
    treeDataGroupingHeaderName: 'Група',
    treeDataExpand: 'показати дітей',
    treeDataCollapse: 'сховати дітей',
  
    // Grouping columns
    groupingColumnHeaderName: 'Групування',
    groupColumn: (name) => `Групувати за ${name}`,
    unGroupColumn: (name) => `Припинити групування за ${name}`,
  
    // Master/detail
    detailPanelToggle: 'Переключення панелі деталей',
    expandDetailPanel: 'Розгорнути',
    collapseDetailPanel: 'Згорнути',
  
    // Used core components translation keys
    MuiTablePagination: {},
  
    // Row reordering text
    rowReorderingHeaderName: 'Переміщення рядків',
  
    // Aggregation
    aggregationMenuItemHeader: 'Агрегація',
  aggregationFunctionLabelSum: 'сума',
  aggregationFunctionLabelAvg: 'середнє',
  aggregationFunctionLabelMin: 'мінімум',
  aggregationFunctionLabelMax: 'максимум',
  aggregationFunctionLabelSize: 'розмір',
};

  