import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const GeographyChart = () => {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        type: 'bar',
        borderRadius: '10px',
        background: 'transparent', // робимо фон графіка прозорим
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 1,
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          style: {
            colors: 'white',
          },
        },
      },
      yaxis: {
        max: 10,
        tickAmount: 10, // Встановлення кількості тиків для кроку в 1
        labels: {
          style: {
            colors: '#fff',
          },
        },
      },
      theme: {
        mode: 'dark',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '70%',
          dataLabels: {
            position: 'top',
            style: {
              colors: ['#333'],
            },
          },
        },
      },
      colors: ['#519772'],
    },
    series: [
      {
        name: 'Кількість авто',
        data: [],
        
      },
    ],
  });

  const handleReset = () => {
    const today = new Date().toISOString().slice(0, 10);
    setSelectedDate(today);
    setEndDate(today);
  };
  
  

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [startTime, setStartTime] = useState(8); // Припустимо, робочий день починається о 9:00
  const [endTime, setEndTime] = useState(20); 

  const fetchChartData = async () => {
    try {
      const apiKey = localStorage.getItem('api');
      const headers = {
        'X-Api-Key': apiKey,
      };
  
      let url;
      let isSingleDay = selectedDate === endDate;
  
      if (isSingleDay) {
        url = `https://api.assist.cam/get_date?date=${selectedDate}`;
      } else {
        url = `https://api.assist.cam/get_date_range?start_date=${selectedDate}&end_date=${endDate}`;
      }
  
      const response = await fetch(url, { method: 'GET', headers });
      const data = await response.json();
  
      if (isSingleDay) {
        // Визначити категорії осі X на основі робочих годин
        const categories = Array.from({ length: endTime - startTime + 1 }, (_, i) => `${startTime + i}:00`);
  
        setChartData((prevChartData) => ({
          ...prevChartData,
          options: {
            ...prevChartData.options,
            xaxis: { ...prevChartData.options.xaxis, categories: categories },
            // yaxis: { ...prevChartData.options.yaxis, max: Math.max(...data) + 1 }, // Автоматичне визначення максимального значення осі Y
          },
          series: [{ name: 'Кількість авто', data }],
        }));
      } else {
        let start = new Date(selectedDate);
        let end = new Date(endDate);
        let days = [];
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
          days.push(d.toISOString().slice(0, 10));
        }
  
        setChartData((prevChartData) => ({
          ...prevChartData,
          options: {
            ...prevChartData.options,
            xaxis: { ...prevChartData.options.xaxis, categories: days },
          },
          series: [{ name: 'Кількість авто', data }],
        }));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  useEffect(() => {
    fetchChartData();
  }, [selectedDate, endDate]);;

  const handleStartDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const newOptions = {
    ...chartData.options,
    chart: {
      ...chartData.options.chart,
      borderRadius: '10px',
      borderWidth: 1, // Товщина бордеру
      borderColor: '#ccc', // Колір бордеру
    },
  };
  return (
    <div style={{ 
      width: '100%', 
      height: '100%', 
      display: 'flex',  
      flexDirection: 'column', 
      alignItems: 'center',
      borderRadius: '10px'
    }}> 
    
       
      <div style={{ 
        width: '100%', 
        height: '70%', 
        borderRadius: '10px',
      }}> 
        <h3 style={{ textAlign: 'center' }}>Машинозаїзди</h3>
        <Chart
          options={newOptions}
          series={chartData.series}
          type="bar"
          height='100%' // Використовуйте 100% висоти батьківського елемента
          width="100%"// Додайте стиль з бордером і бордер радіусом
        />
  
  
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          width: '100%', 
          marginBottom: '10px',
          marginTop: '20px'
        }}>
          <div style={{ marginRight: '10px' }}>
            <button onClick={handleReset} style={{ marginRight: '10px' }}>🔄</button>
            <label>З: </label>
            <input type="date" value={selectedDate} onChange={handleStartDateChange} />
          </div>
          <div>
            <label>По: </label>
            <input type="date" value={endDate} onChange={handleEndDateChange} />
          </div>
        </div>
      </div>    
    </div>
  );
  
  
}
  export default GeographyChart;
  
