import React, { useState, useEffect } from 'react';


import {Dialog, DialogTitle, DialogContent,DialogActions, Box, Button, Divider, IconButton, Typography, useTheme, Modal, TextField, Grid } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import MainChart from "../../components/MainChart";
import GeographyChartWithButtons from "../../components/NumberDisplay"
import CustomTable from '../../components/DataInfo';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Team from '../team/index'
import Calendar from '../../components/Calend';
import LockIcon from "@mui/icons-material/Lock";
import TariffCalculator from '../../components/PayClient';


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [currentCamera, setCurrentCamera] = useState(0);
  const [loading, setLoading] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now()); // Додавання часової мітки
  const [hasCameras, setHasCameras] = useState(false);
  const [camId, setCamId] = useState(0)
  const cameraUrls = ["https://auto.assist.cam:5022/video_feed/0",
   "https://auto.assist.cam:5022/video_feed/1"];

   const [cameraStream, setCameraStream] = useState();
   const [camerasData, setCamerasData] = useState([]);

   const [cameraName, setCameraName] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [serverResponse, setServerResponse] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSet, setModalOpenSet] = useState(false);
  const companyName = localStorage.getItem('companyName');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  const [isPlanActive, setIsPlanActive] = useState(false);
  const [PlanA, setPlan] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [pay, setPay] = useState(false);



  const handleDateSelect = (date) => {
    setSelectedDate(date);
    // You can perform any actions with the selected date here
    // For example, you can update the state or perform an API call.
  };




  useEffect(() => {
    const checkPlanStatus = async () => {
      const apiKey = localStorage.getItem('api');

  
      try {
        const response = await fetch(`https://api.assist.cam/plan_status`, {
          method: 'GET', // Якщо метод GET, то це можна не вказувати, бо він за замовчуванням
          headers: {
            'X-Api-Key': apiKey, // Встановлення API ключа в заголовки запиту
            'Content-Type': 'application/json' // Додатковий заголовок, якщо ваш сервер очікує JSON
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setIsPlanActive(data.is_active);
          setPlan(data) 
          console.log(data)// Встановлюємо стан відповідно до отриманого статусу
        } else {
          throw new Error('Failed to fetch plan status');
        }
      } catch (error) {
        console.error('Error:', error);
        setIsPlanActive(false); // В разі помилки запиту вважаємо, що план неактивний
      }
    };
  
    checkPlanStatus();
  }, []);


  
  const handlePayment = async () => {
    const apiKey = localStorage.getItem('api');

    try {
      
      const body = {
        tariffName: PlanA.tariff_name,
        totalPrice: PlanA.amount_due,
      };
      console.log(body)
  
      const response = await fetch('https://api.assist.cam/create_invoice', {
        method: 'POST',
        headers: {
          'X-Api-Key': apiKey,
          
        },
        body: JSON.stringify(body),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Платіж ініційовано', data);
        setPaymentUrl(data.url)
        // Після успішної оплати встановити isPlanActive в true
        // setIsPlanActive(true);
        setConfirmDialogOpen(true);  // Закрити діалог підтвердження
      } else {
        throw new Error('Не вдалося ініціювати платіж');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleConfirmPaymens = () => {
    window.open(paymentUrl, '_blank');
    setConfirmDialogOpen(false); // Закриття діалогу підтвердження
};

const payment = () => {
  setPay(true)
};

  

   // В цьому прикладі ви виконуєте запит до сервера для отримання даних про камери
const fetchCamerasData = async () => {
  try {
    const apiKey = localStorage.getItem('api');
    const headers = {
      'X-Api-Key': apiKey,
    };

    const response = await fetch('https://api.assist.cam/get_cam/', {
      method: 'GET',
      headers: headers,
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Повертаємо дані про камери
    } else {
      throw new Error('Помилка отримання даних про камери');
    }
  } catch (error) {
    console.error('Помилка:', error);
    return null;
  }
};

useEffect(() => {
  const fetchCameras = async () => {
    const data = await fetchCamerasData();
    if (data && data.length > 0) {
      setCamerasData(data); // Оновлюємо стан камер з отриманими даними
      // setCameraStream(data[0].url); // Встановлюємо потік першої камери за замовчуванням
    }
  };

  fetchCameras();

  // Створюємо інтервал для періодичного оновлення даних про камери кожні 10 секунд
  const intervalId = setInterval(() => {
    fetchCameras(); // Повторне викликання функції fetchCameras для оновлення даних
  }, 1000);

  // Прибираємо інтервал при знищенні компонента
  return () => clearInterval(intervalId);
}, []);  // Порожній масив означає, що цей ефект запускається лише раз під час першого рендерингу


    




const handleCameraClick = async (cameraId) => {
  const selectedCamera = camerasData.find(camera => camera.id === cameraId);
  if (selectedCamera) {
    
    setCameraStream(selectedCamera.url); // Відразу встановлюємо базовий URL

    setTimeout(() => { // Оновлюємо URL з параметром часу після короткої затримки
      const newCameraStreamUrl = `${selectedCamera.url}?t=${Date.now()}`;
      setCameraStream(newCameraStreamUrl);
    }); // Невелика затримка для плавного переходу
  } else {
    console.error('Камера з даним ID не знайдена.');
  }
};





  

  
  return (
    
       <Box m="20px">
      

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, minmax(auto, 1fr))"
        gridAutoRows="minmax(10vh, 10vh)"
        gap="10px"
      >
        {/* ROW 1 */}
        <Box
  gridColumn="span 6"
  gridRow="span 6"
  sx={{
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #617c85',
    borderRadius: "10px",
    overflow: 'hidden',
  }}
>
  {/* Main Camera Stream */}
  {/* Main Camera Stream */}
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%', // Adjust height based on the thumbnail row
    }}
  >
    {loading ? (
      <Typography>Завантаження...</Typography>
    ) : camerasData && camerasData.length > 0 ? (
      <img
        src={cameraStream}
        alt={`Оберіть камеру.`}
        style={{ width: '100%', height: '100%', borderRadius: '10px' }}
      />
    ) : (
      <Typography>Немає доступних камер.</Typography>
    )}
  </Box>

</Box>


<Box
  gridColumn="span 6"
  gridRow="span 6"
  backgroundColor="rgba(255, 255, 255, 0.1)" // Застосування прозорості
  borderRadius="5px"
  sx={{
    width: '100%',
    height: '100%',
    border: '2px solid #617c85',
    overflow: 'auto', // Дозволяємо скрол всередині Box, якщо вміст перевищує його розміри
  }}
>
  {/* Thumbnails of Cameras moved here */}
  <Box 
    sx={{ 
      display: 'flex', 
      flexWrap: 'wrap', 
      gap: '10px',
      padding: '10px',
      borderBottom: '2px solid #617c85',
    }}
  >
    {camerasData && camerasData.map((camera) => (
      <Box
        key={camera.id}
        onClick={() => handleCameraClick(camera.id)}

        sx={{
          flexBasis: 'calc(20% - 10px)',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            backgroundSize: 'cover',
            borderRadius: '5px',
            border: '2px solid #fff',
            height: '100px',
            width: '100%',
            '&:hover': {
              border: '2px solid #617c85',
            },
          }}
        >
          <img
            src={camera.url}
            alt={`Камера ${camera.name}`}
            style={{ width: '100%', height: '100%', borderRadius: '10px' }}
          />
          <Typography variant="caption" sx={{ color: 'white', textAlign: 'center', position: 'absolute', top: '5px', left: '5px', zIndex: 2 }}>
            {camera.name}
          </Typography>
        </Box>
      </Box>
    ))}
  </Box>




      <CustomTable />
    </Box>




        
        

        <Box
            gridColumn="span 4"
            gridRow="span 5"
            backgroundColor= "rgba(0, 0, 0, 0)"
            borderRadius="5px"
            sx={{
              
              width: '100%',
              height: '100%',
              border: '2px solid #617c85',
              
            }}
          >
              <Calendar onDateSelect={handleDateSelect} />

      </Box>

      <Box
        gridColumn="span 5"
        gridRow="span 5"
        backgroundColor= "rgba(0, 0, 0, 0)"
        borderRadius="5px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          border: '2px solid #617c85',
          overflow: 'auto', // Дозволяємо скрол всередині Box, якщо вміст перевищує його розміри

        }}>
          <GeographyChartWithButtons selectedDate={selectedDate} />
      </Box>
    

      <Box
            gridColumn="span 3"
            gridRow="span 5"
            backgroundColor= "rgba(0, 0, 0, 0)"
            borderRadius="5px"
            sx={{
              
              width: '100%',
              height: '100%',
              border: '2px solid #617c85',
              
            }}
          >
            <GeographyChart />
      </Box>

      <Modal
        open={pay}
        onClose={() => setPay(false)}
        aria-labelledby="tariff-calculator-title"
        aria-describedby="tariff-calculator-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            
            boxShadow: 24,
            p: 4,
          }}
        >
          <TariffCalculator count={PlanA.cam} />
        </Box>
      </Modal>

      </Box>
     
   
    </Box>

    


      

    
  );
};

export default Dashboard;
