import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, CircularProgress } from '@mui/material';

const fetchDiagnosticInfo = async (recordId) => {
    try {
      const response = await fetch(`http://10.20.40.2:7050/api/diagnostics/${recordId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      throw error;
    }
  };
  

const DiagnosticModal = ({ isOpen, onClose, recordId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [diagnosticInfo, setDiagnosticInfo] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      fetchDiagnosticInfo(recordId)
        .then(data => {
          setDiagnosticInfo(data);
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Failed to fetch diagnostic info", error);
          setIsLoading(false);
        });
    }
  }, [isOpen, recordId]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-diagnostic-title"
      aria-describedby="modal-diagnostic-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography id="modal-diagnostic-title" variant="h6" component="h2">
          Інформація про діагностику
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : diagnosticInfo ? (
          <>
            <Typography id="modal-diagnostic-description" sx={{ mt: 2 }}>
              {diagnosticInfo.description}
            </Typography>
            {/* Тут можна додати більше полів з інформацією про діагностику */}
          </>
        ) : (
          <Typography>Інформація не знайдена.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default DiagnosticModal;
