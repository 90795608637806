import React from 'react';
import Clock from 'react-simple-clock';

const MyClock = () => {
  const clockStyles = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
    color: 'white',
    backgroundColor: 'white'
    
    /* Додайте інші стилі за вашим вибором */
  };

  return <Clock live={true} hourMarkFormat="number" style={clockStyles} mode="dark"  />;
};

export default MyClock;
