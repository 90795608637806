import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const CarCatalog = () => {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [modifications, setModifications] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModification, setSelectedModification] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [parts, setParts] = useState([]);
  const [oemNumbers, setOemNumbers] = useState([]);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = () => {
    // Отримання брендів з вашого Flask API
    fetch('http://localhost:5070/get_brands')
      .then(response => response.json())
      .then(data => setBrands(data.brands))
      .catch(error => console.error('Помилка:', error));
  };

  const fetchModels = (brandId) => {
    // Отримання моделей з вашого Flask API за певним брендом
    fetch(`http://localhost:5070/get_models/${brandId}`)
      .then(response => response.json())
      .then(data => setModels(data.models))
      .catch(error => console.error('Помилка:', error));
  };

  const fetchModifications = (modelId) => {
    // Отримання модифікацій з вашого Flask API за певною моделлю
    fetch(`http://localhost:5070/get_modifications/${modelId}`)
      .then(response => response.json())
      .then(data => setModifications(data.modifications))
      .catch(error => console.error('Помилка:', error));
  };

  const fetchSections = (modificationId) => {
    // Отримання секцій з вашого Flask API за певною модифікацією
    fetch(`http://localhost:5070/get_sections/${modificationId}`)
      .then(response => response.json())
      .then(data => setSections(data.sections))
      .catch(error => console.error('Помилка:', error));
  };

  const fetchParts = (modificationId, sectionId) => {
    fetch(`http://localhost:5070/display_parts/${modificationId}/${sectionId}`)
      .then(response => response.json())
      .then(data => setParts(data))
      .catch(error => console.error('Помилка:', error));
  };

  const fetchOemNumbers = (number, brandId) => {
    fetch(`http://localhost:5070/get_oem_numbers/${number}/${brandId}`)
      .then(response => response.json())
      .then(data => setOemNumbers(data))
      .catch(error => console.error('Помилка:', error));
  };

  // Обробники вибору бренда, моделі, модифікації та секції
  const handleBrandSelect = (brandId) => {
    setSelectedBrand(brandId);
    setSelectedModel(null);
    setSelectedModification(null);
    setSelectedSection(null);
    setModels([]); // Очистити список моделей
    setModifications([]); // Очистити список модифікацій
    setSections([]); // Очистити список секцій
    fetchModels(brandId);
  };

  const handleModelSelect = (modelId) => {
    setSelectedModel(modelId);
    setSelectedModification(null);
    setSelectedSection(null);
    setModifications([]); // Очистити список модифікацій
    setSections([]); // Очистити список секцій
    fetchModifications(modelId);
  };

  const handleModificationSelect = (modificationId) => {
    setSelectedModification(modificationId);
    setSelectedSection(null);
    setSections([]); // Очистити список секцій
    fetchSections(modificationId);
  };

  const handleSectionSelect = (sectionId) => {
    setSelectedSection(sectionId);
  };

  // Формування вузлів дерева
  const renderTree = (nodes) => {
    return (
      <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
        {Array.isArray(nodes.children)
          ? nodes.children.map(node => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <Box sx={{ minHeight: 220, flexGrow: 1, maxWidth: 300 }}>
      <TreeView
        aria-label="car-catalog"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {brands.map(brand => (
          <TreeItem
            key={brand.id}
            nodeId={brand.id}
            label={brand.name}
            onClick={() => handleBrandSelect(brand.id)}
          >
            {models.map(model => (
              <TreeItem
                key={model.id}
                nodeId={model.id}
                label={model.name}
                onClick={() => handleModelSelect(model.id)}
              >
                {modifications.map(modification => (
                  <TreeItem
                    key={modification.id}
                    nodeId={modification.id}
                    label={modification.name}
                    onClick={() => handleModificationSelect(modification.id)}
                  >
                    {sections.map(section => (
                      <TreeItem
                        key={section.id}
                        nodeId={section.id}
                        label={section.description}
                        onClick={() => handleSectionSelect(section.id)}
                      />
                    ))}
                  </TreeItem>
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>
    </Box>
  );
};

export default CarCatalog;
