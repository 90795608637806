import React from 'react';
import Calls from './Calls'; // Assuming 'Calls' component is in a separate file
import Records from './Records';
import Repairts from './Repair';

import { Grid } from '@mui/material';
const ThreeCall = () => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={4.5}>
          {/* Перша таблиця (40%) */}
          <Calls />
        </Grid>
        <Grid item xs={4.5}>
          {/* Друга таблиця (40%) */}
          <Records />
        </Grid>
        <Grid item xs={3}>
          {/* Третя таблиця (20%) */}
          <Repairts />
        </Grid>
      </Grid>
    );
  };

  export default ThreeCall;
