import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, AppBar, Modal, Checkbox,
   FormControlLabel, Link, IconButton  } from '@mui/material';
import axios from 'axios';
import MyClock from '../../components/Clock'
import CloseIcon from '@mui/icons-material/Close';
import backgroundImage from './img/bg.jpg';
import './css/loginPage.css'

function LoginForm({ onLoginSuccess }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company_name, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);

 // Новий стан для відстеження згоди користувача

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    try {
      const url = new URL('https://api.assist.cam/authenticate/');
      url.searchParams.append('login', username);
      url.searchParams.append('password', password);

      // Відправка POST запиту на ваш API для реєстрації
      const response = await axios.post(url.toString());
      console.log(response.data)
      const responseData = response.data[0];

    if (responseData && responseData.status === 200) {
        localStorage.setItem('api', response.data[0].api_key);
        localStorage.setItem('session_id', response.data[0].session_id) 
        localStorage.setItem('isAuthenticated', true);// Зберігаємо токен
        onLoginSuccess(); // Викликати пропс-функцію, щоб сигналізувати про успішний вхід
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      setError('Login failed. Please try again.');
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');
  
    try {
      const url = new URL('https://api.assist.cam/register/');
      url.searchParams.append('name', name);
      url.searchParams.append('email', email);
      url.searchParams.append('company_name', company_name);
      url.searchParams.append('phone', phone);
  
      // Відправка POST запиту на ваш API для реєстрації
      const response = await axios.post(url.toString());
      console.log(response.data)
      const responseData = response.data[0];

    if (responseData && responseData.status === 200) {
        // Успішна реєстрація. Тут можна виконати дії після реєстрації, наприклад зберегти токен
        localStorage.setItem('api', response.data.api_key);
        // onLoginSuccess(); // Викликати функцію успішного входу або реєстрації
        alert('Реєстрація успішна! Дані для входу відправлені вам на пошту');
        window.location.reload();
         // Показати повідомлення користувачу
      } else {
        // Якщо сервер повернув помилку
        setError(response.data.message || 'Помилка реєстрації. Спробуйте будь ласка пізніше або зверніться в службу підтрики!');
      }
    } catch (error) {
      // Якщо виникла помилка при відправці запиту
      setError('Помилка реєстрації. Спробуйте будь ласка пізніше або зверніться в службу підтрики!');
    }
  };
  


  
  const [status_ag, setStatus_ag] = useState(0);
  // Обробник для зміни стану чекбоксу
  const handleAgreeChange = (event) => {
    setIsAgreed(event.target.checked);
    // Тут можна відправити дані на бекенд, якщо потрібно
  };

  const [modalsOpen, setModalsOpen] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');

const openModalWithDocument = (url) => {
  setDocumentUrl(url);
  setModalsOpen(true);
};

const closeModals = () => {
  setModalsOpen(false);
  setDocumentUrl('');
};



  const handleClose = () => {
    setIsModalOpen(false);
  };

  
  // setInterval(async () => {
  //   const apiKey = localStorage.getItem('api');
  //   const sessionId = localStorage.getItem('session_id');
  //   console.log(apiKey)
  //   try {
  //     const url = `https://api.assist.cam/check-session/${sessionId}`;
  //     const config = {
  //       headers: {
  //         'X-Api-Key': apiKey
  //       },
  //        // Це можна залишити, якщо вам це потрібно для іншої мети
  //     };
  
  //     const response = await axios.get(url, config);
  
  //     if (response.data.valid === false) {
  //       localStorage.removeItem('api');
        
  //       localStorage.setItem('isAuthenticated', false);
  //     }
  //   } catch (error) {
  //     console.error('Error checking session:', error);
  //   }
  // }, 10000000);
  

  return (
    <Box >
      
      {/* <Box
        component="img"
        src={'../../assets/fon.png'} // Шлях до вашої картинки
        sx={{ 
          width: '100vw', // Ширина на всю ширину вьюпорту
          height: '100vh', // Висота на всю висоту вьюпорту
          position: 'absolute', // Абсолютне позиціонування
          top: 0,
          left: 0,
          backgroundSize: 'cover'
          
        }}
      /> */}

      <div className="container">
      <img src={backgroundImage} alt="Фон" style={{ width: '100%', height: 'auto' }} />
    </div>

      
      <Box sx={{ position: 'absolute', top: 20, left: 20 }}>
        <MyClock />
      </Box>
       <Button
        sx={{ 
          position: 'absolute', 
          width: '40%',
          height: '5%',
          borderRadius: '20px',
          border: '4px solid #40E0D0',
          bottom: 70, // Відступ знизу
          left: '50%', // Центрування по горизонталі
          transform: 'translateX(-50%)', // Точне центрування кнопки
          backgroundColor: '#7FFFD4',
          overflow: 'hidden',
          color: 'inherit',
          '&:hover': {
            backgroundColor: '#40E0D0', // Колір кнопки при наведенні
          }
        }}
        onClick={() => setIsModalOpen(true)}
      >
        Вхід/Реєстрація
      </Button>

      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none'
        }}>
     
          <Box
            component="img"
            src={'../../assets/logo192.png'} // Переконайтеся, що шлях до логотипу вірний
            sx={{ width: 100, height: 100, mb: 2 }}
          />
          
          <Box component="form" onSubmit={isRegistering ? handleRegister : handleLogin} sx={{ mt: 1, width: '100%' }}>
            {isRegistering ? (
              <>
                <Typography variant="h5" component="h1" gutterBottom
                  sx={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                  Реєстрація
                </Typography>
                <TextField margin="normal" required fullWidth label="Ім'я" value={name} onChange={(e) => setName(e.target.value)} />
                <TextField margin="normal" type='email' required fullWidth label="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                <TextField margin="normal" required fullWidth label="Назва компанії" value={company_name} onChange={(e) => setCompany(e.target.value)} />
                <TextField margin="normal" type='phone' required fullWidth label="Номер телефону" value={phone} onChange={(e) => setPhone(e.target.value)} />
                <Link href="#" sx={{color: "blue"}} onClick={() => openModalWithDocument('../../assets/Політика конфіденційності.pdf')} underline="hover">
                Політика конфіденційності
              </Link>
              {' | '}
              <Link href="#" sx={{color: "blue"}}  onClick={() => openModalWithDocument('../../assets/Оферта.pdf')} underline="hover">
                Публічна оферта
              </Link>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAgreed}
                    onChange={handleAgreeChange}
                    name="agree"
                  />
                }
                label="Я погоджуюсь з правилами"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isAgreed} // Кнопка активна тільки якщо користувач погодився
                sx={{ mt: 3, mb: 2, backgroundColor: '#1F2A40' }}
              >
                Зареєструватися
              </Button>
              </>
            ) : (
              <>
                {/* Поля для входу */}
                <Typography variant="h5" component="h1" gutterBottom>
                  Вхід у систему
                </Typography>
                <TextField margin="normal" required fullWidth label="Ім'я користувача" value={username} onChange={(e) => setUsername(e.target.value)} />
                <TextField margin="normal" required fullWidth label="Пароль" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: '#1F2A40' }}>
                  Увійти
                </Button>
              </>
            )}
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            
            
            <Button
              fullWidth
              variant="text"
              sx={{ mt: 1, mb: 2 }}
              onClick={() => setIsRegistering(!isRegistering)}
            >
              {isRegistering ? 'Уже маєте аккаунт? Увійдіть' : 'Створити аккаунт'}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={modalsOpen}
        onClose={closeModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', height: '80%', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <IconButton onClick={closeModals} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
          <iframe src={documentUrl} width="100%" height="100%" style={{ border: 'none' }}></iframe>
        </Box>
      </Modal>
    </Box>
  );
}

export default LoginForm;