import React, { useState } from 'react';
import { Box, Typography, Divider, ToggleButton, ToggleButtonGroup, LinearProgress} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DiagnosticIcon from '@mui/icons-material/Settings';
import TodayIcon from '@mui/icons-material/Today';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BuildIcon from '@mui/icons-material/Build';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const Indicator = ({ dayData, monthData }) => {
  
  const [viewMode, setViewMode] = useState('day'); // 'day' або 'month'

  const handleChangeViewMode = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

 



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>Статистика</Typography>
      <Divider sx={{ width: '100%' }} />
      <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleChangeViewMode}
            sx={{ 
                marginBottom: 2,
                '& .MuiToggleButton-root': { // Застосовує стилі до кожної ToggleButton в групі
                color: 'white', // Колір тексту
                borderColor: 'white', // Колір рамки
                '&.Mui-selected': { // Стилі для вибраної кнопки
                    backgroundColor: 'gray', // Змініть на потрібний колір фону
                    color: 'black', // Змініть на потрібний колір тексту
                }
                }
            }}
            >
  <ToggleButton value="day" aria-label="day">
    <TodayIcon /> День
  </ToggleButton>
  <ToggleButton value="month" aria-label="month">
    <CalendarMonthIcon /> Місяць
  </ToggleButton>
</ToggleButtonGroup>

      

<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, width: '100%' }}>
    {/* Приклади блоків з лінійним прогресом */}
    <Box sx={{ display: 'flex', alignItems: 'center',  width: '100%' }}>
        <DriveEtaIcon fontSize="20px" />
        <Box sx={{ width: '100%', ml: 1 }}>
        <Typography variant="h8">
            Всього: {viewMode === 'day' ? dayData.entries + dayData.completed + dayData.diagnostics +  dayData.repairs : monthData.entries + monthData.complete + monthData.diagnostics + monthData.repairs}
        </Typography>
        </Box>
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center',  width: '100%' }}>
        <CheckCircleIcon fontSize="20px" />
        <Box sx={{ width: '100%', ml: 1 }}>
        <Typography variant="h8">
            Готово: {viewMode === 'day' ? dayData.completed : monthData.complete}
        </Typography>
        </Box>
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center',  width: '100%' }}>
        <BuildIcon fontSize="20px" />
        <Box sx={{ width: '100%', ml: 1 }}>
        <Typography variant="h8">
            У ремонті: {viewMode === 'day' ? dayData.repairs : monthData.repairs}
        </Typography>
        </Box>
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center',  width: '100%' }}>
        <BuildIcon fontSize="20px" />
        <Box sx={{ width: '100%', ml: 1 }}>
        <Typography variant="h8">
        На діагностиці: {viewMode === 'day' ? dayData.diagnostics : monthData.diagnostics}
        </Typography>
        </Box>
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center',  width: '100%' }}>
        <PriorityHighIcon fontSize="20px" />
        <Box sx={{ width: '100%', ml: 1 }}>
        <Typography variant="h8">
            Нерозібрані: {viewMode === 'day' ? dayData.entries : monthData.entries}
        </Typography>
        </Box>
    </Box>


        
  

    {/* <Box sx={{ width: '100%' }}>
        <Typography variant="h8">
            Заїзди: {monthData.repair_stats.total_diagnostic}, Діагностики: {monthData.repair_stats.total_diagnostic}, Ремонти: {monthData.repair_stats.repair_percentage}.
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 80 }}>Заїзди</Typography>
            <LinearProgress variant="determinate" value={100} sx={{ flex: 1, height: 10, borderRadius: 5, backgroundColor: '#f0f0f0' }} />
            <Typography variant="body2">{monthData.repair_stats.total_diagnostic}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 80 }}>Діагностики</Typography>
            <LinearProgress variant="determinate" value={100} sx={{ flex: 1, height: 10, borderRadius: 5, backgroundColor: 'yellow' }} />
            <Typography variant="body2">{monthData.repair_stats.total_diagnostic}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
            <Typography variant="body2" sx={{ minWidth: 80 }}>Ремонти</Typography>
            <LinearProgress
            variant="determinate"
            value={(monthData.repair_stats.repair_percentage / monthData.repair_stats.total_diagnostic) * 100}
            sx={{
                flex: 1,
                height: 10,
                borderRadius: 5,
                backgroundColor: '#f0f0f0',
                '& .MuiLinearProgress-bar': { backgroundColor: 'green' },
            }}
            />
            <Typography variant="body2">{monthData.repair_stats.repair_percentage}</Typography>
        </Box>
        </Box> */}

            
            
      </Box>

     
    </Box>
  );
};

export default Indicator;
