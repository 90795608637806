import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, IconButton } from '@mui/material';
import moment from 'moment';
import 'moment/locale/uk';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Indicator from './Indicator';


const Calendar = ({ onDateSelect }) => {
  moment.locale('uk');
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [carData, setCarData] = useState({}); 
  const [selectedDay, setSelectedDay] = useState(moment().format('YYYY-MM-DD'));
  const [carColors, setCarColors] = useState({});
  const [selectedCar, setSelectedCar] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [carRepairs, setCarRepairs] = useState({});
  const [selectedStart, setSelectedStart] = useState('09:00');
  const [selectedEnd, setSelectedEnd] = useState('10:00');
  const [selectedColor, setSelectedColor] = useState(''); // Default color selection
  const [defaultCarColor, setDefaultCarColor] = useState('grey'); // Default car color
  const [openModal, setOpenModal] = useState(false);
  const formattedSelectedDay = moment(selectedDay).format('YYYY-MM-DD');
  const formattedDate = moment(selectedDay).format('DD.MM.YY');
  const [dayData, setDayData] = useState({ entries: 0, diagnostics: 0, repairs: 0, completed: 0 });
  const [monthData, setMonthData] = useState({ entries: 0, diagnostics: 0, repairs: 0, complete: 0, repair_stats: {}});
  const formattedM = moment(currentMonth).format('YYYY-MM');


  useEffect(() => {
    const fetchData = async () => {
      const today = new Date().toISOString().slice(0, 10);
      const apiKey = localStorage.getItem('api');
  
      try {
        // Запит за день
        const dayStatsResponse = await fetch(`https://api.assist.cam/get_day_service?date=${selectedDay}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
          },
        });
        const dayStats = await dayStatsResponse.json();


        console.log("Datat",dayStats)
        // Оновлення стану з новими даними
        setDayData(dayStats);
  
        // Запит за місяць
        const monthStatsResponse = await fetch(`https://api.assist.cam/get_month_service?month=${formattedM}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
          },
        });

        if (!dayStatsResponse.ok || !monthStatsResponse.ok) {
          throw new Error('Network response was not ok');
        }
  
        // Отримання JSON відповіді
        const monthStats = await monthStatsResponse.json();
        
        setMonthData(monthStats);

        const proc = await fetch(`https://api.assist/cam/get_zag?date=${selectedDay}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': apiKey,
          },
        });
  
        

      } catch (error) {
        console.error('There was a problem with fetching the stats:', error);
      }
    };
  
    fetchData();
  }, [selectedDay, formattedM]);
  
  

  const handleDayClick = (day) => {
    const selectedDate = day.format('YYYY-MM-DD');
    setSelectedDay(selectedDate); // Set the selected date in your component's state
    onDateSelect(selectedDate); // Pass the selected date to the callback function
    console.log(selectedDate); // You can also log the selected date if needed
  };


  const apiKey = localStorage.getItem('api');
  // Помістіть ваш API ключ тут
  const BASE_URL = 'https://api.assist.cam'; // Замініть на URL вашого API
  
  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/carDats`, {
          headers: {
            'X-Api-Key': apiKey,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        
        // Трансформація даних у структуру carData
        const carDataStructure = data.reduce((acc, { date, post_id, car }) => {
          if (!acc[date]) acc[date] = {};
          if (!acc[date][post_id]) acc[date][post_id] = [];
          acc[date][post_id].push(car);
          return acc;
        }, {});
        
        console.log("Дані постів", {carDataStructure});
        setCarData(carDataStructure); // Оновлення стану carData
        // ...
      } catch (error) {
        console.error("Помилка при завантаженні даних про автомобілі: ", error);
      }
    };
  
  
    const fetchData = () => {
      fetchCarData();
      generateCalendarDays();
    };
    
    // Викликаємо fetchData відразу при монтажі компонента
    fetchData();
  
    // Налаштовуємо інтервал для автооновлення даних
    const intervalId = setInterval(fetchData, 10000); // 10000 мс = 10 секунд
  
    // Очищуємо інтервал при демонтажі компонента
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedDay, currentMonth]); 
  
  
  
  
  // Припускаємо, що carData отримується з API або іншого джерела

  useEffect(() => {
    generateCalendarDays();
    // Тут можна додати запит на отримання carData, якщо потрібно
  }, [currentMonth]);

  const generateCalendarDays = () => {
    const days = [];
    const startDay = currentMonth.clone().startOf('month').startOf('week');
    const endDay = currentMonth.clone().endOf('month').endOf('week');

    for (let m = moment(startDay); m.isBefore(endDay); m.add(1, 'days')) {
      days.push(m.clone());
    }

    setDaysInMonth(days);
  };

  const goToPreviousMonth = () => {
    const newMonth = currentMonth.clone().subtract(1, 'month');
    setCurrentMonth(newMonth);
  };
  
  const goToNextMonth = () => {
    const newMonth = currentMonth.clone().add(1, 'month');
    setCurrentMonth(newMonth);
  };
  

  function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }
  
  function rgbToHex(r, g, b) {
    return "#" + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }).join('');
  }
  
  function interpolateColor(color1, color2, factor) {
    const [r1, g1, b1] = hexToRgb(color1);
    const [r2, g2, b2] = hexToRgb(color2);
    
    const r = Math.round(r1 + (r2 - r1) * factor);
    const g = Math.round(g1 + (g2 - g1) * factor);
    const b = Math.round(b1 + (b2 - b1) * factor);
  
    return rgbToHex(r, g, b);
  }
  
  const getColorForDay = (day) => {
    const dayData = carData[day.format('YYYY-MM-DD')];
    let carCount = 0;
  
    if (dayData) {
      Object.values(dayData).forEach(arr => {
        carCount += arr.length;
      });
    }
  
    if (carCount === 0) {
      return '#617c85'; // Сірий колір
    }
  
    const startColor = '#FFBA08';
    const endColor = '#DC2F02';
    const maxCarCount = 10;
    const factor = Math.min(carCount, maxCarCount) / maxCarCount;
  
    return interpolateColor(startColor, endColor, factor);
  };
  


  const renderCalendar = () => {
    return daysInMonth.map((day) => {
      const dayKey = day.format('YYYY-MM-DD');
      const isCurrentMonth = day.month() === currentMonth.month();
      const isToday = dayKey === selectedDay
      const dayColor = getColorForDay(day);

      return (
        <Grid item xs={1.7} key={dayKey} onClick={() => handleDayClick(day)}>
          <Paper
            elevation={2}
            sx={{
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: isCurrentMonth ? dayColor : 'inherit',
              opacity: isCurrentMonth ? 1 : 0.3,
              cursor: 'pointer',
              border: isToday ? '5px solid #0000FF' : 'none',
              marginBottom: '2px'
              
            }}
          >
            <Typography variant="body2">{isCurrentMonth ? day.format('D') : ''}</Typography>
          </Paper>
        </Grid>
      );
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: 1 }}>
      {/* Календар (75%) */}
      <Box sx={{  width: '60%', backgroundColor: "rgba(0, 0, 0, 0)" }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1 }}>
          <IconButton onClick={goToPreviousMonth} size="small">
            <ArrowCircleLeftIcon />
          </IconButton>
          <Typography variant="h6">{currentMonth.format('MMMM YYYY')}</Typography>
          <IconButton onClick={goToNextMonth} size="small">
            <ArrowCircleRightIcon />
          </IconButton>
        </Box>
        
        <Grid container spacing={1}>
          {renderCalendar()}
        </Grid>
        
      </Box>

      {/* Блок з індикаторами (25%) */}
      <Box sx={{width: '40%', border: '2px solid #ccc', borderRadius: '4px', padding: 2 }}>
      <Indicator dayData={dayData} monthData={monthData} />

      </Box>
    </Box>
  );
};

export default Calendar;
