import React, { useEffect, useState, useRef } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControlLabel, Checkbox,IconButton } from "@mui/material";
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles } from '@mui/material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../components/Header";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import {GRID_UA_UA_LOCALE_TEXT} from './loc'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import '../components/call.css'

import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import notificationSound from '../components/allert.mp3';

const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

  
    return `${hours}:${minutes} ${day}-${month}-${year}`;
  };

  const AudioPlayer = React.memo(({ src }) => {
    const audioRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const audio = audioRef.current;
        audio.src = src;

        const onLoadedData = () => {
            setIsReady(true);
            setProgress((audio.currentTime / audio.duration) * 100);
        };

        const onTimeUpdate = () => {
            setProgress((audio.currentTime / audio.duration) * 100);
        };

        const onCanPlay = () => {
            setIsReady(true);
        };

        audio.addEventListener('loadeddata', onLoadedData);
        audio.addEventListener('timeupdate', onTimeUpdate);
        audio.addEventListener('canplay', onCanPlay);

        return () => {
            audio.removeEventListener('loadeddata', onLoadedData);
            audio.removeEventListener('timeupdate', onTimeUpdate);
            audio.removeEventListener('canplay', onCanPlay);
        };
    }, [src]);

    const togglePlay = (event) => {
        event.stopPropagation();
        if (isReady) {
            if (playing) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setPlaying(!playing);
        }
    };

    const handleSeek = (event) => {
        event.stopPropagation();
        if (isReady) {
            const newTime = (event.target.value / 100) * audioRef.current.duration;
            audioRef.current.currentTime = newTime;
            setProgress(event.target.value);
        }
    };

    return (
        <div onClick={event => event.stopPropagation()}>
            <audio ref={audioRef} preload="auto" />
            <IconButton onClick={togglePlay}>
                {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <input
                type="range"
                min="0"
                max="100"
                value={progress}
                onChange={handleSeek}
                onMouseDown={event => event.stopPropagation()}
                onTouchStart={event => event.stopPropagation()}
                style={{ width: '100%' }}
            />
        </div>
    );
});







const Calls = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [calls, setCalls] = useState([]);
    const [hasPhotos, setHasPhotos] = useState(false);
    const [highlightedRow, setHighlightedRow] = useState(null);

    const [missedCalls, setMissedCalls] = useState(0);
    const audioRef = useRef(new Audio(notificationSound));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCall, setSelectedCall] = useState({});

    const getStatusIcon = (statusId) => {
        const iconStyle = statusId === 2 ? { animation: 'pulse 1s infinite' } : {};

        switch (statusId) {
            case 0:
                return <CallReceivedIcon style={{ color: '#00FF00' }}/>; // Іконка для вхідних дзвінків
            case 1:
                return <CallMadeIcon style={{ color: '#FFFF00' }}/>; // Іконка для вихідних дзвінків
            case 2:
                return <PhoneMissedIcon style={{ color: '#FF0000', ...iconStyle }}/>; // Іконка для пропущених дзвінків
                default:
                return null; // Якщо статус невідомий
        }
    };


    
    

    const apiKey = localStorage.getItem('api');
    useEffect(() => {
    const interval = setInterval(() => {
        axios.get('https://api.assist.cam/calls', {
            headers: {
                'X-Api-Key': apiKey,
            },
        })
        .then(response => {
            setCalls(response.data);
            const photoExists = response.data.some(call => call.photo);
            setHasPhotos(photoExists);
            if (response.data.some(call => call.type === 2 && call.status === '📞')) {
                audioRef.current.play(); // Відтворення звуку, коли є нові пропущені дзвінки з type 2 та status 📞
            }
            
             // Оновлення стану з кількістю пропущених викликів
        })
        .catch(error => {
            console.error('Виникла помилка!', error);
        });
    }, 15000);

    return () => clearInterval(interval);
}, [missedCalls]); // Додайте missedCalls до списку залежностей


    const handleSubmit = (callData) => {
        // Відправка даних на бекенд
        axios.post('https://api.assist.cam/add_call', callData, {
          headers: {
              'X-Api-Key': apiKey,
          },
        })
        .then(response => {
            // Оновлення стану, якщо потрібно
            handleCloseModal();
        })
        .catch(error => {
            console.error('Помилка під час відправлення даних!', error);
        });
      };


      



    const columns = [
        {
            field: 'type',
            headerName: 'Статус',
            width: 50,
            renderCell: (params) => getStatusIcon(params.value),
        },
        {
            field: 'audio',
            headerName: 'Аудіо',
            width: 120,
            renderCell: (params) => (
                params.value ? <AudioPlayer key={params.id} src={params.value} /> : null
            ),
        },
        

        
        {
          field: 'phone_cl',
          headerName: 'Номер клієнта',
          width: 100,
        //   renderCell: (params) => (
        //     <img src={params.value} alt="Фото" style={{ width: '80px', height: '80px' }} />
        //   ),
        },
        { field: 'client_name', headerName: 'Ініціали', width: 120 }, 
        
        {
          field: 'plate',
          headerName: 'Номер авто',
          width: 80,
          
        }, 
        {
            field: 'date',
            headerName: 'Дата та час',
            width: 130,
            valueGetter: (params) => {
              // Використовуйте функцію formatDateTime для форматування дати та часу
              return formatDateTime(params.value);
            },
          },

        // {
        //     field: 'auto',
        //     headerName: 'Авто',
        //     width: 200,
            
        //   },

        //   {
        //     field: 'vin',
        //     headerName: 'VIN',
        //     width: 150,
            
        //   },
        //   hasPhotos && {
        //     field: 'photo',
        //     headerName: 'Фото',
        //     width: 130,
        //     renderCell: (params) => (
        //         params.value ? <img src={params.value} alt="Фото" style={{ width: '80px', height: '80px' }} /> : null
        //     ),
        // },

        
      ].filter(Boolean);


      const handleOpenModal = (call) => {
        setSelectedCall(call);
        setIsModalOpen(true);
      };
      
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };

      const handleRowClick = async (params) => {
        setHighlightedRow(params.id); // Встановлюємо, що пропущені дзвінки були переглянуті
        handleOpenModal(params.row);

        // Відправлення сигналу на бекенд про перегляд дзвінка
        try {
            await axios.post('https://api.assist.cam/reviewed', {
                callId: params.row.id,
                type: params.row.type // ID дзвінка, який було переглянуто
            }, {
                headers: {
                    'X-Api-Key': apiKey,
                },
            });
            console.log('Дзвінок було переглянуто.');
        } catch (error) {
            console.error('Помилка при відправленні даних на бекенд', error);
        }
    };
      
      



    return (
        <Box m="20px">
        <Header title="Дзвінки"/>


            <Box m="40px 0 0 0" height="75vh" sx={{
            "& .MuiDataGrid-root": {
                border: "none",
            },
            "& .MuiDataGrid-cell": {
                borderBottom: "none",
            },
            "& .no-border-bottom": {
                borderBottom: "none !important",
            },
            "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
            },
            // "& .MuiDataGrid-footerContainer": {
            //     borderTop: "none",
            //     backgroundColor: colors.blueAccent[700],
            // },
            "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
            },
            }}>
            <DataGrid
            rows={calls}
            columns={columns}
            getRowClassName={(params) => params.row.type === 2 && params.row.status === "📞" && highlightedRow !== params.id ? 'pulseRow' : ''}
            onRowClick={handleRowClick}
            components={{ Toolbar: GridToolbar }}
            localeText={GRID_UA_UA_LOCALE_TEXT}
            />
            
        </Box>

        <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            maxWidth="lg"
            fullWidth
            sx={{
                display: 'flex', // Встановлюємо flex container для вирівнювання
                flexDirection: 'column', // Вікно буде вирівнюватися вертикально
                alignItems: 'center', // Вирівнювання по центру горизонтально
                maxHeight: '100vh',
                height: '100%', // Встановлюємо висоту модального вікна
                '& .MuiDialog-paper': {
                    width: '80%',
                    maxHeight: '100vh',
                    height: '80%', // Встановлюємо висоту внутрішнього вікна
                },
            }}
        >
            <DialogTitle sx={{ backgroundColor: colors.primary[400] }}>
                Редагувати дзвінок
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: colors.primary[400], overflow: 'hidden' }}>
            {selectedCall.photo && (
            <img src={selectedCall.photo} alt="Фото" style={{ width: '30%', height: 'auto' }} />
        )}
                <TextField
                autoFocus
                margin="dense"
                id="phone_cl"
                label="Номер клієнта"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedCall.phone_cl || ''}
                onChange={(e) => setSelectedCall({ ...selectedCall, phone_cl: e.target.value })}
                sx={{ margin: theme.spacing(1) }}
                />
                <TextField
                margin="dense"
                id="client_name"
                label="Ініціали"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedCall.client_name || ''}
                onChange={(e) => setSelectedCall({ ...selectedCall, client_name: e.target.value })}
                sx={{ margin: theme.spacing(1) }}
                />
                <TextField
                margin="dense"
                id="plate"
                label="Номер авто"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedCall.plate || ''}
                onChange={(e) => setSelectedCall({ ...selectedCall, plate: e.target.value })}
                sx={{ margin: theme.spacing(1) }}
                />
                <TextField
                margin="dense"
                id="date"
                label="Дата та час"
                type="datetime-local"
                fullWidth
                variant="outlined"
                value={selectedCall.date || ''}
                onChange={(e) => setSelectedCall({ ...selectedCall, date: e.target.value })}
                sx={{ margin: theme.spacing(1) }}
                InputLabelProps={{
                    shrink: true,  // Це забезпечує, що лейбл завжди буде зменшений, щоб не перекривати значення поля
                }}
            />
                <TextField
                margin="dense"
                id="auto"
                label="Авто"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedCall.auto || ''}
                onChange={(e) => setSelectedCall({ ...selectedCall, auto: e.target.value })}
                sx={{ margin: theme.spacing(1) }}
                />
                <TextField
                margin="dense"
                id="vin"
                label="VIN"
                type="text"
                fullWidth
                variant="outlined"
                value={selectedCall.vin || ''}
                onChange={(e) => setSelectedCall({ ...selectedCall, vin: e.target.value })}
                sx={{ margin: theme.spacing(1) }}
                />
                {/* ... можна додати більше полів якщо треба ... */}
            </DialogContent>
            <DialogActions sx={{ backgroundColor: colors.primary[400] }}>
                <Button onClick={handleCloseModal} style={{color:'white', backgroundColor: 'red'}}>
                Скасувати
                </Button>
                <Button onClick={() => handleSubmit(selectedCall)} style={{color:'white', backgroundColor: 'green'}}>
                Зберегти
                </Button>
            </DialogActions>
            </Dialog>




      </Box>
    );
};

export default Calls;
