import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, Paper, Tab, Tabs, Table, TableBody, TableCell,
   TableHead, TableRow, Icon, ToggleButton, ToggleButtonGroup, IconButton, useTheme } from "@mui/material";
import {  FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {  Avatar, TextField} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Divider } from "@mui/material";
import { CircularProgress } from '@mui/material'; // Для індикатора завантаження
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './transitions.css'; // Припускаємо, що стилі анімацій збережені тут
import CarDetails from './CarDetail';
import DiagnosticDialog from './DiagnosticDialog';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PhoneIcon from '@mui/icons-material/Phone'; // Додати
import HomeIcon from '@mui/icons-material/Home'; // Додати
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Додати
import CardMembershipIcon from '@mui/icons-material/CardMembership'; // Додати
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { tokens } from "../theme";
import { color } from '@mui/system';




  
const CustomerCard = ({ customerInfo, isLoading  }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedInfo, setEditedInfo] = useState(customerInfo);
  console.log("Loading",)
  const theme = useTheme(); // Використання хука useTheme для отримання поточної теми
const mode = theme.palette.mode;
const color_t = theme.palette.text.primary

  const handleFieldChange = (e) => {
      const { name, value } = e.target;
      setEditedInfo({ ...editedInfo, [name]: value });
  };

  const handleTypeChange = (event, newType) => {
      if (newType !== null) {
          setEditedInfo({ ...editedInfo, type: newType });
      }
  };

  const handleSave = async () => {
    setIsEditing(false); // Закінчити редагування
  
    try {
      const apiKey = localStorage.getItem('api'); // Отримання API ключа з локального сховища

      const response = await fetch('https:/api.assist.cam/customers/update', {
        method: 'PUT', // або 'POST', залежно від API
        headers: {
          'Content-Type': 'application/json',
          // Додайте тут ваш токен авторизації, якщо потрібно
          'X-Api-Key': apiKey, 
        },
        body: JSON.stringify(editedInfo), // Перетворюємо дані, які потрібно відправити, в JSON
      });
  
      if (response.ok) {
        const result = await response.json(); // Отримуємо відповідь від сервера
        console.log('Дані успішно оновлено:', result);
        // Тут можна додати дії після успішного оновлення, наприклад, оновити UI
      } else {
        console.error('Помилка при оновленні даних:', response.statusText);
        // Обробка помилки відповіді сервера
      }
    } catch (error) {
      console.error('Помилка при відправці запиту:', error);
      // Обробка помилки запиту
    }
  };
  
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
}

//background: 'rgba(0, 0, 0, 0.1)',
  return (
        <Paper elevation={15} style={{ 
          padding: '20px', 
          margin: '10px', 
          width: '95%', 
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'flex-start', 
          backgroundColor: '#006400', 
          color: 'primary',
          background: mode === "dark"
            ? 'linear-gradient(to top right, #041d44 0%, #041d44 50%, #519772 100%)'
            : 'linear-gradient(to top right, #ebf5ed 0%, #ebf5ed 20%, #519772 100%)'
        }}>
          <Box display="flex" alignItems="center" marginBottom="20px">
              <Avatar src={editedInfo.logoSrc} style={{ marginRight: '10px' }} />
              {isEditing ? (
                  <TextField
                      variant="outlined"
                      name="name"
                      value={editedInfo.name}
                      onChange={handleFieldChange}
                      style={{ flexGrow: 1, marginRight: '10px' }}
                  />
              ) : (
                  <Box flexGrow={1} style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                      <Typography variant="h5" color={color_t}>{customerInfo.name} </Typography>
                  </Box>
              )}
              {isEditing ? (
                  <ToggleButtonGroup
                      value={editedInfo.type}
                      exclusive
                      onChange={handleTypeChange}
                      style={{ height: 56 }}
                  >
                      <ToggleButton value={0} aria-label="personal">
                          <PersonIcon />
                      </ToggleButton>
                      <ToggleButton value={1} aria-label="corporate">
                          <ApartmentIcon />
                      </ToggleButton>
                  </ToggleButtonGroup>
              ) : (
                <Box display="flex" alignItems="center">
                <IconButton style={{ backgroundColor: editedInfo.type === 0 ? '#00FFFF' : '#00FFFF', marginRight: '8px' }}>
                    {editedInfo.type === 0 ? <PersonIcon /> : <ApartmentIcon />}
                </IconButton>
                <Typography variant="body1">
                    {editedInfo.type === 0 ? 'Клієнт' : 'Постачальник'}
                </Typography>
            </Box>
              )}
          </Box>
          {isEditing ? (
              <>
                  <TextField
                      variant="outlined"
                      name="phone"
                      label="Телефон"
                      value={editedInfo.phone}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                  />
                  <TextField
                      variant="outlined"
                      name="address"
                      label="Адреса"
                      value={editedInfo.address}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                  />
                  <TextField
                      variant="outlined"
                      name="date_n"
                      label="Дата народження"
                      value={editedInfo.date_n}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                  />
                  <TextField
                      variant="outlined"
                      name="segment"
                      label="Сегмент"
                      value={editedInfo.segment}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                  />
                  <TextField
                      variant="outlined"
                      name="disc_p"
                      label="Знижка на запчастини (%)"
                      value={editedInfo.disc_p}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                  />
                  <TextField
                      variant="outlined"
                      name="disc_s"
                      label="Знижка на послуги (%)"
                      value={editedInfo.disc_s}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                  />
                  <TextField
                      variant="outlined"
                      name="card"
                      label="Дисконтна карта"
                      value={editedInfo.card}
                      onChange={handleFieldChange}
                      fullWidth
                      margin="normal"
                  />
              </>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom="20px">
            <Box display="flex" alignItems="center" marginBottom="10px">
              <PhoneIcon style={{ marginRight: '8px' }} />
              <Typography variant="h5">Телефон: {customerInfo.phone}</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <HomeIcon style={{ marginRight: '8px' }} />
              <Typography variant="h5">Адреса: {customerInfo.address}</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <CalendarTodayIcon style={{ marginRight: '8px' }} />
              <Typography variant="h5">Дата народження: {customerInfo.date_n}</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <Typography variant="h5" style={{ flexGrow: 1 }}>Сегмент: {customerInfo.segment}</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <CardMembershipIcon style={{ marginRight: '8px' }} />
              <Typography>Знижка на запчастини: {customerInfo.disc_p} %</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <CardMembershipIcon style={{ marginRight: '8px' }} />
              <Typography>Знижка на послуги: {customerInfo.disc_s} %</Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <CreditCardIcon style={{ marginRight: '8px' }} />
              <Typography>Дисконтна карта: {customerInfo.card}</Typography>
            </Box>
          </Box>
          )}
          {isEditing ? (
              <Button onClick={handleSave} startIcon={<SaveIcon />} style={{ marginTop: '10px', backgroundColor: "#4CAF50", color: "white" }}>
                  Зберегти
              </Button>
          ) : (
              <Button onClick={() => setIsEditing(!isEditing)} startIcon={<EditIcon />} style={{ marginTop: '10px', backgroundColor: "#2196F3", color: "white" }}>
                  Редагувати
              </Button>
          )}
      </Paper>
  );
};


const TabPanel = ({ children, value, index, ...other }) => {
  // Компонент для відображення контенту вкладки
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const CustomerDialog = ({ open, handleClose, carId }) => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme(); // Використання хука useTheme для отримання поточної теми
const mode = theme.palette.mode;
const color_t = theme.palette.text.primary

  const [clData, setCarData] = useState([]);
   // Початковий стан - порожній масив
   const [clCars, setCarsData] = useState([]);
   const [isLoading, setIsLoading] = useState(false); // Для керування індикатором завантаження
   const [viewMode, setViewMode] = useState('table'); // 'customerInfo' або 'carDetails'
   const [selectedCarId, setSelectedCarId] = useState(null);
   const [diagnosticUrl, setDiagnosticUrl] = useState('');
   const [showDiagnosticModal, setShowDiagnosticModal] = useState(false);
const [selectedDiagnosticUrl, setSelectedDiagnosticUrl] = useState('');
const [openDialog, setOpenDialog] = useState(false); // Стан для відображення діалогового вікна
const [selectedCarData, setSelectedCarData] = useState(null);
const [loading, setLoading] = useState(false); // Додавання стану для відстеження завантаження



  // Функція для відкриття діалогового вікна
  const handleOpenDialog = (e, carId) => {
    e.stopPropagation();
    console.log('Вибране авто', carId)
    console.log('Вибране авто', clData.records.find(car => car.id === carId))
    setSelectedCarData(clData.records.find(car => car.id === carId));
    setOpenDialog(true);
  };

  // Функція для закриття діалогового вікна
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


   const handleViewCarDetails = (carId) => {
    setSelectedCarId(carId);
    setViewMode('carDetails');
  };


    // Функція для обробки події підтвердження в діалоговому вікні
    const handleSubmitDialog = (reason, selectedOption) => {
      // Тут ви можете реалізувати необхідну логіку для обробки введених даних
      console.log("Причина звернення:", reason);
      console.log("Обрана причина:", selectedOption);
    };


  
  const handleBackToCustomerInfo = () => {
    setViewMode('customerInfo');
  };


  const handleSelectCar = (carId) => {
    setSelectedCarId(carId);
    setViewMode('carDetails');
  };

  const handleBackToTable = () => {
    setViewMode('table');
  };
  
   

  useEffect(() => {
    // Якщо вікно відкрите і маємо carId, завантажуємо дані
    if (open && carId) {
      const fetchCarData = async () => {
        setLoading(true)
        try {
          const apiKey = localStorage.getItem('api');
          const response = await fetch(`https://api.assist.cam/get_client_card/${carId}`, {
            method: 'GET',
            headers: {
              'X-Api-Key': apiKey,
            },
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log(data)
          setCarData(data[0]);
          setLoading(false)
        } catch (error) {
          console.error('Помилка при запиті:', error);
        }
      };

      fetchCarData();
    }
  }, [open, carId]); 


  // Завантаження даних про автомобілі клієнта при переході на вкладку "Автомобілі"
  useEffect(() => {
    if (open && carId && tabValue === 1) {
       // Починаємо показ індикатора завантаження
      // Лише коли вкладка "Автомобілі" активна
      const fetchCarsData = async () => {
        setIsLoading(true);
        try {
          const apiKey = localStorage.getItem('api');
          const response = await fetch(`https://api.assist.cam/get_client_cars/${carId}`, {
            method: 'GET',
            headers: { 'X-Api-Key': apiKey },
          });
          if (!response.ok) throw new Error('Network response was not ok');
          const carsData = await response.json();
          setCarsData(carsData);
          setIsLoading(false); // Завершуємо показ індикатора завантаження
          // Припускаємо, що API повертає масив автомобілів
        } catch (error) {
          console.error('Error fetching cars data:', error);
        }
      };
      fetchCarsData();
      
    }
  }, [open, carId, tabValue]);


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // // Заглушка для CarDetails компоненту
  // const CarDetails = ({ carDetails, onBack }) => (
  //   <Paper style={{ margin: '10px', padding: '20px' }}>
  //     <Button onClick={onBack} sx={{backgroundColor: 'white'}}>Назад</Button>
  //     {/* Відображення деталей автомобіля */}
  //     <Typography>Деталі автомобіля: </Typography>
  //   </Paper>
  // );

  const handleDiagnosticClick = (e, diagnosticUrl) => {
    e.stopPropagation(); 
    setSelectedDiagnosticUrl(diagnosticUrl);
    setShowDiagnosticModal(true);
};



  return (
    <Box m="20px">
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <DialogContent sx={{ backgroundColor: '#617c85' }}>

            <Box display="flex" flexDirection="row" height="70vh">
                <Box width="30%" minWidth="250px" sx={{border: '2px solid #e0e0e0',borderRadius: '10px',}}>
                <CustomerCard customerInfo={clData} isLoading={loading} />
                </Box>

              <Divider orientation="vertical" flexItem sx={{ marginX: 2, backgroundColor: 'grey', width: '5px' }} />


              {/* Права частина - Вкладки та контент */}
              <Box width="70%" minWidth="350px"   sx={{border: '2px solid #e0e0e0',borderRadius: '10px',}}>
                <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="simple tabs example" 
                    sx={{
                      '.MuiTabs-indicator': {
                        backgroundColor: 'white',
                      },
                      '.MuiTab-root': {
                        color: color_t,
                        '&.Mui-selected': {
                          color: color_t,
                        },
                      },
                    }}
                  >
                    <Tab label="Угоди" />
                    <Tab label="Автомобілі" />
                    <Tab label="Дзвінки" />
                    <Tab label="Завдання" />
                    <Tab label="Компанії" />
                    <Tab label="Статистика" />
                    <Tab label="Примітки" />
                    
                  </Tabs>

                <TransitionGroup component={null}>
                  
                      {viewMode === 'table' && (
                        <CSSTransition key="table" classNames="fade" timeout={300}>
                          <TabPanel value={tabValue} index={0}>
                          <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ fontSize: 'small' }}>Номер угоди</TableCell>
                              <TableCell style={{ fontSize: 'small' }}>Статус</TableCell>
                              <TableCell style={{ fontSize: 'small' }}>Номер авто</TableCell>
                              <TableCell style={{ fontSize: 'small' }}>Фото</TableCell>
                              <TableCell style={{ fontSize: 'small' }}>Авто</TableCell>
                              <TableCell style={{ fontSize: 'small' }}>Причина звернення</TableCell>
                              <TableCell>Діагностика</TableCell>
                              <TableCell>КП</TableCell>
                              <TableCell>Прибуток</TableCell>
                              {/* <TableCell>Реальна НГ</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {clData.records?.map((car, index) => (
                              <TableRow key={index} onClick={() => handleSelectCar(car.id)} style={{ cursor: 'pointer' }}>
                                <TableCell style={{ fontSize: 'small' }}>{car.id}</TableCell>
                                <TableCell style={{ fontSize: 'small' }}>{car.status}</TableCell>
                                <TableCell style={{ fontSize: 'small' }}>{car.plate}</TableCell>
                                <TableCell>
                                    <img src={car.photo} alt="Фото" style={{ width: '70px', height: '50px' }} />
                                </TableCell>
                                <TableCell style={{ fontSize: 'small' }}>{car.auto}</TableCell>
                                <TableCell>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={(e) => handleOpenDialog(e, car.id)}// Передача події e до функції handleOpenDialog
                                  disabled={car.diag_pdf === null || car.diag_pdf === undefined}
                                >
                                  Причина звернення
                                </Button>
                              </TableCell>
                                <TableCell>
                                 
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleDiagnosticClick(e,car.diag_pdf)}
                                    disabled={car.diag_pdf === null || car.diag_pdf === undefined} // Вимкнути кнопку, якщо diag_pdf дорівнює null або undefined
                                >
                                    Діагностика
                                </Button>
                                </TableCell>
                                <TableCell>
                                 
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleDiagnosticClick(e,car.diag_pdf)}
                                    disabled={car.diag_pdf === null || car.diag_pdf === undefined} // Вимкнути кнопку, якщо diag_pdf дорівнює null або undefined
                                >
                                    КП
                                </Button>
                                </TableCell>
                                <TableCell>
                                 
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleDiagnosticClick(e,car.diag_pdf)}
                                    disabled={car.diag_pdf === null || car.diag_pdf === undefined} // Вимкнути кнопку, якщо diag_pdf дорівнює null або undefined
                                >
                                    Прибуток
                                </Button>
                                </TableCell>
                                {/* <TableCell>
                                 
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(e) => handleDiagnosticClick(e,car.diag_pdf)}
                                    disabled={car.diag_pdf === null || car.diag_pdf === undefined} // Вимкнути кнопку, якщо diag_pdf дорівнює null або undefined
                                >
                                    НГ
                                </Button>
                                </TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                            </TabPanel>
                            </CSSTransition>
                          
                        )}
                        
                        {viewMode === 'carDetails' && selectedCarId && (
                          <CSSTransition key="carDetails" classNames="fade" timeout={300}>
                          <CarDetails carDetails={clData.records.find(car => car.id === selectedCarId)} onBack={handleBackToTable} />
                          </CSSTransition>
                        )}
                      
                  </TransitionGroup>
                        
                        <TabPanel value={tabValue} index={1}>
                            
                            {isLoading ? (
                              <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                                <CircularProgress style={{ color: '#ffffff' }}/> 
                              </Box>
                            ) : (
                              clCars?.length > 0 ? (
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                    <TableCell>Номер авто</TableCell>
                                      <TableCell>Марка</TableCell>
                                      <TableCell>VIN</TableCell>
                                      <TableCell>Рік</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {clCars.map((car, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{car.plate}</TableCell>
                                        <TableCell>{car.brand} {car.model}</TableCell>
                                        <TableCell>{car.vin}</TableCell>
                                        <TableCell>{car.year}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              ) : (
                                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                                  <Typography>Дані про автомобілі відсутні</Typography>
                                </Box>
                              )
                            )}
                            </TabPanel>           
                        </Box>
                        
                        </Box>
                            
          </DialogContent>
          <DialogActions sx={{ backgroundColor: '#617c85'}}>
            <Button onClick={handleClose} color="primary">
              Закрити
            </Button>
          </DialogActions>
    </Dialog>

    <Dialog open={showDiagnosticModal} onClose={() => setShowDiagnosticModal(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>PDF Документ</DialogTitle>
        <DialogContent>
          {selectedDiagnosticUrl && (
            <iframe
              src={selectedDiagnosticUrl}
              width="100%"
              height="700px"
              style={{ border: 'none' }}
            ></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button>Закрити</Button>
        </DialogActions>
      </Dialog>

      <DiagnosticDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
        carData={selectedCarData}
      />
  </Box>






  );
};

export default CustomerDialog;
