import React, { useEffect, useState } from 'react';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal,
   TextField, IconButton } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import NoTransferIcon from '@mui/icons-material/NoTransfer';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import Snackbar from '@mui/material/Snackbar';
const PostsComponent = () => {
  const [posts, setPosts] = useState([]); // Для зберігання постів
  const [open, setOpen] = useState(false); // Для відкриття/закриття модального вікна
  const [newPostTitle, setNewPostTitle] = useState(""); // Для зберігання назви нового поста
  const [editPostId, setEditPostId] = useState(null);
  const [editPostTitle, setEditPostTitle] = useState("");
  // Snackbar state
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');

const handleSnackbarClose = () => {
  setSnackbarOpen(false);
};

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const apiKey = localStorage.getItem('api');

    // Функція для завантаження постів
    const fetchPosts = async () => 
    {
      try {
        const response = await fetch('https://api.assist.cam/all_post', {
            method: 'GET',
          headers: {
            'X-Api-Key': apiKey, 
          }
        });

        if (response.ok) {
          const data = await response.json();
          setPosts(data); // Збереження постів у стан
        } else {
          // Обробка помилок
          console.error('Failed to fetch posts');
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

  useEffect(() => {
    

    fetchPosts();
  }, []); 




  const handleEdit = (id, title) => {
    setEditPostId(id);
    setEditPostTitle(title);
  };


  const handleSaveEdit = async (id) => {
    const apiKey = localStorage.getItem('api');
  
    try {
      const response = await fetch(`https://api.assist.cam/update_posts_id/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': apiKey,
        },
        body: JSON.stringify({ title: editPostTitle }),
      });
  
      if (response.ok) {
        const updatedPosts = await response.json(); // Припускаємо, що сервер повертає оновлений список постів
        setPosts(updatedPosts); // Оновлюємо стан постів новим списком
        setEditPostId(null); // Вихід з режиму редагування
        setEditPostTitle(""); // Очищуємо поле редагування
      } else {
        console.error('Failed to update post');
      }
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };
  
  

  const handleDelete = async (id) => {
    const apiKey = localStorage.getItem('api');
  
    try {
      const response = await fetch(`https://api.assist.cam/delete_post_id/${id}`, {
        method: 'DELETE', // Використовуйте метод DELETE для видалення
        headers: {
          'X-Api-Key': apiKey,
        },
      });
  
      if (response.ok) {
        // Видалення поста зі стану після успішного видалення на сервері
        setPosts(posts.filter(post => post.id !== id));
      } else {
        // Обробка помилки від сервера
        console.error('Failed to delete post');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };
  

  const handleSave = async () => {
    const apiKey = localStorage.getItem('api');
  
    const response = await fetch('https://api.assist.cam/add_posts', {
      method: 'POST',
      headers: {
        'X-Api-Key': apiKey,  // Передача API ключа в заголовках
      },
      body: JSON.stringify({ title: newPostTitle }),
    });
  
    if (response.ok) {
      const addedPost = await response.json();
      setPosts([...posts, addedPost]);
      setNewPostTitle('')
    } else {
      const error = await response.json();
      console.error('Failed to add post:', error.detail); // Показ помилки з бекенду
    }
  };


  const togglePostStatus = async (id, status) => {
    const apiKey = localStorage.getItem('api');
  
    try {
      const response = await fetch(`https://api.assist.cam/disable_post_id/${id}/${status}`, {
        method: 'PUT', // Використовуйте метод DELETE для видалення
        headers: {
          'X-Api-Key': apiKey,
        },
      });
  
      if (response.ok) {
        
        console.log(response.status)
        fetchPosts()
        setSnackbarMessage(status === 0 ? 'Пост увімкнено!' : 'Пост вимкнено!');
        setSnackbarOpen(true);
        
      } else {
        // Обробка помилки від сервера
        console.error('Failed to delete post');
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };
  
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Назва поста</TableCell>
              <TableCell align="right">Дії</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {posts
          .filter(post => post.id !== 0 && post.id !== 1) // Фільтрація постів за ID
          .map((post) => (
              <TableRow key={post.id}>
                <TableCell component="th" scope="row">
                  {editPostId === post.id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={editPostTitle}
                      onChange={(e) => setEditPostTitle(e.target.value)}
                    />
                  ) : (
                    post.name
                  )}
                </TableCell>
                <TableCell align="right">
                  {editPostId === post.id ? (
                    <IconButton onClick={() => handleSaveEdit(post.id)}>
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleEdit(post.id, post.name)}>
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={() => handleDelete(post.id)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={() => togglePostStatus(post.id, post.status)}>
                  <PowerSettingsNewIcon color={post.status === 1 ? "success" : "error"} />
                </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Додати новий пост..."
                  value={newPostTitle}
                  onChange={(e) => setNewPostTitle(e.target.value)}
                />
              </TableCell>
              <TableCell align="right">
                <Button variant="contained" onClick={handleSave}>Додати</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
    open={snackbarOpen}
    autoHideDuration={8000}
    onClose={handleSnackbarClose}
    message={snackbarMessage}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    sx={{
        maxWidth: '500px',
        backgroundColor: '#000080',  // Correct hex color usage 
        borderRadius: '10px',
        '& .MuiSnackbarContent-root': { // Targeting a more specific class to override defaults
            backgroundColor: '#000080', 
            borderRadius: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'white',

        }
    }}
/>
    </Box>
  );
};

export default PostsComponent;
